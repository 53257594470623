export const setTokenToHeaders = (token) => {
  window.NUCLIUZ.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const login = (credentials) => {
  return new Promise((res, rej) => {
    window.NUCLIUZ
      .post(`/api/auth/login`, credentials)
      .then((response) => {
        // setTokenToHeaders(response.data.data.api_token);
        res(response.data);
      })
      .catch((err) => {
        rej(`${window.errorsValidate(err)}`);
      });
  });
};
export const register = (credentials) => {
  return new Promise((res, rej) => {
    window.NUCLIUZ
      .post(`/api/auth/register`, credentials)
      .then((response) => {
        // setTokenToHeaders(response.data.data.api_token);
        res(response.data);
      })
      .catch((err) => {
        rej(`${window.errorsValidate(err)}`);
      });
  });
};
export const resetLink = (email) => {
  return new Promise((res, rej) => {
    window.NUCLIUZ
      .post(`/api/auth/reset-link`, email)
      .then((response) => {
        setTokenToHeaders(response.data.data.api_token);
        res(response.data);
      })
      .catch((err) => {
        rej(`${window.errorsValidate(err)}`);
      });
  });
};
export const resetPassword = (email) => {
  return new Promise((res, rej) => {
    window.NUCLIUZ
      .post(`/api/auth/reset-password`, email)
      .then((response) => {
        setTokenToHeaders(response.data.data.api_token);
        res(response.data);
      })
      .catch((err) => {
        rej(`${window.errorsValidate(err)}`);
      });
  });
};
