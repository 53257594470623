<template>
  <div
    class="tw-fixed tw-inset-0 tw-flex md:tw-items-end md:tw-justify-start tw-p-4 md:tw-p-6 tw-pointer-events-none tw-z-1500"
  >
    <div class="tw-max-w-sm tw-w-full">
      <!-- tag="div"
        class="tw-space-y-3"
        name="fade-transition"
        mode="out-in"
        enter-active-class="tw-transform tw-transition-all tw-ease-out tw-duration-300 tw-delay-300"
        enter-class="tw-translate-x-4 tw-opacity-0"
        enter-to-class="tw-translate-x-0 tw-opacity-100"
        leave-active-class="tw-transform tw-transition-all tw-ease-out tw-duration-500"
        leave-class="tw-translate-x-0 tw-opacity-100"
        leave-to-class="tw-translate-x-4 tw-opacity-0"
        move-class=" tw-transform tw-transition-all tw-ease-in-out tw-duration-500" -->
      <!--
        tag="div"
        class="tw-space-y-3 tw-transition-all"
        name="fade-transition"
        mode="out-in"
        enter-active-class="tw-transform tw-transition-all tw-ease-out tw-duration-300 tw-delay-300 "
        enter-class="tw--translate-x-4 tw-opacity-0 "
        enter-to-class="tw-translate-x-0 tw-opacity-100"
        leave-active-class="tw-transform tw-absolute tw-transition-all tw-ease-out tw-duration-500 "
        leave-class="tw-translate-x-0 tw-opacity-100  "
        leave-to-class="tw--translate-x-4 tw-opacity-0   "
        move-class=" tw-transform tw-transition-all tw-ease-in-out tw-duration-500"
         -->
      <!-- enter-active-class="tw-transform "
        enter-class="tw-opacity-0 tw--translate-x-full"
        enter-to-class="tw-opacity-100 tw-translate-x-0"
        leave-active-class="tw-absolute tw-transform"
        leave-class="tw-opacity-100 tw-translate-x-0"
        leave-to-class="tw-opacity-0 tw--translate-x-full"
        tag="div" -->
      <transition-group
        enter-active-class="tw-transform-gpu"
        enter-class="tw-opacity-0 tw--translate-x-full"
        enter-to-class="tw-opacity-100 tw-translate-x-0"
        leave-active-class="tw-absolute tw-transform-gpu"
        leave-class="tw-opacity-100 tw-translate-x-0"
        leave-to-class="tw-opacity-0 tw--translate-x-full"
        tag="div"
        class="tw-flex tw-flex-col tw-space-y-4"
        @before-leave="beforeLeave"
      >
        <Notifcation
          v-for="notification in sortedNotifications"
          :id="notification.id"
          :key="notification.id"
          :title="notification.title"
          :body="notification.body"
          :status-type="notification.statusType"
          @remove-item="removeNotification"
        />
        <!-- :class="i" -->
        <!-- :class="i>0?'tw-mt-4':''" -->
      </transition-group>
    </div>
  </div>
</template>

<script>
import Notifcation from "./NotificationAlert";
export default {
  components: {
    Notifcation
  },
  computed: {
    notifications: {
      get() {
        return this.$store.getters["notifications/notifications"];
      },
      set(val) {
        this.$store.commit("notifications/notifications", val);
      }
    },
    sortedNotifications() {
      //   .reverse()
      return [...this.notifications].slice(0, 4);
    }
  },

  methods: {
    beforeLeave(el) {
      const {
        marginLeft,
        marginTop,
        marginBottom,
        width,
        height
      } = window.getComputedStyle(el);
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`;
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`;
      // el.style.bottom = `${el.offsetBottom - parseFloat(marginBottom, 10)}px`;
      el.style.width = width;
      el.style.height = height;
    },
    removeNotification(id) {
      this.$store.commit("notifications/notificationsRemove", id);
    }
  }
};
</script>

<style></style>
