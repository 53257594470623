<template>
  <div
    id="mail-shirt-modal"
    class="modal mail-shirt-modal in"
    role="dialog"
    style="display: block;"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="backToSteps"
          >
            <span aria-hidden="true">x</span>
          </button>
          <h4 class="modal-title mb0">Envianos una camisa para copiar</h4>
        </div>
        <div class="modal-body text-left row">
          <div class="col-md-7">
            <p>¿Quieres copiar la talla de una camisa que ya tienes, pero no estás seguro de cómo medirla correctamente?</p>

            <p>
              Envíenos su camisa por corrier y nuestros expertos la medirán y crearán un tamaño especial.
              <br />* Tenga en cuenta que nuestra medición no causa daños a la camisa y se la devolveremos con su pedido de la camisa.
            </p>

            <p>Enviar a:</p>
            <p class="address">
              <span class="black">Jermyn & Duke</span>
              <br />
              <span class="black">Attn: Para Medir</span>
              <br />
              <span class="black">Calle Alcanfores 920, Miraflores</span>
              <br />
              <span class="black">Lima, Peru</span>
              <br />
            </p>
          </div>
          <div class="col-md-5">
            <p>Cuando envies, asegurate de:</p>
            <ol>
              <!-- <li>
                <a
                  href="https://propercloth.com/documents/Measure-a-Shirt.pdf"
                  target="_blank"
                >This Form</a>
                (Optional, but helpful)
              </li>-->
              <li>
                Escribemos por WhatsApp al
                <br />999-999-999
              </li>
              <li>Comentenos cuando estará enviandonos su camisa</li>
              <!-- <li>Phone number</li>
              <li>Email address</li>
              <li>Return shipping address</li>-->
              <li>Cuentenos cualquier detalle adicional</li>
            </ol>
          </div>
          <div class="options row w-50 w-full-sm">
            <div class="flex flex-wrap mb3 checkbox-wrap">
              <label class="w-100 mb2 pa3 checkbox-toggle">
                <input v-model="sizeMethod" type="radio" name="size_method" :value="valueMethod" />
                <span>
                  <i class="check-indicator"></i> Selecionar este metodo
                </span>
              </label>
            </div>
          </div>
          <p class="row text-left">
            <a
              href="#"
              class="btn btn-full"
              data-dismiss="modal"
              aria-label="Close"
              @click.prevent="backToSteps"
            >Listo</a>
          </p>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  computed: {
    valueMethod() {
      return this.$store.getters.sizeMailShirt.value;
    },
    nameMethod() {
      return this.$store.getters.sizeMailShirt.name;
    },
    sizeMethod: {
      get: function() {
        return this.$store.getters.sizeMethod;
      },
      set: function(value) {
        console.log(value);
        // console.log(this.name);
        const data = {
          value: value,
          name: this.nameMethod
        };
        this.$store.commit("sizeMethod", data);
      }
    }
  },
  methods: {
    backToSteps() {
      this.$store.commit("toggleModalOpen", false);
      this.$store.commit("setModalMailShirt", false);
    }
  }
};
</script>

<style>
</style>