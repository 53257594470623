<template>
  <transition name="fade">
    <div
      v-show="modalAlert"
      id="modal_window_alert"
      class="modal in display-reg modal_window_alert"
      @click="body"
    >
      <div class="content modal-dialog modal-lg">
        <div class="content-inside modal-content">
          <div id="mw_signin_register_form">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                aria-label="Close"
                @click.prevent="modalAlert = false"
              ></button>
              <h4 class="modal-title">Pedidos</h4>
            </div>
            <!-- <div class="tw-w-full">
                  <div
                    v-for="order in orders"
                    class="tw-w-full"
                    :key="order.id"
                  >
                    {{ order.id }} - {{ order.uuid }}
                  </div>
                </div> -->
            <ul>
              <template v-if="orders.length > 0">
                <li v-for="order in orders" :key="order.uuid">
                  <a
                    href="#"
                    class="tw-no-underline tw-block hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-bg-gray-50 tw-transition tw-duration-150 tw-ease-in-out"
                    @click.prevent="showOrder(order.uuid)"
                  >
                    <div
                      class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6"
                    >
                      <div class="tw-min-w-0 tw-flex-1 tw-flex tw-items-center">
                        <div class="tw-flex-shrink-0">
                          <img
                            class="tw-h-32"
                            :src="order.items[0].shirt.shirt_img"
                            alt=""
                          />
                        </div>
                        <div
                          class="tw-min-w-0 tw-flex-1 tw-px-4 md:tw-grid md:tw-grid-cols-2 md:tw-gap-4"
                        >
                          <div>
                            <div
                              class="tw-text-sm tw-font-medium tw-text-indigo-600 tw-truncate"
                            >
                              #{{ order.code }}
                            </div>
                            <div
                              class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500"
                            >
                              <div class="tw-mr-1.5">
                                <svg
                                  class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    fill="#fff"
                                    d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                                  />
                                </svg>
                              </div>
                              <div class="tw-truncate">
                                {{ order.billing_address }},
                                {{ order.billing_district }}
                              </div>
                            </div>
                          </div>
                          <div class="tw-hidden md:tw-block">
                            <div>
                              <div class="tw-text-sm tw-text-gray-900">
                                Comprado el:
                                <time datetime="2020-01-07">{{
                                  order.time_of_purchase
                                }}</time>
                              </div>
                              <div
                                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500"
                              >
                                <!-- <svg
                                  class="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-green-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg> -->
                                <status-badge
                                  :label="order.status.label"
                                  :name="order.status.name"
                                />
                                <!-- {{ order.status.label }} -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <svg
                          class="tw-h-5 tw-w-5 tw-text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </a>
                </li>
              </template>
              <template v-else>
                <li>
                  <div
                    class="tw-block hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-bg-gray-50 tw-transition tw-duration-150 tw-ease-in-out"
                  >
                    <div class="tw-flex tw-justify-center tw-items-center">
                      <div
                        class="tw-font-medium tw-py-9 tw-text-xl tw-text-gray-500"
                      >
                        No tiene pedidos
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div
              v-if="false"
              class="tw-bg-white tw-px-4 tw-py-3 tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 sm:tw-px-6"
            >
              <div class="tw-hidden sm:tw-block">
                <p class="tw-text-sm tw-text-gray-700">
                  Showing
                  <span class="tw-font-medium">1</span>
                  to
                  <span class="tw-font-medium">10</span>
                  of
                  <span class="tw-font-medium">20</span>
                  results
                </p>
              </div>
              <div
                class="tw-flex-1 tw-flex tw-justify-between sm:tw-justify-end"
              >
                <a
                  href="#"
                  class="tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-text-gray-500 focus:tw-outline-none focus:tw-shadow-outline-blue focus:tw-border-blue-300 active:tw-bg-gray-100 active:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150"
                >
                  Previo
                </a>
                <a
                  href="#"
                  class="tw-ml-3 tw-relative tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-700 tw-bg-white hover:tw-text-gray-500 focus:tw-outline-none focus:tw-shadow-outline-blue focus:tw-border-blue-300 active:tw-bg-gray-100 active:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150"
                >
                  Siguiente
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import StatusBadge from "../markers/StatusBadge.vue";
export default {
  components: { StatusBadge },
  data() {
    return {
      loading: false,
      sel: "",
    };
  },
  computed: {
    orders() {
      return this.$store.state.orders.data;
    },
    userSizes() {
      return this.$store.getters.currentUser?.user_details?.sizes;
    },
    modalAlert: {
      get() {
        return this.$store.getters["alerts/alertModal"];
      },
      set(val) {
        this.$store.commit("alerts/alertModal", val);
      },
    },
    alertClickAway() {
      return this.$store.getters["alerts/alertModalClickAway"];
    },
  },
  methods: {
    body(e) {
      console.log(this.loading);
      if (e.target.id === "modal_window_alert" && !this.loading) {
        this.modalAlert = false;
      }
    },
    showOrder(uuid) {
      this.$router.push(`/thank-you/order/${uuid}`);
      this.modalAlert = false;
    },
    continueTo() {
      console.log("continue save and pay dispatch");

      this.$store.dispatch("saveAndPay");
    },
  },
};
</script>

<style>
</style>