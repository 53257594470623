export function mapFieldsv1(options) {
  const object = {};
  for (let x = 0; x < options.fields.length; x++) {
    const field = [ options.fields[x] ];
    object[field] = {
      get() {
        const state = options.base.reduce((base, nested) => {
          return base[nested];
        }, this.$store.state);
        return state[field];
      },
      set(value) {
        this.$store.commit(options.mutation, { [field]: value });
      }
    };
  }
  return object;
}
/**
 * @typedef {Object} FieldState
 * @property {{string:{get:function():*,set:function(Object): void}}} field - an ID.
 */

/** 
 * @param {{fields:  string[],base: string[],mutation: string}} options The fields, base and mutation 
 * @returns {Object} A get and set
 */
export function mapFields({ fields, base, mutation }) {
  const object = {};
  for (let x = 0; x < fields.length; x++) {
    // const field = [ fields[x] ];
    const field = fields[x];
    // console.log(field);

    /** @type {FieldState} */
    object[field] = {
      get() {
        const state = base.reduce((base, nested) => {
          return base[nested];
        }, this.$store.state);
        return state[field];
      },
      set(value) {
        this.$store.commit(mutation, { [field]: value });
      }
    };
    // console.log(object);
  }
  // console.log(object);

  return object;
}
