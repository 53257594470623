<template>
  <div
    class="bg-white pr pa2 w-100 w-60-ns h-100-ns vh-75 shirt flex items-center"
    :class="isLoading ? 'is-loading' : ''"
  >
    <div class="render flex items-center center tc relative">
      <canvas ref="canvas" width="624" height="676" class="relative" />

      <span
        class="monogram flex center dib items-center justify-center f2"
        data-font="0"
        data-length="0"
        style="display: none"
      >
        <span />
      </span>

      <div class="hotspots absolute top-0 left-0 z-1" data-placket="2">
        <router-link to="/second/cuello" data-id="4" class="step-nav-item">
          <!-- <span>Cuello</span> -->
          <div data-spot="collar" aria-label="Edit Collar">
            <span>Cuello</span>
          </div>
        </router-link>
        <router-link to="/second/puno" data-id="4" class="step-nav-item">
          <div data-spot="cuff" aria-label="Edit Cuff">
            <!-- <span>Cuff</span> -->
            <span>Puño</span>
          </div>
        </router-link>
        <router-link to="/second/placket" data-id="4" class="step-nav-item">
          <!-- <span>Placket</span> -->
          <div data-spot="placket" aria-label="Edit Placket">
            <span>Pechera</span>
          </div>
        </router-link>
        <router-link to="/second/bolsillo" data-id="4" class="step-nav-item">
          <!-- <span>Bolsillo</span> -->
          <div data-spot="pocket" aria-label="Edit Pocket">
            <span>Bolsillo</span>
          </div>
        </router-link>
        <!-- <div data-spot="yoke-l" aria-label="Edit Yoke">
          <span>Yoke</span>
        </div>
        <div data-spot="yoke-r" aria-label="Edit Yoke">
          <span>Yoke</span>
        </div>-->
        <router-link to="/second/botones" data-id="4" class="step-nav-item">
          <!-- <span>Botones</span> -->
          <div data-spot="buttons1" aria-label="Edit Buttons">
            <span>Botones</span>
          </div>
        </router-link>
        <router-link to="/second/botones" data-id="4" class="step-nav-item">
          <!-- <span>Botones</span> -->
          <div data-spot="buttons2" aria-label="Edit Buttons">
            <span>Botones</span>
          </div>
        </router-link>
      </div>

      <span class="render-grab-handle" />
    </div>

    <div class="zoom-layer">
      <canvas
        id="canvas-zoom"
        width="1776"
        height="1924"
        class="nt2 relative"
      />
    </div>

    <!-- <button class="zoom">

            <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="11" fill="#FFF"></circle>
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M25.293 26.707c.3906.3905 1.0239.3905 1.414 0 .3907-.3905.3907-1.0237 0-1.4142l-5.3989-5.3988C22.9878 17.8949 24 15.3156 24 12.5 24 6.14868 18.8511 1 12.5 1 6.14893 1 1 6.14868 1 12.5 1 18.8513 6.14893 24 12.5 24c2.8154 0 5.395-1.012 7.394-2.6919l5.399 5.3989zm-5.4816-4.0673C17.7556 24.1244 15.2288 25 12.5 25 5.59665 25 0 19.4036 0 12.5 0 5.59638 5.59665 0 12.5 0 19.4033 0 25 5.59638 25 12.5c0 2.729-.8758 5.2556-2.3604 7.3113l4.7744 4.7743c.7813.7811.7813 2.0476 0 2.8286-.781.7813-2.0473.7805-2.828 0l-4.7746-4.7745zM12.5 21c4.6943 0 8.5-3.8056 8.5-8.5C21 7.80559 17.1943 4 12.5 4 7.80569 4 4 7.80559 4 12.5c0 4.6944 3.80569 8.5 8.5 8.5zm0 1c5.2466 0 9.5-4.2533 9.5-9.5S17.7466 3 12.5 3C7.25342 3 3 7.2533 3 12.5S7.25342 22 12.5 22z"
                fill="#fff"></path>
              <path class="mag-glass" fill-rule="evenodd" clip-rule="evenodd"
                d="M19.894 21.3081C17.895 22.988 15.3154 24 12.5 24 6.14893 24 1 18.8513 1 12.5 1 6.14868 6.14893 1 12.5 1 18.8511 1 24 6.14868 24 12.5c0 2.8156-1.0122 5.3949-2.6919 7.394l5.3989 5.3988c.3907.3905.3907 1.0237 0 1.4142-.3901.3905-1.0234.3905-1.414 0l-5.399-5.3989zM22 12.5c0 5.2467-4.2534 9.5-9.5 9.5C7.25342 22 3 17.7467 3 12.5S7.25342 3 12.5 3c5.2466 0 9.5 4.2533 9.5 9.5z"
                fill="#AAA"></path>
              <path class="zoom-in" fill-rule="evenodd" clip-rule="evenodd" d="M12 12V9h1v3h3v1h-3v3h-1v-3H9v-1h3z"
                fill="#AAA"></path>
              <path class="zoom-out" fill-rule="evenodd" clip-rule="evenodd" d="M16 13H9v-1h7v1z" fill="#AAA"></path>
            </svg>
            <span class="sr-only">Zoom</span>

    </button>-->

    <div class="loader">
      <span />
    </div>

    <!-- <div class="render-error" v-bind:class="{ 'is-visible': ui.hasRenderError }">Rendering Error</div> -->
  </div>
</template>

<script>
import { AsyncCanvasView } from "../helpers/canvas";
export default {
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    shirtChange() {
      return this.$store.getters.shirtChange;
    },
  },

  watch: {
    shirtChange(newCount, oldCount) {
      // Our fancy notification (2).
      // time in seconds
      if (newCount) {
        const startTime = new Date();
        console.log(`Shirt change start: ${startTime} `);
        this.$store.commit("toggleLoading", true);
        this.refreshCanvas()
          .then((res) => {
            if (res) {
              console.log(res);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.$store.commit("toggleLoading", false);
            this.$store.commit("setShirtChange", false);

            const endTime = new Date();
            let timeDiff = Math.round((endTime - startTime) / 1000); //in ms
            console.log(`We finished in: ${timeDiff} seconds`);
          });
      }
    },
  },
  methods: {
    async refreshCanvas() {
      const context = this.$refs["canvas"].getContext("2d");
      const shirt = this.$store.getters.getShirt;
      try {
        const body = await AsyncCanvasView(context, shirt.tela.url);
        if (shirt.placket.url) {
          const placket = await AsyncCanvasView(context, shirt.placket.url);
        }
        const cuello = await AsyncCanvasView(context, shirt.cuello.url);
        if (shirt.bolsillo.url) {
          const bolsillo = await AsyncCanvasView(context, shirt.bolsillo.url);
        }
        const manga = await AsyncCanvasView(context, shirt.manga.url);
        const hueco_placket = await AsyncCanvasView(
          context,
          shirt.huecos_placket.url
        );
        const brand = await AsyncCanvasView(context, shirt.brand.url);
        if (shirt.boton.places.boton_manga) {
          const boton_manga = await AsyncCanvasView(
            context,
            shirt.boton.places.boton_manga
          );
        }
        if (shirt.boton.additionals.collar) {
          const boton_collar = await AsyncCanvasView(
            context,
            shirt.boton.additionals.collar
          );
        }
        if (shirt.boton.additionals.pocket) {
          const boton_pocket = await AsyncCanvasView(
            context,
            shirt.boton.additionals.pocket
          );
        }
        const boton_cuello = await AsyncCanvasView(
          context,
          shirt.boton.places.boton_cuello
        );
        const boton_camisa = await AsyncCanvasView(
          context,
          shirt.boton.places.boton_camisa
        );
        return { success: true };
      } catch (error) {
        return error;
      }
    },
  },
  // created() {
  //   // this.$store.subscribe((mutation, state) => {
  //   //   // console.log(state);
  //   //   console.log(mutation);
  //   //   if (mutation.type == "setTela") {
  //   //     this.refreshCanvas()
  //   //       .then(res => console.log(res))
  //   //       .catch(err => console.log(err))
  //   //       .finally(() => {
  //   //         this.$store.commit("toggleLoading", false);
  //   //       });
  //   //   }
  //   // });
  // },
  // mounted() {
  //   // this.$store.commit("toggleLoading", true);
  //   // this.refreshCanvas()
  //   //   .then(res => console.log(res))
  //   //   .catch(err => console.log(err))
  //   //   .finally(() => {
  //   //     this.$store.commit("toggleLoading", false);
  //   //     this.$store.commit("setShirtChange", false);
  //   //   });
  // }
};
</script>

<style>
</style>