<template>
  <div
    id="mail-shirt-modal"
    class="modal mail-shirt-modal in"
    role="dialog"
    style="display: block;"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <ValidationObserver ref="form">
          <form class="w-full" @submit.prevent="onSubmit">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click.prevent="backToSteps"
              >
                <span aria-hidden="true">x</span>
              </button>
              <h4 class="modal-title mb0">Cordina una cita con nuestro Sastre</h4>
            </div>
            <div class="modal-body text-left row">
              <div class="col-md-7">
                <p>¿Quieres que nuestro sastre vaya a su domicilio a tomarle sus medidas?</p>

                <div class="address w-100 msr-shirt-modal">
                  <!-- <div class="size--standard"> -->
                  <ul class="size-form">
                    <li>
                      <a href="#body-fit">Direccion</a>
                      <div class="form-inline pull-right">
                        <validation-provider v-slot="{ errors }" rules="required">
                          <div class>
                            <input
                              id="address"
                              v-model="address"
                              type="text"
                              class="form-control p6_left"
                              tabindex="1"
                              :class="{'not-valid':errors[0]}"
                              value
                            />
                            <!-- <div class="input-group-addon">
                        <span>cm</span>
                            </div>-->
                          </div>
                        </validation-provider>
                      </div>
                    </li>
                    <li>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target=".help-modal"
                        data-help="std-type-of-fit"
                      >Distrito</a>
                      <div class="form-inline">
                        <select id="district" v-model="district" tabindex="9" class="form-control">
                          <option
                            v-for="(hour, index) in sizeDistrict"
                            :key="index"
                            :value="hour.value"
                          >{{ hour.value }}</option>
                        </select>
                      </div>
                    </li>

                    <li>
                      <a href="#body-bicep-around">Fecha</a>
                      <div class="form-inline pull-right">
                        <date-pick
                          v-model="date"
                          class="w-100"
                          :isDateDisabled="isFutureDate"
                          :nextMonthCaption="nextMonthCaption"
                          :prevMonthCaption="prevMonthCaption"
                          :setTimeCaption="setTimeCaption"
                          :weekdays="weekdays"
                          :months="months"
                        ></date-pick>
                      </div>
                    </li>
                    <li>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target=".help-modal"
                        data-help="std-type-of-fit"
                      >Hora</a>
                      <div class="form-inline">
                        <select id="hours" tabindex="9" class="form-control">
                          <option
                            v-for="(hour, index) in sizeHours"
                            :key="index"
                            :value="hour.value"
                          >{{ hour.value }}</option>
                        </select>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-md-5">
            <p>Como funciona:</p>

            <ol>
              <li>
                <a
                  href="https://propercloth.com/documents/Measure-a-Shirt.pdf"
                  target="_blank"
                >This Form</a>
                (Optional, but helpful)
              </li>
              <li>Le escribiremos para confirmar los detalles de la cita</li>
              <li>Your name</li>
              <li>Phone number</li>
              <li>Email address</li>
              <li>Return shipping address</li>
              <li>Any special instructions</li>
            </ol>
              </div>-->
              <div class="options row w-50 w-full-sm">
                <div class="flex flex-wrap mb3 checkbox-wrap">
                  <label class="w-100 mb2 pa3 checkbox-toggle">
                    <input
                      v-model="sizeMethod"
                      type="radio"
                      name="size_method"
                      :value="valueMethod"
                    />
                    <span>
                      <i class="check-indicator"></i> Selecionar este metodo
                    </span>
                  </label>
                </div>
              </div>
              <p class="row text-left">
                <button
                  class="btn btn-full"
                  data-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                >Listo</button>
                <!-- @click.prevent="backToSteps" -->
              </p>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

// extend("minmax", {
//   validate(value, { min, max }) {
//     return parseInt(value) >= min && parseInt(value) <= max;
//   },
//   params: ["min", "max"]
// });

import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";

export default {
  components: { DatePick },
  data: () => ({
    // date: "2019-01-01"
    nextMonthCaption: "Siguiente Mes",
    prevMonthCaption: "Mes Previo ",
    setTimeCaption: "Programar tiempo:",
    weekdays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  }),
  computed: {
    sizeCheck: {
      get: function () {
        return this.$store.getters.validateSize;
      },
      set: function (value) {
        const data = {
          value: value,
          name: "date",
        };
        this.$store.commit("validateSize", data);
      },
    },
    valueMethod() {
      return this.$store.getters.sizeSetDate.value;
    },
    nameMethod() {
      return this.$store.getters.sizeSetDate.name;
    },
    sizeHours() {
      return this.$store.getters.sizeHours;
    },
    sizeDistrict() {
      return this.$store.getters.sizeDistrict;
    },
    sizeMethod: {
      get: function () {
        return this.$store.getters.sizeMethod;
      },
      set: function (value) {
        const data = {
          value: value,
          name: this.nameMethod,
        };
        this.$store.commit("sizeMethod", data);
      },
    },
    address: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].address;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "address",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    district: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].district;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "district",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    date: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].date;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "date",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    hours: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].hours;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "hours",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success && this.sizeMethod == this.valueMethod) {
          return;
        }

        this.backToSteps();

        // // Resetting Values
        // this.firstName = this.lastName = this.email = '';

        // // Wait until the models are updated in the UI
        // this.$nextTick(() => {
        //   this.$refs.form.reset();
        // });
      });
    },
    backToSteps() {
      if (this.sizeMethod == this.valueMethod) {
        this.$refs.form.validate().then((success) => {
          this.sizeCheck = !success;
        });
      }
      this.$store.commit("toggleModalOpen", false);
      this.$store.commit("setModalSetDate", false);
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date <= currentDate;
    },
  },
};
</script>

<style>
</style>