<template>
  <transition name="fade">
    <!-- :class="{'display-block':modalLogin}" -->
    <!-- <div class="tw-fixed tw-inset-0 tw-transition-opacity">
      <div
        class="tw-absolute tw-inset-0 tw-bg-gray-900 tw-opacity-75"
        @click="modalUserSizes = false"
      ></div>
    </div> -->

    <!-- @click="body" -->
    <div
      v-show="modalUserSizes"
      id="modal_window_alert_sizes"
      class="modal in display-reg modal_window_alert"
    >
      <div class="tw-fixed tw-inset-0 tw-transition-opacity">
        <div
          class="tw-absolute tw-inset-0 tw-bg-gray-900 tw-opacity-25"
          @click="modalUserSizes = false"
        ></div>
      </div>

      <div class="content modal-dialog modal-lg">
        <div class="content-inside modal-content tw-bg-gray-50">
          <div id="mw_signin_register_form">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                aria-label="Close"
                @click.prevent="modalUserSizes = false"
              >
                <span aria-hidden="true">x</span>
              </button>
              <h4 class="modal-title">Mis Favoritos</h4>
            </div>
            <div class="modal-body">
              <form
                class="form-horizontal form-neu mt0"
                style="margin-top: 0"
                role="form"
                @submit.prevent="continueTo"
              >
                <div class="msr-shirt-modal row">
                  <div class="col-xs-12"></div>
                  <div class="w-full text-left row options">
                    <div class="tw-p-0 checkbox-wrap tw-space-y-6 tw-mb-6">
                      <div
                        v-if="favorites.length > 0"
                        class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 tw-mb-12"
                      >
                        <div
                          v-for="favorite in favorites"
                          :key="favorite.id"
                          class="tw-flex tw-w-full tw-p-4"
                        >
                          <div>
                            <button
                              type="button"
                              aria-label="Go to The Everyday Mask v1.3 - Light Grey Melange (Single Mask)"
                              class="tw-shadow tw-p-0 tw-h-full tw-w-full tw-bg-white tw-relative tw-rounded"
                              @click="selectShirt(favorite.shirt)"
                            >
                              <img
                                :src="favorite.shirt.shirt_img"
                                alt
                                class="tw-w-full"
                              />
                              <div
                                class="hover:tw-bg-primary-900 focus:tw-bg-primary-900 hover:tw-bg-opacity-25 focus:tw-bg-opacity-25 tw-outline-none focus:ring tw-transition tw-rounded tw-absolute tw-h-full tw-w-full tw-inset-0"
                              ></div>
                            </button>
                            <div class="tw-flex tw-justify-end tw-mt-3">
                              <a
                                :href="
                                  `https://custom.jdoutfit.com/shirt/${favorite.shirt.uuid}`
                                "
                                target="_blank"
                                @click.prevent="copyShirtUrl(favorite.shirt)"
                              >
                                Copiar enlace
                              </a>
                            </div>
                          </div>
                          <!-- @click="selectShirt(favorite.shirt)" -->
                          <!-- v-ripple -->
                        </div>
                      </div>
                      <div v-else>
                        <div
                          class="tw-block hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-bg-gray-50 tw-transition tw-duration-150 tw-ease-in-out"
                        >
                          <div
                            class="tw-flex tw-justify-center tw-items-center"
                          >
                            <div
                              class="tw-font-medium tw-py-9 tw-text-xl tw-text-gray-500"
                            >
                              No tienes favoritos
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="tw-flex tw-flex-wrap tw--mx-2 tw-justify-end">
                    <!-- <div class="tw-w-full sm:tw-w-1/2 sm:tw-pr-2">
                      <div class="options row w-full-sm">
                        <div class="flex flex-wrap checkbox-wrap">
                          <label
                            class="w-100 mb2 pa3 checkbox-toggle"
                            style="height: auto !important; padding-top: 0"
                          >
                            <input
                              v-model="sizeMethod"
                              type="radio"
                              name="size_method"
                              :value="valueMethod"
                            />
                            <span>
                              <i class="check-indicator"></i> Selecionar este
                              metodo
                            </span>
                          </label>
                        </div>
                      </div>
                    </div> -->
                    <div class="tw-w-full sm:tw-w-1/2 align-center sm:tw-pl-2">
                      <button
                        type="submit"
                        class="btn btn-default btn-width-all border-solid-black display-flex justify-center"
                        :disabled="loading"
                      >
                        <span v-if="!loading">Listo</span>
                        <BtnLoader v-if="loading" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { copyToClipboard } from "@/helpers/copyToClipboard";
import ShirtFavorite from "./ShirtFavorite.vue";
import SizeRow from "./SizeRow";
export default {
  components: {
    // ShirtFavorite,
    // SizeRow,
  },
  data() {
    return {
      loading: false,
      sel: ""
    };
  },
  computed: {
    favorites() {
      if (!this.$store.state.currentUser?.favorites) {
        return [];
      }
      return this.$store.state.currentUser?.favorites;
    },
    selected_size: {
      get() {
        return this.$store.state.checkout.size.id;
      },
      set(value) {
        this.$store.commit("checkout/setSelectedSize", value);
      }
    },
    userSizes() {
      return this.$store.getters.currentUser?.user_details?.sizes;
    },
    valueMethod() {
      return this.$store.getters.setSize.value;
    },
    nameMethod() {
      return this.$store.getters.setSize.name;
    },
    sizeMethod: {
      get: function() {
        return this.$store.getters.sizeMethod;
      },
      set: function(value) {
        const data = {
          value: value,
          name: this.nameMethod
        };
        this.$store.commit("sizeMethod", data);
      }
    },
    modalUserSizes: {
      get() {
        return this.$store.getters["alerts/modalUserSizes"];
      },
      set(val) {
        this.$store.commit("alerts/modalUserSizes", val);
      }
    },
    finishOptions() {
      return this.$store.getters.sizeFinishes;
    },

    alertClickAway() {
      return this.$store.getters["alerts/alertModalClickAway"];
    }
  },
  methods: {
    copyShirtUrl(shirt) {
      console.log("coppied");
      copyToClipboard(`https://custom.jdoutfit.com/shirt/${shirt.uuid}`);
    },
    selectShirt(shirt) {
      console.log({ shirt });
      this.$store.dispatch("setShirtFromFavorite", shirt);
      this.modalUserSizes = false;
    },
    body(e) {
      console.log(this.loading);
      if (e.target.id === "modal_window_alert" && !this.loading) {
        this.modalUserSizes = false;
      }
    },
    continueTo() {
      this.modalUserSizes = false;
    }
  }
};
</script>

<style></style>
