<template>
  <label class="w-50 mb2">
    <input v-model="monogram_style" class type="radio" name="monogram-style" :value="value" />
    <span>
      <span>{{ label }}</span>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    monogram_style: {
      get: function() {
        return this.$store.getters.monogramParams.style;
      },
      set: function(value) {
        const data = {
          target: "style",
          value: value
        };
        this.$store.commit("setMonogramParam", data);
      }
    }
  }
};
</script>

<style>
</style>