export const AlertsParams = {
  namespaced: true,
  state: {
    alertModal: false,
    modalUserSizes: false,
    modalLogout: false,
    alertModalClickAway: false,
    alertContent: ''
  },
  mutations: {
    alertModal(state, payload) {
      state.alertModal = payload;
    },
    modalLogout(state, payload) {
      state.modalLogout = payload;
    },
    modalUserSizes(state, payload) {
      state.modalUserSizes = payload;
    },
    alertModalClickAway(state, payload) {
      state.alertModalClickAway = payload;
    }
  },
  getters: {
    alertModal(state) {
      return state.alertModal;
    },
    modalLogout(state) {
      return state.modalLogout;
    },
    modalUserSizes(state) {
      return state.modalUserSizes;
    },
    alertModalClickAway(state) {
      return state.alertModalClickAway;
    }
  },

  actions: {}
};
