<template>
  <div id="step-1" class="pb4 mb4 step--fabrics" data-step="fabric">
    <section id="fabrics" class="style-single options" data-opt="fabrics">
      <div>
        <div class="fabrics__header flex justify-between">
          <h5 class="mb4">Telas</h5>
          <div class="fabric-search" style="display: none">
            <button>
              <i class="filter-icon" aria-label="Toggle the Fabric Filter">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    class="fltr-line1"
                    x="7"
                    y="10"
                    width="18"
                    height="1"
                    fill="black"
                  />
                  <rect
                    class="fltr-line2"
                    x="7"
                    y="20"
                    width="18"
                    height="1"
                    fill="black"
                  />
                  <circle
                    class="fltr-crcl1"
                    cx="13"
                    cy="20.5"
                    r="2.5"
                    fill="#F0F0F0"
                    stroke="black"
                  />
                  <circle
                    class="fltr-crcl2"
                    cx="19"
                    cy="10.5"
                    r="2.5"
                    fill="#F0F0F0"
                    stroke="black"
                  />
                </svg>
              </i>
            </button>

            <div class="fabric-search__form form">
              <input
                class="search"
                type="text"
                name="query"
                placeholder="Search"
              />
              <!-- <span class="clear-search"></span> -->
            </div>
          </div>
        </div>

        <div class="fabrics flex flex-wrap justify-start pb6">
          <Selector
            v-for="(fabric, index) in fabrics"
            :key="index"
            :value="fabric.id"
            :img="fabric.url_img"
            :name="fabric.name"
            :img_url="fabric.url_img_body"
          />
        </div>

        <div class="fabric-search__none">
          <h3>No fabrics match your search.</h3>
        </div>
      </div>
    </section>

    <div class="selected-panel" style="display: none">
      <div>
        <div
          class="flex flex-nowrap flex-wrap-ns justify-between items-center items-end-ns"
        >
          <div class="w-80 w-60-ns pr1 pr4-ns">
            <h3 class="f5">
              <a href="#">Hudson White Wrinkle-Resistant Twill</a>
            </h3>
            <p class="sku f7">
              <span class="orig">
                <span class="price">S./ 139</span>
              </span>

              <!-- <span class="fabric-price-diff" v-if="ui.editMode">$5 Change</span> -->
              /
              <span class="pcf">PCF1078</span>
            </p>
          </div>
          <ul class="w-20 w-40-ns pa0 ma0 list">
            <li class="construction">
              <div class="flex justify-between items-baseline">
                <h4 class="flex-auto ma0 mb0">&nbsp;</h4>
                <span class="value tr gray">100s 2-ply</span>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 58.8235%"
                />
              </div>
            </li>
            <li class="thickness">
              <div class="flex justify-between items-baseline">
                <h4 class="flex-auto ma0 mb0">&nbsp;</h4>
                <span class="value tr gray">Mid</span>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 45%"
                />
              </div>
            </li>
          </ul>
          <div class="details-btn-wrap absolute top-0 right-0 pr3 nt3 pt1">
            <a href="#" class="btn btn-secondary btn-sm details">Details</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Selector from "../../components/steps/one/Selector.vue";
export default {
  components: {
    Selector,
  },
  computed: {
    fabrics() {
      return this.$store.getters.fabrics;
    },
    tela: {
      get: function () {
        return this.$store.getters.tela;
      },
      set: function (value) {
        this.$store.commit("setTela", value);
      },
    },
  },
  mounted() {},
};
</script>

<style>
</style>