import { allOrder, showOrder } from "@/api/orders";
export const Orders = {
  namespaced: true,
  state: {
    selected_uuid: null,
    selected: {
      order: {},
      loading: false
    },

    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    // first_page_url: 'http://laravel.app?page=1',
    // last_page_url: 'http://laravel.app?page=4',
    // next_page_url: 'http://laravel.app?page=2',
    prev_page_url: null,
    // path: 'http://laravel.app',
    from: 0,
    to: 0
  },
  mutations: {
    addOrder(state, payload) {
      state.data = [...state.data, payload];
    },
    setSelectedOrder(state, payload) {
      state.selected = { ...state.selected, ...payload };
    },
    setOrders(state, payload) {
      state.data = payload;
    },
    setSelected(state, payload) {
      state.selected_uuid = payload;
    }
  },
  getters: {
    selected(state) {
      return state.data.find(row => row.uuid == state.selected_uuid);
    }
  },

  actions: {
    setSelected(context, { uuid }) {
      context.commit("setSelected", uuid);
    },
    async getOrders(context, body) {
      try {
        const { data } = await allOrder();
        context.commit("setOrders", data.orders.data);
      } catch (error) {
        throw window.errorsValidate(error);
      }
    },
    async showOrder({ commit }, { uuid }) {
      commit("setSelectedOrder", { loading: true });
      return showOrder(uuid)
        .then(res => {
          const { order } = res.data;
          commit("setSelectedOrder", { order: order });
          console.log(res);
        })
        .catch(err => {
          window.errorsValidate(err);
        })
        .finally(() => {
          commit("setSelectedOrder", { loading: false });
        });
    },
    save_order(context, body) {
      console.log(body);
      context.commit("add_order", body);
    }
  }
};
