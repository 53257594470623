<template>
  <div class="size-method">
    <section class="">
      <!-- size-single -->
      <div class="size-options">
        <div>
          <header class="tw-flex tw-items-center tw-justify-between tw-mb-6">
            <button
              class="tw-overflow-hidden tw-bg-black f7 hand back-to-sizes focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
              style="justify-content: space-between"
              @click.prevent="step_selected = ''"
            >
              <span class="tw-text-white tw-bg-transparent"> &#8592; </span>
            </button>
            <h5 class="tw-mb-0 tw-text-sm tw-text-center sm:tw-text-base">
              Cordina una cita con nuestro sastre
            </h5>
            <a
              href="#"
              class="tip"
              data-toggle="popover"
              data-placement="bottom"
              data-content="Choose from traditional sizes below. You can fine tune your size in the Size Detail panel."
            >
              ?
            </a>
          </header>

          <div class="size--standard">
            <p class="tw-mb-8 tw-text-xs tw-text-center sm:tw-text-base">
              ¿Quieres que nuestro sastre vaya a su domicilio a tomarle sus
              medidas?
            </p>

            <ValidationObserver ref="form">
              <form class="tw-mb-24 w-full" @submit.prevent="onSubmit">
                <ul
                  class="tw-grid tw-grid-cols-2 tw-gap-2 size-form sm:tw-grid-cols-4 sm:tw-gap-4"
                >
                  <li class="tw-col-span-2 sm:tw-col-span-1">
                    <SelectForm
                      id="set_date_district"
                      v-model="district"
                      :options="districts"
                      label="Distrito"
                    />
                  </li>
                  <li class="tw-col-span-2 sm:tw-col-span-3">
                    <validation-provider v-slot="{ errors }" rules="required">
                      <InputForm
                        id="set_date_street_address1"
                        v-model="address"
                        type="text"
                        :error="!!errors[0]"
                        label="Dirección"
                        placeholder=" "
                        @blur="onSubmit"
                      />
                    </validation-provider>
                  </li>

                  <li class="sm:tw-col-span-2">
                    <label>Fecha</label>
                    <div class="form-inline">
                      <date-pick
                        v-model="date"
                        class="tw-shadow-sm w-100"
                        :isDateDisabled="isFutureDate"
                        :nextMonthCaption="nextMonthCaption"
                        :prevMonthCaption="prevMonthCaption"
                        :setTimeCaption="setTimeCaption"
                        :weekdays="weekdays"
                        :months="months"
                      ></date-pick>
                    </div>
                    <!-- v-model="address" -->
                    <!-- <InputForm
                          id="set_date_select"
                          :value="inputValue"
                          label="Dirección"
                          placeholder=" "
                          readonly
                          @click.native="togglePopover"
                        /> -->
                    <!-- <INPUT
                          CLASS="BG-WHITE BORDER PX-2 PY-1 ROUNDED"
                          :VALUE="INPUTVALUE"
                          V-ON="INPUTEVENTS"
                        /> -->
                    <!-- <v-date-picker
                      v-model="date"
                      :min-date="new Date()"
                      locale="es"
                      :input-debounce="500"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                       
                       
                        <div class="">
                          <label for="set_date_select">Dirección</label>
                          <div class="tw-relative tw-shadow-sm">
                            <input
                              id="set_date_select"
                              :value="inputValue"
                              name="set_date_select"
                              type="text"
                              readonly
                              class="tw-px-3 tw-py-3 tw-pt-4 tw-text-base tw-font-thin tw-leading-5 tw-transition tw-duration-150 tw-ease-in-out tw-bg-white tw-border tw-border-gray-300 tw-border-solid tw-rounded-sm tw-shadow focus:tw-bg-white hover:tw-border-gray-600 hover:tw-bg-secondary-100 focus:tw-border-gray-600 focus:tw-ring focus:tw-ring-gray-300 focus:tw-outline-none text-left"
                              v-on="inputEvents"
                            />
                          </div>
                        </div>
                      </template>
                    </v-date-picker> -->
                    <!-- v-on.capture="inputEvents" -->
                  </li>
                  <li class="sm:tw-col-span-2">
                    <SelectForm
                      id="set_date_hours"
                      v-model="hours"
                      :options="sizeHours"
                      label="Hora"
                    />
                  </li>
                </ul>
              </form>
            </ValidationObserver>
          </div>

          <p class="text-center mt2">
            <!-- <span
              class="f7 pv2 hand back-to-sizes"
              style="justify-content: space-between"
            >
              <span class="mr3" @click.prevent="step_selected = ''"
                >&#8592;</span
              > -->
            <!-- <div class="options row w-full w-full-sm">
                <div class="flex flex-wrap checkbox-wrap">
                  <label class="w-100 mb2 pa3 checkbox-toggle">
                    <input
                      v-model="sizeMethod"
                      type="radio"
                      name="size_method"
                      :value="valueMethod"
                    />
                    <span>
                      <i class="check-indicator"></i> Selecionar este metodo
                    </span>
                  </label>
                </div>
              </div> -->
            <!-- </span> -->
          </p>

          <span class="unit-footnote">
            <!-- All dimensions shown in&nbsp;
            <i>inches</i>-->
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SelectForm from "@/components/forms/SelectForm";
import InputForm from "@/components/forms/InputForm";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    DatePick,
    SelectForm,
    InputForm,
  },
  data: () => ({
    // date: "2019-01-01"
    nextMonthCaption: "Siguiente Mes",
    prevMonthCaption: "Mes Previo ",
    setTimeCaption: "Programar tiempo:",
    weekdays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  }),
  computed: {
    valueMethod() {
      return this.$store.getters.sizeSetDate.value;
    },
    nameMethod() {
      return this.$store.getters.sizeSetDate.name;
    },
    ...mapGetters({
      standardSize: "getThirdStandardSizeToggle",
      sizeFinishes: "sizeFinishes",
      sizeStandards: "sizeStandards",
    }),
    ...mapState({
      districts: (state) =>
        state.size.init.district.map((item) => {
          return {
            text: item.label,
            value: item.value,
          };
        }),
      sizeHours: (state) =>
        state.size.init.hour.map((item) => {
          return {
            text: item.label,
            value: item.value,
          };
        }),
    }),
    step_selected: {
      get() {
        return this.$store.getters["sizes/step_selected"];
      },
      set(val) {
        this.$store.commit("sizes/step_selected", val);
      },
    },
    address: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].address;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "address",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    district: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].district;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "district",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    date: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].date;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "date",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    hours: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].hours;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "hours",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    sizeCheck: {
      get: function () {
        return this.$store.getters.validateSize;
      },
      set: function (value) {
        const data = {
          value: value,
          name: "date",
        };
        this.$store.commit("validateSize", data);
      },
    },
    sizeMethod: {
      get: function () {
        return this.$store.getters.sizeMethod;
      },
      set: function (value) {
        const data = {
          value: value,
          name: this.nameMethod,
        };
        this.$store.commit("sizeMethod", data);
      },
    },
    standart_type_size_name: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod]
          .standart_type_size_name;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "standart_type_size_name",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    standart_type_of_fit_form: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod]
          .standart_type_of_fit_form;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "standart_type_of_fit_form",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
  },

  mounted() {
    this.sizeMethod = this.valueMethod;
  },
  beforeDestroy() {
    this.onSubmit();
  },
  methods: {
    isFutureDate(date) {
      var date_yesterday = new Date();
      const currentDate = new Date(
        new Date().setDate(new Date().getDate() - 1)
      );
      return date <= currentDate;
    },
    toggleStandardSize() {
      this.$store.commit("setThirdStandardSizeToggle", false);
    },

    onSubmit() {
      this.$refs.form.validate().then((success) => {
        // console.log(success);
        // if (!success) {
        //   this.sizeCheck = !success;
        //   return;
        // }

        this.sizeCheck = !success;
      });
    },
  },
};
</script>

<style>
</style>