<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$router.app._route.query.token);
    //   return router.push('/first');
    const data = {
      token: this.$router.app._route.query.token,
    };

    this.$store.dispatch("auth/passwordReset", data);
    this.$router.push("/first");
  },
};
</script>

<style>
</style>