<template>
  <div class="tw-shadow">
    <button
      :type="type"
      class="focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300 focus:tw-border-gray-600 btn tw-inline-flex tw-items-center tw-justify-center tw-transition tw-ease-in-out tw-duration-150"
      :class="[
        {
          'tw-cursor-not-allowed pointer-events-none disabled':
            loading || disabled,
        },
        { 'tw-w-full': block },
        xClasses,
      ]"
      :disabled="disabled || loading"
      @click="handleClick"
    >
      <span
        key="slot_primary"
        class="tw-justify-center tw-flex tw-ease-in tw-duration-200"
        :class="{ 'tw-opacity-0': loading, 'tw-opacity-100': !loading }"
      >
        <slot></slot>
      </span>
      <transition
        enter-active-class="tw-ease-out tw-duration-300"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-200"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
        mode="out-in"
      >
        <span
          v-if="loading"
          key="primary_spining"
          class="tw-leading-none tw-absolute"
        >
          <!-- <svg
            class="tw-animate-spin tw-h-5 tw-w-5 tw-text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="tw-opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="3"
            />
            <path
              class="tw-opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg> -->
          <svg
            class="tw-h-5 tw-animate-rotator tw-duration-300 tw-block tw-w-5 tw-visible tw-z-50"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              class="tw-animate-spindash-colors spindash-colors-path"
              fill="none"
              stroke-width="1"
              stroke-linecap="round"
              cx="12"
              cy="12"
              r="10"
            />
          </svg>
        </span>
      </transition>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    xClasses: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "submit",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    handleClick: {
      type: Function,
      default: () => null,
    },
  },
};
</script>
