export const AuthParams = {
  namespaced: true,
  state: {
    toggleForm: 'login',
    isAuthLoading: false,
    password_recovery_token: ''
  },
  mutations: {
    toggleForm(state, payload) {
      state.toggleForm = payload;
    },
    setAuthLoading(state, payload) {
      state.isAuthLoading = payload;
    },
    setPasswordRecoveryToken(state, payload) {
      state.password_recovery_token = payload.token;
    }
  },
  getters: {
    formCurrent(state) {
      return state.toggleForm;
    },
    isAuthLoading(state) {
      return state.isAuthLoading;
    },
    resetToken(state) {
      return state.password_recovery_token;
    }
  },

  actions: {
    passwordReset({ dispatch, commit, getters, rootGetters }, payload) {
      commit('setPasswordRecoveryToken', payload);
      commit('toggleForm', 'reset');
      commit('toggleModalLoginOpen', true, { root: true });
    }
  }
};
