<template>
  <label class="w-50 mb2">
    <input v-model="manga" class type="radio" name="cuff" :value="value" layer="csc" />
    <span>
      <img :src="img" />
      <span>{{ name }}</span>

      <i class="info-btn">
        A fused, crisp cuff and can be worn either buttoned like a barrel cuff, or
        with cufflinks.
      </i>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 1
    },
    name: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    img_shirt: { type: String, default: "" },
    item_id: { type: Number, default: 1 },
    btns: { type: Array, default: () => [] }
  },
  computed: {
    manga: {
      get: function() {
        return this.$store.getters.manga;
      },
      set: function(value) {
        const data = {
          value: value,
          name: this.name,
          item_id: this.item_id,
          img_url: this.img_shirt
        };
        this.$store.commit("setManga", data);
      }
    }
  }
};
</script>

<style>
</style>