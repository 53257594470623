export const Notifications = {
  namespaced: true,
  state: {
    count: 0,
    notifications: []
  },
  mutations: {
    count(state, payload) {
      state.count = payload;
    },
    notifications(state, payload) {
      state.notifications.push(payload);
    },
    notificationsRemove(state, payload) {
      state.notifications = state.notifications.filter(n => n.id !== payload);
    }
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
    count(state) {
      return state.count;
    }
  },

  actions: {
    addNotification(
      { dispatch, commit, getters, rootGetters, state },
      payload
    ) {
      const currentId = state.count;
      commit("notifications", {
        id: currentId,
        statusType: "success",
        ...payload
      });

      setTimeout(() => {
        console.log("Removing: " + currentId);
        commit("notificationsRemove", currentId);
      }, 3000);

      // let next;
      commit("count", state.count + 1);
    }
  }
};
