export const showGiftCard = id => {
  return window.NUCLIUZ.get(`/api/gift-cards/${id}`);
};
export const storeGiftCard = body => {
  return window.NUCLIUZ.post(`/api/gift-cards/orders`, body);
};
export const processGiftCard = body => {
  return window.NUCLIUZ.post(`/api/gift-cards/orders/pay`, body);
};
export const getGiftCardOrder = uuid => {
  return window.NUCLIUZ.get(`/api/gift-cards/orders/${uuid}`);
};
