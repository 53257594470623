<template>
  <span>
    <span @click="startBackToSiteAction">
      <slot />
    </span>

    <jet-dialog-modal :show="backToSiteModal" @close="closeModal">
      <template #title>
        <div class="sm:tw-flex sm:tw-items-start">
          <div
            class="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-yellow-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="tw-h-6 tw-w-6 tw-text-yellow-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div
            class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left"
          >
            <h3
              id="modal-headline"
              class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
            >
              ¿Estás seguro?
            </h3>
            <div class="tw-mt-2">
              <p class="tw-text-gray-500">
                Estas a un paso de volver al sitio de Jermyn & Duke, al hacer
                click en volver tu seleccion de camisa sera perdida. Si no
                deseas perder su seleccion, haz click en cancelar.
              </p>
            </div>
          </div>
        </div>
        <!-- {{ title }} -->
      </template>

      <template #content>
        <!-- {{ content }} -->
      </template>

      <template #footer>
        <!-- <jet-secondary-button @click.native="closeModal">
          Cerrar
        </jet-secondary-button>

        <jet-button
          class="ml-2"
          @click.native="confirmPassword"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          {{ button }}
        </jet-button> -->

        <div class="tw-flex lg:tw-flex-row lg:tw-justify-end tw-space-x-4">
          <ThirdBtn :handleClick="closeModal">Cancelar</ThirdBtn>
          <a class="tw-no-underline" href="https://www.jdoutfit.com"
            ><PrimaryBtn>
              <span class="sm:tw-hidden tw-inline-flex">Volver</span>
              <span class="tw-hidden sm:tw-inline-flex"
                >Volver a Jermyn & Duke</span
              >
            </PrimaryBtn></a
          >
        </div>
      </template>
    </jet-dialog-modal>
  </span>
</template>

<script>
// import FloatInputForm from "@/components/forms/FloatInputForm";
// import FloatSelectForm from "@/components/forms/FloatSelectForm";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import ThirdBtn from "@/components/buttons/ThirdBtn";
// import JetButton from "@/Jetstream/Button";
import JetDialogModal from "./DialogModal";
import { mapState } from "vuex";
// import JetInput from "@/Jetstream/Input";
// import JetInputError from "@/Jetstream/InputError";
// import JetSecondaryButton from "@/Jetstream/SecondaryButton";
import { updateProfile, updatePassword } from "@/api/user";

export default {
  components: {
    // JetButton,
    JetDialogModal,
    PrimaryBtn,
    ThirdBtn,
    // JetInput,
    // JetInputError,
    // JetSecondaryButton,
  },
  props: {
    title: {
      type: String,
      default: "Confirm Password",
    },
    content: {
      type: String,
      default: "For your security, please confirm your password to continue.",
    },
    button: {
      type: String,
      default: "Confirmar",
    },
  },

  data() {
    return {
      form_inputs: {
        name: "",
        doc_num: "",
        doc_type: "dni",
        phone: "",
        email: "",
      },
      profile_processing: false,
      profile_error: "",
      password_error: "",
      password_processing: false,
      backToSiteModal: false,
      processing: false,
      form: {
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    ...mapState({
      current_user: (state) => state.currentUser,
      doc_types: (state) =>
        state.constants.doc_types.map((item) => {
          const new_item = {
            text: item.label,
            value: item.name,
          };
          return new_item;
        }),
    }),
  },
  methods: {
    updateProfile() {
      console.log("updateProfile");
      this.profile_processing = true;
      this.profile_error = "";
      return updateProfile(this.form_inputs)
        .then((res) => {
          this.$store.dispatch("initUser");

          this.$store.dispatch("notifications/addNotification", {
            title: "Perfil Actualizado",
            body: "",
          });
        })
        .catch((err) => {
          this.profile_error = window.errorsValidate(err);
        })
        .finally(() => {
          this.profile_processing = false;
        });
    },
    updatePassword() {
      console.log("updatePassword");
      this.password_processing = true;
      this.password_error = "";
      return updatePassword(this.form)
        .then((res) => {
          this.$store.dispatch("notifications/addNotification", {
            title: "Contraseña Actualizada",
            body: "",
          });
        })
        .catch((err) => {
          this.password_error = window.errorsValidate(err);
        })
        .finally(() => {
          this.password_processing = false;
        });
    },
    startBackToSiteAction() {
      this.backToSiteModal = true;
      // axios.get(route("password.confirmation")).then((response) => {
      //   if (response.data.confirmed) {
      //     this.$emit("confirmed");
      //   } else {
      //     this.backToSiteModal = true;
      //     setTimeout(() => this.$refs.password.focus(), 250);
      //   }
      // });
    },

    confirmPassword() {
      this.form.processing = true;

      // axios
      //   .post(route("password.confirm"), {
      //     password: this.form.password,
      //   })
      //   .then(() => {
      //     this.form.processing = false;
      //     this.closeModal();
      //     this.$nextTick(() => this.$emit("confirmed"));
      //   })
      //   .catch((error) => {
      //     this.form.processing = false;
      //     this.form.error = error.response.data.errors.password[0];
      //     this.$refs.password.focus();
      //   });
    },

    closeModal() {
      this.backToSiteModal = false;
    },
  },
};
</script>
