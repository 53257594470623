<template>
  <div class="tw-h-full size-method">
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <section class="tw-h-full size-single">
          <div class="size-options" data-method="standard">
            <div>
              <header
                class="tw-flex tw-items-center tw-justify-between tw-mb-4"
              >
                <button
                  class="tw-overflow-hidden tw-bg-black f7 hand back-to-sizes focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
                  style="justify-content: space-between"
                  @click.prevent="step_selected = ''"
                >
                  <span class="tw-bg-transparent tw-text-white"> &#8592; </span>
                </button>
                <h5 class="tw-mb-0 tw-text-sm tw-text-center sm:tw-text-base">
                  Medida
                </h5>
                <a
                  href="#"
                  class="tw-ml-0 tip"
                  data-toggle="popover"
                  data-placement="bottom"
                  data-content="Elija entre los tamaños tradicionales a continuación. Puede ajustar su tamaño en el panel de Especificos."
                >
                  ?
                </a>
              </header>
              <div class="size--standard">
                <ul>
                  <li class="tw-col-span-1">
                    <!-- v-model="district" -->
                    <SelectForm
                      id="standart_collar_measure"
                      v-model="size"
                      :options="collarSize"
                      label="Talla"
                    />
                  </li>
                  <li>
                    <SelectForm
                      id="standart_type_of_fit_measure"
                      v-model="type_of_fit"
                      :options="finishOptions"
                      label="Acabado"
                    />
                  </li>
                </ul>
              </div>

              <div class="tw-hidden tw-w-full tw-mt-6 lg:tw-block">
                <PrimaryBtn :block="true"> Crear Medida </PrimaryBtn>
              </div>
              <span class="unit-footnote">
                Todas las medidas estan en centimetros
              </span>
            </div>
          </div>

          <div id="size-details" class="tw-pb-64 size--details text-left">
            <!-- <div class="curtain">
                      <a class="btn btn-default" href="#">View calculated size</a>
        </div>-->

            <div class="dimension-details">
              <header class="flex justify-between">
                <h5>Especificos</h5>
                <div class="flex flex-auto justify-end ml-auto items-center">
                  <a
                    id="size-reset"
                    href="#"
                    class="reset"
                    @click.prevent="resetSize"
                  >
                    Reset
                  </a>
                  <div id="save-fast-block" class="save-btns">
                    <div class="tw-hidden btn-group">
                      <button
                        type="button"
                        disabled
                        class="disabled btn btn-primary btn-sm btn-same-name"
                      >
                        Save Size
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="display: none"
                      >
                        <span class="caret" />
                      </button>
                      <ul class="dropdown-menu">
                        <li class="btn-new-name">
                          <a href="#">Save Size As</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </header>
              <!-- @submit.prevent="onSubmit" -->
              <div class="size-detail-form form-inline">
                <ul class="size-form">
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Cuello
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:27,56"
                      >
                        <div class="input-group">
                          <input
                            id="neck"
                            v-model="collar"
                            type="text"
                            class="form-control"
                            tabindex="1"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'cuello'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Largo de brazos
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:45,107"
                      >
                        <div class="input-group">
                          <input
                            id="sleeve_length"
                            v-model="sleeve_length"
                            type="text"
                            class="form-control"
                            tabindex="2"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'manga'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Hombros
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:30,72"
                      >
                        <div class="input-group">
                          <input
                            id="shoulder_width"
                            v-model="shoulder_width"
                            type="text"
                            class="form-control"
                            tabindex="3"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'cuello'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Pecho
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:76,152"
                      >
                        <div class="input-group">
                          <input
                            id="chest"
                            v-model="chest"
                            type="text"
                            class="form-control"
                            tabindex="4"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'pecho'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Cintura
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:50,152"
                      >
                        <div class="input-group">
                          <input
                            id="waist"
                            v-model="waist"
                            type="text"
                            class="form-control"
                            tabindex="5"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'cintura'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Caderas
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:60,162"
                      >
                        <div class="input-group">
                          <input
                            id="hip"
                            v-model="hip"
                            type="text"
                            class="form-control"
                            tabindex="5"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'cintura'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Largo de camisa
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:60,96"
                      >
                        <div class="input-group">
                          <input
                            id="length"
                            v-model="length"
                            type="text"
                            class="form-control"
                            tabindex="6"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'largo'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Ancho de brazos
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:20,60"
                      >
                        <div class="input-group">
                          <input
                            id="arm_width"
                            v-model="arm_width"
                            type="text"
                            class="form-control"
                            tabindex="7"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'brazo'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <li data-size="collar" class="tw-items-center">
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target=".help-modal"
                      data-help="collar-around"
                    >
                      Puño
                    </a>

                    <div class="tw-flex form-inline jd-add-on">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|minmax:15,25"
                      >
                        <div class="input-group">
                          <input
                            id="cuff_width"
                            v-model="cuff_width"
                            type="text"
                            class="form-control"
                            tabindex="8"
                            :class="{ 'not-valid': errors[0] }"
                            @focus="focus = 'punno'"
                          />
                          <!-- @keyup="validateSize(neck,'cuello',30,90)" -->
                          <div class="tw-bg-white input-group-addon">
                            <span>cm</span>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </li>
                  <div class="tw-w-full tw-mt-6 lg:tw-hidden">
                    <PrimaryBtn :block="true"> Crear Medida </PrimaryBtn>
                  </div>
                  <!-- <li data-size="collar" class="tw-items-center">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="collar-around"
                  >
                    Acabado
                  </a>

                  <div class="tw-flex form-inline jd-add-on">
                    <select
                      id="type_of_fit"
                      v-model="type_of_fit"
                      class="form-control"
                      tabindex="9"
                      @focus="measures = false"
                      @blur="measures = true"
                    >
                      <option
                        v-for="(finish, index) in finishOptions"
                        :key="index"
                        :value="finish.value"
                      >
                        {{ finish.label }}
                      </option>
                    </select>
                  </div>
                </li> -->
                  <!-- <li id="caution_1" data-size="sleeve">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="sleeve-length"
                  >
                    Sleeve Length
                  </a>
                  <span class="warning-icon" style="display: none">!</span>
                  <div class="form-group pull-right">
                    <input
                      id="sleeve_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                      in-value="34"
                    />
                    <button
                      id="sleeve_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'sleeve')"
                    >
                      -
                    </button>
                    <button
                      id="sleeve_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'sleeve')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li data-size="yoke">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="yoke-width"
                  >
                    Yoke Width
                  </a>
                  <div class="form-group pull-right">
                    <input
                      id="yoke_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                      in-value="17.75"
                    />
                    <button
                      id="yoke_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'yoke')"
                    >
                      -
                    </button>
                    <button
                      id="yoke_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'yoke')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li data-size="chest">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="chest-width"
                  >
                    Chest Width
                  </a>
                  <div class="form-group pull-right">
                    <input
                      id="chest_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                      in-value="23.5"
                    />
                    <button
                      id="chest_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'chest')"
                    >
                      -
                    </button>
                    <button
                      id="chest_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'chest')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li data-size="midsection">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="midsection-width"
                    >Midsection Width
                  </a>
                  <div class="form-group pull-right">
                    <input
                      id="midsection_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                      in-value="21"
                    />
                    <button
                      id="midsection_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'midsection')"
                    >
                      -
                    </button>
                    <button
                      id="midsection_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'midsection')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li id="caution_2" data-size="length">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="shirt-length"
                  >
                    Shirt Length
                  </a>
                  <span class="warning-icon" style="display: none">!</span>
                  <div class="form-group pull-right">
                    <input
                      id="length_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                      in-value="31"
                    />
                    <button
                      id="length_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'length')"
                    >
                      -
                    </button>
                    <button
                      id="length_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'length')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li data-size="sleeve_w">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="sleeve-width"
                  >
                    Sleeve Width
                  </a>
                  <div class="form-group pull-right">
                    <input
                      id="sleeve_w_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                      in-value="9"
                    />
                    <button
                      id="sleeve_w_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'sleeve_w')"
                    >
                      -
                    </button>
                    <button
                      id="sleeve_w_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'sleeve_w')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li data-size="cuff">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="cuff-around"
                  >
                    Cuff Around
                  </a>
                  <div class="form-group pull-right">
                    <input
                      id="cuff_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                      in-value="9"
                    />
                    <button
                      id="cuff_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'cuff')"
                    >
                      -
                    </button>
                    <button
                      id="cuff_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'cuff')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li data-size="short_sleeve_length">
                  <a
                    class="twoline"
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="ss-length"
                  >
                    Short Sleeve
                    <br />Length
                  </a>
                  <div class="form-group pull-right">
                    <input
                      id="short_sleeve_length_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                    />
                    <button
                      id="short_sleeve_length_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'short_sleeve_length')"
                    >
                      -
                    </button>
                    <button
                      id="short_sleeve_length_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'short_sleeve_length')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li data-size="short_sleeve_width">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="ss-width"
                  >
                    Sleeve Width
                  </a>
                  <div class="form-group pull-right">
                    <input
                      id="short_sleeve_width_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                    />
                    <button
                      id="short_sleeve_width_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'short_sleeve_width')"
                    >
                      -
                    </button>
                    <button
                      id="short_sleeve_width_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'short_sleeve_width')"
                    >
                      +
                    </button>
                  </div>
                </li>
                <li data-size="short_sleeve_opening_width">
                  <a
                    class="twoline"
                    href="#"
                    data-toggle="modal"
                    data-target=".help-modal"
                    data-help="ss-opening"
                  >
                    Sleeve Opening
                    <br />Width
                  </a>
                  <div class="form-group pull-right">
                    <input
                      id="short_sleeve_opening_width_field_das3"
                      type="text"
                      class="form-control"
                      readonly="readonly"
                    />
                    <button
                      id="short_sleeve_opening_width_minus"
                      type="button"
                      class="btn minus"
                      onclick="on_event_ftf('minus', 'short_sleeve_opening_width')"
                    >
                      -
                    </button>
                    <button
                      id="short_sleeve_opening_width_plus"
                      type="button"
                      class="btn plus"
                      onclick="on_event_ftf('plus', 'short_sleeve_opening_width')"
                    >
                      +
                    </button>
                  </div>
                </li> -->
                  <li class="hidden">
                    <input
                      id="rear_side_pleats_field_das3"
                      type="text"
                      class="form-control"
                      value="0"
                      readonly="readonly"
                    />
                  </li>

                  <hr class="tw-mt-8" />

                  <!-- <li>
                <a href="#" data-toggle="modal" data-target=".help-modal" data-help="bottom-width">
                  Bottom Width
                  <span
                    id="adddim_method_field_change"
                    role="default"
                    style="cursor: pointer;"
                  >(automatic)</span>
                </a>
                <div class="form-group pull-right">
                  <input
                    id="adddim_bottom_width_field_das3"
                    type="text"
                    class="form-control"
                    readonly="readonly"
                    in-value="22.75"
                  />
                  <button
                    id="adddim_bottom_width_minus"
                    type="button"
                    class="btn minus visible-hidden"
                    onclick="on_event_ftf('minus', 'adddim_bottom_width')"
                  >-</button>
                  <button
                    id="adddim_bottom_width_plus"
                    type="button"
                    class="btn plus visible-hidden"
                    onclick="on_event_ftf('plus', 'adddim_bottom_width')"
                  >+</button>
                </div>
                <div class="hidden">
                  <input id="adddim_method_field_das3" type="radio" value="default" />
                </div>
              </li>
              <li data-size="watch-allowance">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target=".help-modal"
                  data-help="watch-allowance"
                >
                  Watch
                  Allowance
                </a>
                <div class="form-group pull-right">
                  <select id="adddim_cuff_watch_allowance_field_das3" class="form-control">
                    <option value="none" selected="selected">None</option>
                    <option value="+25leftcuff" selected="selected">+0.25" Left Cuff</option>
                    <option value="+5leftcuff" selected="selected">+0.5" Left Cuff</option>
                    <option value="+75leftcuff" selected="selected">+0.75" Left Cuff</option>
                    <option value="+25rightcuff" selected="selected">+0.25" Right Cuff</option>
                    <option value="+5rightcuff" selected="selected">+0.5" Right Cuff</option>
                    <option value="+75rightcuff" selected="selected">+0.75" Right Cuff</option>
                  </select>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target=".help-modal"
                  data-help="shoulder-slope"
                >
                  Shoulder
                  Slope
                </a>
                <div class="form-group pull-right">
                  <select id="adddim_shulders_slope_field_das3" class="form-control">
                    <option value="normal" selected="selected">Normal</option>
                    <option value="square" selected="selected">Square Shoulders</option>
                    <option value="sloping" selected="selected">Sloping Shoulders</option>
                    <option value="verysloping" selected="selected">Very Sloping Shoulders</option>
                    <option value="veryverysloping" selected="selected">Very Very Sloping Shoulders</option>
                    <option value="verysquare" selected="selected">Very Square Shoulders</option>
                    <option value="veryverysquare" selected="selected">Very Very Square Shoulders</option>
                  </select>
                </div>
              </li>
              <li data-size="forearm">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target=".help-modal"
                  data-help="forearm"
                >Forearm</a>
                <div class="form-group pull-right">
                  <select id="adddim_forearm_field_das3" class="form-control">
                    <option value="slim" selected="selected">One Pleat</option>
                    <option value="traditional" selected="selected">Two Pleats</option>
                    <option value="three" selected="selected">Three Pleats</option>
                  </select>
                </div>
              </li>
              <li data-size="armpit-fit">
                <a
                  href="#"
                  class="twoline"
                  data-toggle="modal"
                  data-target=".help-modal"
                  data-help="armpit-fit"
                >Shoulder/Armpit Fit</a>
                <div class="form-group pull-right">
                  <select id="adddim_shoulder_fit_field_das3" class="form-control">
                    <option value="full" selected="selected">Full (default)</option>
                    <option value="tailored" selected="selected">Tailored</option>
                  </select>
                </div>
              </li>
              <li>
                <a href="#" data-toggle="modal" data-target=".help-modal" data-help="rear-pleats">
                  Rear
                  Pleats
                </a>
                <div class="form-group pull-right">
                  <select id="adddim_rear_pleats_field_das3" class="form-control">
                    <option value="none" selected="selected">None (default)</option>
                    <option value="side_pleats" selected="selected">Side Pleats</option>
                    <option value="center_box_pleat" selected="selected">Center Box Pleat</option>
                    <option value="center_box_pleat_locker_loop" selected="selected">
                      Center Box Pleat Locker
                      Loop
                    </option>
                  </select>
                </div>
              </li>
              <li>
                <a href="#" data-toggle="modal" data-target=".help-modal" data-help="back-darts">
                  Back
                  Darts
                </a>
                <div class="form-group pull-right">
                  <select id="adddim_back_darts_field_das3" class="form-control">
                    <option value="none" selected="selected">None (default)</option>
                    <option value="yes" selected="selected">Yes</option>
                  </select>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target=".help-modal"
                  data-help="posture"
                >Posture</a>
                <div class="form-group pull-right">
                  <select id="adddim_posture_field_das3" class="form-control">
                    <option value="standard" selected="selected">Normal (default)</option>
                    <option value="back_1_2" selected="selected">Neck Back 1/2"</option>
                    <option value="back_1" selected="selected">Neck Back 1"</option>
                    <option value="forward_1_2" selected="selected">Neck Forward 1/2"</option>
                    <option value="forward_1" selected="selected">Neck Forward 1"</option>
                  </select>
                </div>
              </li>
              <li>
                <a href="#" data-toggle="modal" data-target=".help-modal" data-help="top-button">
                  Top Button
                  Placement
                </a>
                <div class="form-group pull-right">
                  <select id="adddim_top_button_placement_field_das3" class="form-control">
                    <option value="raised_1" selected="selected">Raised 1"</option>
                    <option value="raised_1_2" selected="selected">Raised �"</option>
                    <option value="standard" selected="selected">Standard</option>
                    <option value="lowered_1_2" selected="selected">Lowered �"</option>
                  </select>
                </div>
              </li>
              <li>
                <a
                  href="#"
                  class="twoline"
                  data-toggle="modal"
                  data-target=".help-modal"
                  data-help="shirt-buttons"
                >Buttons on Shirt Front</a>
                <div class="form-group pull-right">
                  <input
                    id="adddim_buttons_on_shirt_front_field_das3"
                    type="text"
                    class="form-control full"
                    value="8"
                    readonly="read-only"
                  />
                </div>
              </li>-->
                </ul>
              </div>
            </div>
          </div>
        </section>
      </form>
    </ValidationObserver>

    <div class="size-options" data-method="saved" style="display: none">
      <div>
        <header class="flex justify-between">
          <h5>Saved Sizes</h5>
        </header>

        <div class="spinner-wrap">
          <svg
            class="spinner"
            width="65px"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              class="path"
              fill="none"
              stroke-width="3"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- <input id="shortSleeve" type="hidden" value="0" />
    <input id="m_xml_body" type="hidden" value="16.5" />
    <input id="m_xml_collar" type="hidden" value="16" />
    <input id="m_xml_chest" type="hidden" value="2" />
    <input id="m_xml_midsection" type="hidden" value="0" />
    <input id="m_xml_bottom" type="hidden" value="1.5" />
    <input id="m_xml_yoke" type="hidden" value="-0.5" />
    <input id="m_xml_length" type="hidden" value="-1" />
    <input id="m_xml_sleeve_w" type="hidden" value="0" />
    <input id="m_xml_cuff" type="hidden" value="10" />
    <input id="m_xml_sleeve" type="hidden" value="34" />-->
  </div>
</template>

<script>
import SelectForm from "@/components/forms/SelectForm";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required"
});
extend("minmax", {
  validate(value, { min, max }) {
    return parseInt(value) >= min && parseInt(value) <= max;
  },
  params: ["min", "max"]
});
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
export default {
  components: {
    PrimaryBtn,
    SelectForm
  },
  data() {
    return {
      size: 14,
      collar: "",
      sleeve_length: "",
      shoulder_width: "",
      chest: "",
      waist: "",
      hip: "",
      length: "",
      arm_width: "",
      cuff_width: "",
      type_of_fit: 2
    };
  },
  computed: {
    finishOptions() {
      // return this.$store.getters.sizeFinishes.map((item) => ({
      //   text: item.label,
      //   value: item.value,
      // }));
      return this.$store.getters["constants/finishes"];
    },
    step_selected: {
      get() {
        return this.$store.getters["sizes/step_selected"];
      },
      set(val) {
        this.$store.commit("sizes/step_selected", val);
      }
    },
    standardSize() {
      return this.$store.getters.getThirdStandardSizeToggle;
    },
    collarSize() {
      return this.$store.getters["constants/sizes"];
    },
    standard_sizes() {
      return this.$store.state.constants.standard_sizes;
    },
    standard_size() {
      return this.standard_sizes.find(row => row.size == this.size);
    }
  },
  watch: {
    size: {
      handler: "resetSize",
      immediate: true
    }
  },
  methods: {
    resetSize() {
      this.collar = this.standard_size.collar;
      this.sleeve_length = this.standard_size.sleeve_length;
      this.shoulder_width = this.standard_size.shoulder_width;
      this.chest = this.standard_size.chest;
      this.waist = this.standard_size.waist;
      this.hip = this.standard_size.hip;
      this.length = this.standard_size.length;
      this.arm_width = this.standard_size.arm_width;
      this.cuff_width = this.standard_size.cuff_width;
    },
    onSubmit() {
      const body = {
        collar: this.collar,
        sleeve_length: this.sleeve_length,
        shoulder_width: this.shoulder_width,
        chest: this.chest,
        waist: this.waist,
        hip: this.hip,
        length: this.length,
        arm_width: this.arm_width,
        cuff_width: this.cuff_width,
        id_finish: this.type_of_fit
      };
      // console.log(body);
      this.$store.dispatch("saveSizeStandard", body);
    },
    toggleStandardSize() {
      this.$store.commit("setThirdStandardSizeToggle", false);
    }
  }
};
</script>

<style></style>
