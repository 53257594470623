<template>
  <nav class="site-nav" role="navigation">
    <div class="nav-container">
      <button
        type="button"
        class="navbar-toggle collapsed"
        data-toggle="collapse"
        data-target="#main-nav"
        aria-expanded="false"
        @click.prevent="toggleMobileNavBtn"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar" />
        <span class="icon-bar" />
        <span class="icon-bar" />
      </button>
      <!-- <a href="https://propercloth.com/cart" class="cart-count-mob cart_quantity">0</a> -->

      <div class="nav-wrap ns-menu">
        <BackToSiteWarning ref="first_warn">
          <ul class="nav navbar-nav navbar--left">
            <li class="nav__item">
              <!-- <button class="back">←</button> -->

              <!-- <a href="https://propercloth.com/dress-shirts/">Shop</a> -->

              <!-- <BackToSiteWarning> -->

              <a
                href="https://jdoutfit.com/"
                class="tw-group"
                @click.prevent="() => null"
              >
                ←
                <span
                  class="tw-transition tw-opacity-0 group-hover:tw-opacity-100"
                >
                  Volver a Jermyn & Duke
                </span>
              </a>
              <!-- </BackToSiteWarning> -->
            </li>
          </ul>
        </BackToSiteWarning>
        <BackToSiteWarning ref="second_warn">
          <a
            ref="label-brand"
            class="navbar--brand"
            href="#"
            @click.prevent="() => null"
          >
            <span class="sr-only">Jermyn & Duke</span>
            <!-- <img src="../assets/logo-01.svg" /> -->
          </a>
        </BackToSiteWarning>
        <!-- <a style=" width: 165px;
  height: 60px;" class="tw-flex">
          <img src="../assets/logo-01.svg" />
        </a>-->
        <!-- <a class="navbar--brand" href="https://propercloth.com/">
          <span class="sr-only">Proper Cloth</span>
        </a>-->
        <!-- <a class href="#">
          <img
            class="w-100"
            src="https://www.jdoutfit.com/wp-content/uploads/2019/12/logo-nuevo-1.png"
            alt
            srcset
          />
        </a>-->

        <ul class="nav navbar-nav navbar--right">
          <li class="nav__item">
            <!-- <a href="https://jdoutfit.com/reference/">Ayuda</a> -->
            <a href="#" @click="addNotification">Ayuda</a>
            <div class="nav-dropdown dd--help">
              <div
                class="
                  tw-absolute
                  tw-inset-0
                  tw-flex
                  tw-justify-end
                  tw-items-end
                  tw-p-0
                  tw-pr-3
                  tw-pb-3
                "
              >
                <p class="tw-text-gray-400 tw-text-sm">v{{ version }}</p>
              </div>
              <div class="tw-relative">
                <div class="col">
                  <ul class="">
                    <li>
                      <a href="https://www.jdoutfit.com/faq">FAQs</a>
                    </li>
                    <li>
                      <hr />
                      <a href="https://jdoutfit.com/contactanos">Contactanos</a>
                    </li>
                    <li>
                      <a href="tel:+51956444665">+51 956-444-665</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li v-if="auth" class="nav__item nav__item--acct">
            <!-- href="https://propercloth.com/account/basics" -->
            <a class="nav-link truncate" href="#" aria-haspopup="true">
              <span class="truncate">Mi Cuenta</span>
            </a>
            <div class="nav-dropdown dd--account" aria-label="submenu">
              <div>
                <ul>
                  <!-- <li class="user">
                    <a href="https://propercloth.com/account/basics"><span class="truncate">Gcavanunez@</span></a>
                  </li>-->
                  <li>
                    <!-- <hr> -->
                    <!-- <a href="https://propercloth.com/account/basics">Mi Cuenta</a> -->

                    <user-modal>
                      <a href="#">Mi Cuenta</a>
                    </user-modal>
                  </li>
                  <li>
                    <!-- <a href="https://propercloth.com/account/orders">Pedidos</a> -->
                    <a href="#" @click.prevent="modalAlert = true"
                      >Mis Pedidos</a
                    >
                  </li>
                  <li>
                    <!-- <a href="https://propercloth.com/sizes">Mis Tallas</a> -->
                    <a href="#" @click.prevent="modalUserSizes = true"
                      >Mis Favoritos</a
                    >
                  </li>
                  <li class="logout">
                    <hr />
                    <a href="#" @click.prevent="logoutDestroy">Cerrar sesión</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li v-if="!auth" class="nav__item">
            <a href="#" class="nav-link nav_sign_in" @click="toggleLoginModal"
              >Ingresar</a
            >
          </li>
        </ul>
      </div>

      <div
        class="navbar-collapse mobile-menu visible-xs"
        :class="{ 'shown in': MobileNavOpen }"
      >
        <div
          class="
            tw-absolute
            tw-inset-0
            tw-flex
            tw-justify-end
            tw-items-end
            tw-p-0
            tw-pr-3
            tw-pb-3
          "
        >
          <p class="tw-text-gray-400 tw-text-sm">v{{ version }}</p>
        </div>
        <ul class="nav navbar-nav tw-relative">
          <div class="mob-menu-account">
            <a
              v-if="!auth"
              class="btn btn-sm btn-default nav_sign_in"
              href="#"
              @click.prevent="toggleLoginModal"
              >Sign In</a
            >
          </div>

          <li class="nav-item">
            <ul class="primary">
              <div v-if="auth">
                <li>
                  <user-modal>
                    <a href="#" class="nav-link">Mi Cuenta</a>
                  </user-modal>
                </li>
                <li>
                  <a
                    href="#"
                    class="nav-link"
                    @click.prevent="modalAlert = true"
                    >Mis Pedidos</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="nav-link"
                    @click.prevent="modalUserSizes = true"
                    >Mis Favoritos</a
                  >
                </li>
              </div>
            </ul>
            <ul class="secondary">
              <li>
                <a class="nav-link" href="#">Ayuda</a>
              </li>

              <li>
                <a href="https://www.jdoutfit.com/faq" class="nav-link">FAQs</a>
              </li>
              <li>
                <a href="https://jdoutfit.com/contactanos" class="nav-link"
                  >Contactanos</a
                >
              </li>
              <li>
                <a class="nav-link" href="tel:+51956444665">+51 956-444-665</a>
              </li>
            </ul>
            <ul class="tw-border-t tw-border-gray-200 secondary">
              <li v-if="auth" class="tw-py-3">
                <a href="#" class="nav-link" @click.prevent="logoutDestroy">
                  Cerrar sesión
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import UserModal from "@/components/modals/UserProfile.vue";
import BackToSiteWarning from "@/components/modals/BackToSiteWarning.vue";
export default {
  components: { UserModal, BackToSiteWarning },
  data: () => ({
    version: process.env.VUE_APP_VERSION,
  }),
  computed: {
    modalAlert: {
      get() {
        return this.$store.getters["alerts/alertModal"];
      },
      set(val) {
        this.$store.commit("alerts/alertModal", val);
      },
      // } {
      //   return this.$store.getters.isModalLoginOpen;
    },
    MobileNavOpen() {
      return this.$store.getters.MobileNavOpen;
    },
    auth() {
      return this.$store.getters.isAuth;
    },
    modalUserSizes: {
      get() {
        return this.$store.getters["alerts/modalUserSizes"];
      },
      set(val) {
        this.$store.commit("alerts/modalUserSizes", val);
      },
    },
    count: {
      get() {
        return this.$store.getters["notifications/count"];
      },
      set(val) {
        this.$store.commit("notifications/count", val);
      },
    },

    notifications: {
      get() {
        return this.$store.getters.notifications;
      },
      set(val) {
        this.$store.commit("notifications/notifications", val);
      },
    },
  },
  methods: {
    toggleMobileNavBtn() {
      return this.$store.commit(
        "MobileNavOpen",
        !this.$store.getters.MobileNavOpen
      );
    },
    toggleLoginModal() {
      this.$store.commit("toggleModalLoginOpen", true);
    },
    logoutDestroy() {
      // this.$store.commit("setAuthenticated", false);
      // window.localStorage.removeItem("token");
      this.$store.commit("alerts/modalLogout", true);
    },
    addNotification() {
      // this.$store.dispatch("notifications/addNotification", {
      //   title: "",
      //   body: "",
      // });
    },
  },
};
</script>

<style>
</style>
