<template>
  <div></div>
</template>

<script>
export default {
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  mounted() {
    console.log("shirt step");
    console.log(this.uuid);
    this.$store.commit("setShirtUUID", { uuid: this.uuid });
    this.$router.push("/first");
  },
};
</script>

<style>
</style>