export const indexSizes = (body) => {
  return window.NUCLIUZ.get(`/api/user/sizes`);
};
export const createSizes = (body) => {
  return window.NUCLIUZ.post(`/api/user/sizes`, body);
};
export const showSizes = (id) => {
  return window.NUCLIUZ.get(`/api/user/sizes/${id}`);
};
export const updateSizes = (id, body) => {
  let payload = '';
  if (body instanceof FormData) {
    body.append('_method', 'PUT');
    payload = body;
  } else {
    payload = { ...body, _method: 'PUT' };
  }
  return window.NUCLIUZ.post(`/api/user/sizes/${id}`, payload);
};
export const deleteSizes = (id) => {
  let form_data = new FormData();
  form_data.append('_method', 'DELETE');
  return window.NUCLIUZ.post(`/api/user/sizes/${id}`, form_data);
};
