<template>
  <div
    class="line-items --products mt3 pt4 bt b--black-20 is-expanded"
    v-on="inputListeners"
  >
    <div class="products-container">
      <!---->
      <!---->
      <!---->
      <div class="product-group w-100">
        <!-- <h6 class="tw-mb-2">
          <div>Camisa</div>
        </h6> -->
        <div class="w-100">
          <div
            class="tw-flex tw-items-center tw-flex-wrap sm:tw-flex-nowrap mb3"
          >
            <!--  sm:tw-w-1/3 -->
            <div class="tw-flex pr3 tw-w-full">
              <a
                href="#"
                aria-label="Go to The Everyday Mask v1.3 - Light Grey Melange (Single Mask)"
                class="tw-shadow tw-py-2 tw-px-1 tw-h-full tw-w-full tw-bg-white"
              >
                <!-- src="http://localhost:8000/imgs/custom-shirts/0abd2338-5cbb-4bc2-9b3a-4351ac8e5eee.png" -->
                <img :src="current_shirt" alt class="tw-w-full" />
              </a>
            </div>
            <!-- <div class="flex-auto flex">
              <div class="shirt-details flex-auto mt1 mb1 f7 pr3">
                <div>
                  <div>Tela</div>
                  <div>
                    <div>
                      <router-link
                        to="/first"
                        class="tag"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Editar"
                      >
                        <div>{{ shirt_fabric }}</div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div>
                  <div>Estilo</div>
                  <div>
                    <div>
                      <router-link
                        to="/second/cuello"
                        href="#"
                        class="tag"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Editar"
                      >
                        <div>{{ shirt_collar }}</div>
                      </router-link>

                      <router-link
                        to="/second/puno"
                        href="#"
                        class="tag"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Editar"
                      >
                        <div>{{ shirt_cuff }}</div>
                      </router-link>

                      <router-link
                        to="/second/bolsillo"
                        href="#"
                        class="tag"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Editar"
                      >
                        <div>{{ shirt_pocket }}</div>
                      </router-link>

                      <router-link
                        to="/second/placket"
                        href="#"
                        class="tag"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Editar"
                      >
                        <div>{{ shirt_placket }}</div>
                      </router-link>

                      <router-link
                        to="/second/botones"
                        href="#"
                        class="tag"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Editar"
                      >
                        <div>{{ shirt_btn }}</div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div>
                  <div>Monogram</div>
                  <div>
                    <div>
                      <router-link
                        to="/second/monogram"
                        class="tag"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Editar"
                      >
                        <div>
                          {{ monogram ? "Con monograma" : "Sin monograma" }}
                        </div>
                        <div class="add-on tw-hidden">+$0</div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <p class="price tr flex-none mt1 f7 pl2" style="min-width: 50px;">
                      $25.00 
                    </p>-->
          </div>
        </div>
      </div>
      <!---->
      <!---->
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "current_shirt",
    "shirt_fabric",
    "shirt_collar",
    "shirt_cuff",
    "shirt_pocket",
    "shirt_placket",
    "shirt_btn",
  ],
  computed: {
    inputListeners: function () {
      let vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event.target.value);
          },
        }
      );
    },
  },
};
</script>

<style>
</style>