<template>
  <!-- <simplebar data-simplebar-auto-hide="true" class="tw-overflow-y-auto"> -->
  <div class="config tw-p-0 tw-overflow-hidden z-2 relative">
    <!-- <div class="flex-1 flex flex-col w-0 tw-overflow-hidden"> -->
    <simplebar
      data-simplebar-auto-hide="true"
      class="tw-flex-1 tw-overflow-y-auto tw-h-full sm:tw-p-8 tw-p-2"
    >
      <!-- <FirstStep />
      <SecondStep />-->

      <transition name="fade_fast" mode="out-in">
        <router-view />
      </transition>
      <div class="change-msg">
        <div class="flex justify-between w-100">
          <p />
          <div class="flex-none pl2">
            <span class="close close-change-msg" onclick="hideChangeMsg()">
              �
            </span>
          </div>
        </div>
      </div>

      <footer class="build-footer tw-px-2 sm:tw-px-3">
        <div>
          <ForthBtn :disabled="loading" type="button" :handleClick="goBack">
            <ArrowLeft
              x-classes=" sm:tw--ml-1 sm:tw-mr-2 tw-text-gray-300"
            /><span class="tw-hidden sm:tw-inline-flex">Prev</span>
          </ForthBtn>
        </div>
        <div class>
          <p class="price tw-mr-3 sm:tw-mr-4">
            <!-- <span
              v-if="sastre_second_time"
              id="shirt-total-price"
              key="bottom_nav_price_sastre"
            >
              S./ 199
            </span>
            <span v-else id="shirt-total-price" key="bottom_nav_price_normal">
              S./ 169
            </span> -->
            <span>
              {{ total }}
            </span>
          </p>
          <div>
            <ThirdBtn
              type="button"
              :disabled="btnUnable || loading"
              :loading="loading"
              :handleClick="goNext"
            >
              {{ textBtnNext }}
              <ArrowRight x-classes=" tw--mr-1 tw-ml-2 tw-text-gray-500" />
            </ThirdBtn>
          </div>
        </div>
      </footer>
    </simplebar>
  </div>
  <!-- </simplebar> -->
  <!-- </div> -->
</template>

<script>
import ArrowLeft from "@/components/icons/ArrowLeft";
import ArrowRight from "@/components/icons/ArrowRight";
import ForthBtn from "@/components/buttons/ForthBtn";
import ThirdBtn from "@/components/buttons/ThirdBtn";
import { mapGetters } from "vuex";
export default {
  components: {
    ForthBtn,
    ArrowLeft,
    ArrowRight,
    ThirdBtn
  },
  data() {
    return {
      textBtnNext: "Siguiente",
      btnUnable: false
    };
  },
  computed: {
    sastre_second_time() {
      return this.sizeMethod === this.setDateValue && this.second_purchase;
    },
    second_purchase() {
      return this.$store.getters.currentUser.user_details?.sastre_once;
    },
    sizeMethod() {
      return this.$store.getters.sizeMethod;
    },
    setDateValue() {
      return this.$store.getters.sizeSetDate.value;
    },
    // loading() {
    //   return this.$store.getters.toBuyLoading;
    // },
    // pathChange() {
    //   return this.$store.getters.currentPath;
    // },
    // sizeName() {
    //   return this.$store.getters.sizeName;
    // },
    ...mapGetters({
      sizeName: "sizeName",
      pathChange: "currentPath",
      loading: "toBuyLoading",
      total: ["checkout/subtotal"]
    })
  },
  watch: {
    pathChange(change, old) {
      this.checkLabel(change);
    }
  },
  mounted() {
    this.checkLabel(this.pathChange);
  },
  methods: {
    checkLabel(path) {
      this.btnUnable = false;
      if (path.split("/")[1] !== "summary") {
        return (this.textBtnNext = "Siguiente");
      }
      // checks if step three has been satisfied
      if (!this.sizeName) {
        this.btnUnable = true;
      }
      return (this.textBtnNext = "Comprar");
    },
    goBack() {
      // return this.$router.go(-1);
      const thisRoute = this.$store.getters.currentPath.split("/")[1];
      if (thisRoute === "first") {
        return console.log("begin");
      }
      if (thisRoute === "second") {
        return this.$router.push({ path: "/first" });
      }
      if (thisRoute === "third") {
        // this.textBtnNext = "Comprar";
        return this.$router.push({ path: "/second" });
      }
      if (thisRoute === "summary") {
        // this.textBtnNext = "Comprar";
        return this.$router.push({ path: "/third" });
      }
    },
    goNext() {
      const thisRoute = this.$store.getters.currentPath.split("/")[1];
      if (thisRoute === "first") {
        return this.$router.push({ path: "/second" });
      }
      if (thisRoute === "second") {
        // const thisSecond = this.$store.getters.currentPath.split("/")[2];
        // if (thisSecond === "cuello") {
        //   return this.$router.push({ path: "/second/puno" });
        // }
        // if (thisSecond === "puno") {
        //   return this.$router.push({ path: "/second/bolsillo" });
        // }
        // if (thisSecond === "bolsillo") {
        //   return this.$router.push({ path: "/second/placket" });
        // }
        // if (thisSecond === "placket") {
        //   return this.$router.push({ path: "/second/botones" });
        // }
        // if (thisSecond === "botones") {
        //   return this.$router.push({ path: "/second/monogram" });
        // }
        // if (thisSecond === "monogram") {
        return this.$router.push({ path: "/third" });
        // }
        // return this.$router.push({ path: "/second/cuello" });
      }
      if (thisRoute === "third") {
        // this.textBtnNext = "Comprar";
        return this.$router.push({ path: "/summary" });
      }
      if (thisRoute === "summary") {
        // this.textBtnNext = "Comprar";
        // window.axios
        //   .get("http://www.jdoutfit.com/checkout?add-to-cart=354")
        //   .then(res => console.log(res))
        //   .catch(err => console.log(err));
        // document.cookie = "myValue=5;path=/;domain=localhost";
        // return console.log("Redirect to pay");
        // return (window.location.href =
        //   "http://www.jdoutfit.com/checkout/?add-to-cart=1336&custom_shirt=123");
        // this.loading = true;
        this.$store
          .dispatch("saveAndPay")
          .then(res => {})
          .catch(err => {})
          .finally(() => {
            // this.loading = false;
          });
        // this.$store.dispatch("saveAndPay").then(res=>{}).catch(err=>{}).finally;
      }
    }
  }
};
</script>

<style></style>
