export const Sizes = {
  namespaced: true,
  state: {
    step_selected: ''
  },
  mutations: {
    step_selected(state, payload) {
      state.step_selected = payload;
    }
  },
  getters: {
    step_selected(state) {
      return state.step_selected;
    }
  },

  actions: {
    addNotification({ dispatch, commit, getters, rootGetters, state }, payload) {
      const currentId = state.count;
      commit('notifications', { id: currentId, ...payload });

      setTimeout(() => {
        console.log('Removing: ' + currentId);
        commit('notificationsRemove', currentId);
      }, 7000);

      // let next;
      commit('count', state.count + 1);
    }
  }
};
