import axios from "axios";
window.axios = axios;

window.NUCLIUZ = window.axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://api.jdoutfit.com"
      : process.env.VUE_APP_TEST_API
});
window.NUCLIUZ.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
