<template>
  <div id="mw_forgot_password_form_2">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        :disabled="loading"
        @click.prevent="toggleLoginModal"
      >
        <span aria-hidden="true"></span>
      </button>
      <h4 class="modal-title">RECUPERAR CONTRASEÑA</h4>
    </div>
    <div class="modal-body">
      <form
        class="form-horizontal"
        style="margin-top: 0"
        role="form"
        @submit.prevent="reset"
      >
        <div class="tw-w-full tw-mb-8">
          <FloatInputForm
            id="mw_email_forgot"
            v-model="email"
            type="email"
            label="Email"
            placeholder=" "
          />
        </div>
        <!-- <div class="form-group">
          <div class="col-xs-12 control-area">
            <label for="email_field" class="sr-only">Email</label>
            <input
              id="mw_email_forgot"
              v-model="email"
              type="email_field"
              class="form-control"
              value
              placeholder="Email"
              autocomplete="username"
            />
          </div>
        </div> -->
        <!-- <div class="form-group no-border" style="margin-left:0;">
          <div id="captcha_container_fp" />
        </div> -->

        <!-- <div class="row">
          <div class="col-xs-6 padding-0 padding-right-5">
            <button
              type="button"
              class="btn btn-default-grey btn-width-all mw_close border-solid-black bg-transparent secondary text-black"
              :disabled="loading"
              @click.prevent="toggleLogin"
            >
              CANCELAR
            </button>
          </div>
          <div class="col-xs-6 padding-0 padding-left-5 align-center">
            <button
              id="mw_buttonResetPassword"
              type="submit"
              class="btn btn-default btn-width-all border-solid-black display-flex justify-center"
              :disabled="loading"
            >
              <span v-if="!loading">
                <div class="hidden-xxs">RECUPERAR CONTRASEÑA</div>
                <div class="visible-xxs">RECUPERAR</div>
              </span>
              <BtnLoader v-if="loading" />
            </button>
          </div>
        </div> -->
        <div
          class="tw-w-full tw-flex-col-reverse sm:tw-flex-row tw-flex tw-flex-wrap mt3 sm:tw-flex-nowrap sm:tw-space-x-4 tw-mb-8 sm:tw-mb-0 tw-space-y-6 tw-space-y-reverse"
        >
          <div class="tw-w-full sm:tw-w-1/2">
            <ThirdBtn
              type="button"
              :disabled="loading"
              :block="true"
              :handleClick="toggleLogin"
            >
              CANCELAR
            </ThirdBtn>
          </div>
          <div class="tw-w-full sm:tw-w-1/2">
            <PrimaryBtn type="submit" :block="true" :loading="loading">
              <div class="hidden-xxs">RECUPERAR CONTRASEÑA</div>
              <div class="visible-xxs">RECUPERAR</div>
            </PrimaryBtn>
          </div>
        </div>
      </form>
      <input id="inp_sp" type="hidden" name="inp_sp" value="sp_say_not" />
      <div class="indent-30">
        <div class="indent-5" />
        <div class="message-danger margin-top-0-imp display-block">
          {{ errorMsg }}
        </div>
        <div class="message-success margin-top-0-imp display-block">
          {{ successMsg }}
        </div>
      </div>
      <div class="align-center pb2">
        Te enviaremos los pasos para recuperar tu contraseña.
      </div>
    </div>
  </div>
</template>

<script>
import FloatInputForm from "@/components/forms/FloatInputForm";
import { resetLink } from "@/api/auth";
import BtnLoader from "@/components/btnLoader";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import ThirdBtn from "@/components/buttons/ThirdBtn";
export default {
  components: {
    // BtnLoader,
    FloatInputForm,
    PrimaryBtn,
    ThirdBtn,
  },
  data() {
    return {
      email: "",
      errorMsg: "",
      successMsg: "",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["auth/isAuthLoading"];
    },
  },
  methods: {
    toggleLoginModal() {
      return this.$store.commit("toggleModalLoginOpen", false);
    },
    toggleForgot() {
      return this.$store.commit("auth/toggleForm", "forgot");
    },
    toggleLogin() {
      return this.$store.commit("auth/toggleForm", "login");
    },
    toggleRegister() {
      return this.$store.commit("auth/toggleForm", "register");
    },
    reset() {
      this.$store.commit("auth/setAuthLoading", true);
      const body = {
        email: this.email,
      };
      this.errorMsg = "";
      resetLink(body)
        .then((res) => {
          console.log(res);
          this.email = "";
          this.successMsg = "Verifica tu bandeja";
        })
        .catch((err) => {
          console.log(err);
          this.errorMsg = err;
        })
        .finally(() => {
          this.$store.commit("auth/setAuthLoading", false);
        });
    },
  },
};
</script>

<style>
</style>