<template>
  <!-- tw-scale-100 group-hover:tw-scale-105 tw-transform -->
  <div
    class="tw-bg-white tw-rounded-lg tw-pointer-events-auto tw-cursor-pointer tw-group tw-shadow-md tw-transform tw-transition-all tw-duration-300"
  >
    <div class="tw-rounded-lg tw-shadow-xs tw-overflow-hidden">
      <div class="tw-p-4">
        <div class="tw-flex tw-items-start">
          <div class="tw-flex-shrink-0">
            <svg
              v-if="statusType === 'success'"
              class="tw-h-6 tw-w-6 tw-text-green-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <svg
              v-if="statusType === 'warning'"
              xmlns="http://www.w3.org/2000/svg"
              class="tw-h-6 tw-w-6 tw-text-red-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
            <p
              class="tw-text-sm tw-leading-5 tw-font-medium tw-text-cool-gray-900 tw-mb-0"
            >
              {{ title }}
            </p>
            <p
              class="tw-mt-1 tw-text-sm tw-leading-5 tw-text-cool-gray-500 tw-mb-0"
            >
              <span class="tw-font-semibold tw-text-cool-gray-600">{{
                body
              }}</span>
            </p>
          </div>
          <div class="tw-ml-4 tw-flex-shrink-0 tw-flex">
            <span
              class="tw-inline-flex tw-text-cool-gray-400 focus:tw-outline-none focus:tw-text-cool-gray-500 tw-transition tw-ease-in-out tw-duration-150"
              @click="$emit('remove-item', id)"
            >
              <svg
                class="tw-h-5 tw-w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },
    body: {
      type: String,
      default: ""
    },
    statusType: {
      type: String,
      default: "success"
    }
  }
};
</script>

<style></style>
