<template>
  <section id="step-cuello" class="style-single options" :class="{ 'is-active': isTrue }">
    <div class="inner">
      <header>
        <BackBtn />
        <h5>Cuello</h5>
        <!-- <span class="style-filter__toggle" v-on:click="toggleStyleFilter">Filter</span> -->
      </header>
      <!-- <div class="flex flex-wrap mb2 checkbox-wrap">
                  <label class="w-100 pa3 checkbox-toggle">
                    <input class="" type="checkbox" name="collarContrast">
                    <span><i class="check-indicator"></i> Add White Contrast Collar <i class="add-on">+$5</i>

                    </span>
                  </label>
      </div>-->

      <div class="flex flex-wrap option-grid">
        <CuelloInput
          v-for="(style,index) in collar_styles"
          id="check"
          :key="index"
          :value="style.id"
          :img="style.url_img"
          :name="style.name"
          :data-img="style.url_img"
          :img_shirt="style.url_img_body"
          :item_id="style.item_id"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BackBtn from "../../../components/BackBtn";
import CuelloInput from "../../../components/steps/two/CuelloInput";
export default {
  components: {
    BackBtn,
    CuelloInput
  },
  data() {
    return {
      isTrue: false
    };
  },
  computed: {
    collar_styles() {
      return this.$store.getters.collarStyles;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isTrue = true;
    }, 100);
  }
};
</script>

<style>
</style>