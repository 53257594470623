<template>
  <span class="most-pop" :class="{ span_error: error, special_span: !error }">{{
    error ? "Error" : "Seleccionada"
  }}</span>
</template>

<script>
export default {
  props: {
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>