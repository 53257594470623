<template>
  <ul id="step-2-nav" class="style-steps inset z-1" :class="{ 'is-visible': isStep2 }">
    <li class="step-2-nav-item" data-id="step-cuello">
      <router-link to="/second/cuello" data-id="4" class="step-nav-item">
        <span>Cuello</span>
      </router-link>
    </li>
    <li class="step-2-nav-item" data-id="step-po">
      <router-link to="/second/puno" data-id="4" class="step-nav-item">
        <span>Puño</span>
      </router-link>
    </li>
    <li class="step-2-nav-item" data-id="step-bolsillo">
      <router-link to="/second/bolsillo" data-id="4" class="step-nav-item">
        <span>Bolsillo</span>
      </router-link>
    </li>
    <li class="step-2-nav-item" data-id="step-placket">
      <router-link to="/second/placket" data-id="4" class="step-nav-item">
        <span>Pechera</span>
      </router-link>
    </li>
    <li class="step-2-nav-item" data-id="step-botones">
      <router-link to="/second/botones" data-id="4" class="step-nav-item">
        <span>Botones</span>
      </router-link>
    </li>
    <li class="step-2-nav-item" data-id="step-monogram">
      <router-link to="/second/monogram" data-id="4" class="step-nav-item">
        <span>Monogram</span>
      </router-link>
    </li>
    <li class="step-2-nav-item" data-id="step-monogram">
      <router-link to="/second" data-id="4" class="step-nav-item">
        <span>☰ Todos</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    isStep2() {
      return this.$store.getters.currentPath.split("/")[1] == "second" &&
        this.$store.getters.currentPath.split("/")[2] !== "" &&
        this.$store.getters.currentPath.split("/")[2] !== undefined
        ? true
        : false;
    }
  }
};
</script>

<style>
</style>