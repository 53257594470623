<template>
  <div
    :class="{ 'mob-nav-visible': boolMobile, 'nav-is-shown': mobileTopBar }"
    class=""
  >
    <Nav />

    <span
      class="nav-toggle tw-bg-gray-100 hover:tw-bg-gray-300 tw-cursor-pointer tw-rounded-full"
      @click="toggleHeader"
    />
    <!-- <transition name="fade_fast" mode="out-in"> -->
    <transition
      enter-active-class="tw-ease-out tw-duration-300"
      enter-class="tw-opacity-0"
      enter-to-class="tw-opacity-100"
      leave-active-class="tw-ease-in tw-duration-200"
      leave-class="tw-opacity-100"
      leave-to-class="tw-opacity-0"
      mode="out-in"
    >
      <router-view />
    </transition>
    <div class="cancel-dialog">
      <div>
        <p>Are you sure you want to cancel your shirt design changes?</p>
        <p>Any design changes you�ve made will not be applied to shirt</p>
        <div class="flex">
          <button class="btn btn-secondary btn-sm">No, Keep Editing</button>
          <a href>
            <button class="ml3 btn btn-primary btn-sm">Yes, Cancel</button>
          </a>
        </div>
      </div>
    </div>
    <div class="cancel-dialog__bg z-8" />
    <AuthContainer />
    <UserOrders />
    <UserFavorites />
    <LogoutConfirm />
    <div v-if="modalOpen" class="modal-backdrop in"></div>
    <transition name="fade">
      <span id="nav-shade" class="nav-shade" @click="closeMobile"></span>
    </transition>

    <!-- Modal Portal -->
    <portal-target name="modal" multiple> </portal-target>

    <NotificationContainer />
    <GlobalLoadingScreen />
  </div>
</template>

<script>
import Nav from "./layout/Nav";
import NotificationContainer from "./components/notifications/NotificationContainer";
import AuthContainer from "./components/modals/auth/AuthContainer";
import UserOrders from "./components/modals/UserOrders.vue";
import UserFavorites from "./components/modals/UserFavorites";
import LogoutConfirm from "./components/modals/auth/LogoutConfirm";

import Customizer from "./views/Customizer";
import Canvas from "./components/Canvas";
import FilterWrap from "./components/FilterWrap";
import ModalContainer from "./components/ModalContainer";
import LeftMenu from "./components/steps/two/LeftMenu";
import GlobalLoadingScreen from "./components/GlobalLoadingScreen";
import StepBody from "./views/StepBody";
export default {
  name: "App",
  components: {
    Nav,
    GlobalLoadingScreen,
    AuthContainer,
    UserOrders,
    NotificationContainer,
    LogoutConfirm,
    UserFavorites,
  },
  data() {
    return {};
  },
  computed: {
    getRoutePath() {
      //console.log(this.$route.path);
      return this.$route.path;
    },
    boolMobile: {
      get() {
        return this.$store.getters.toggles.navBarMobile;
      },
      set(val) {
        const body = {
          target: "mobile",
          val: val,
        };
        this.$store.commit("toggles", body);
      },
    },
    modalOpen() {
      return this.$store.getters.isModalOpen;
    },
    mobileTopBar() {
      return this.$store.getters.MobileNavOpen;
    },
    isStep2() {
      return this.$store.getters.currentPath.split("/")[1] == "second" &&
        this.$store.getters.currentPath.split("/")[2] !== "" &&
        this.$store.getters.currentPath.split("/")[2] !== undefined
        ? true
        : false;
    },
  },
  watch: {
    $route(to, from) {
      // console.log(to);
      console.log(to);
      this.$store.commit("currentPath", to);
    },
  },
  mounted() {
    // this.$store.dispatch("initStore").then(() => {

    // this.$nextTick(() => {
    this.$store.dispatch("initConstants");
    this.$store.dispatch("getFabrics");
    // .then(res=>{
    //       this.$store.dispatch("setShirtFromUUIDAtMount");
    //     });
    if (window.localStorage.getItem("token")) {
      this.$store.dispatch("initUser");
    }
    console.log("MOUNTED APP");
    console.log(this.$router.history);
    console.log(this.$router.history.current.fullPath);
    console.log(this.$route);
    console.log(this.getRoutePath);
    // if (
    //   this.$store.getters.currentPath == "/checkout" ||
    //   this.$store.getters.currentPath == "/checkout/confirm"
    // ) {
    //   console.log("fuck you ");
    //   this.$router.push("/summary");
    //   return;
    // }

    // if (
    //   this.$router.history.current.path == "/reset/response-password-reset"
    // ) {
    //   console.log(this.$router.app._route.query.token);
    //   //   return router.push('/first');
    //   const data = {
    //     token: this.$router.app._route.query.token,
    //   };

    //   this.$store.dispatch("auth/passwordReset", data);
    // }
    // });

    // localStorage.removeItem("shirt");
    // if (this.$router.history.current.path != "/first") {
    //   this.$router.push("/first");
    // }
  },
  beforeMount: function () {
    // console.log(this.$router.history.current.path);
    this.$store.commit("currentPath", this.$router.history.current);
  },
  methods: {
    toggleHeader() {
      return (this.boolMobile = !this.boolMobile);
    },
    closeMobile(e) {
      if (e.target.id === "nav-shade") {
        return this.$store.commit("MobileNavOpen", false);
      }
    },
  },
};
</script>

<style>
</style>