
<template>
  <BaseBtn
    :type="type"
    :disabled="disabled"
    :block="block"
    :loading="loading"
    x-classes="  btn btn-secondary tw-bg-white tw-text-black tw-border-black tw-border-solid tw-border focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300 hover:tw-bg-gray-300 focus:tw-bg-gray-300 "
    :handleClick="handleClick"
  >
    <slot></slot>
  </BaseBtn>
</template>

<script>
import BaseBtn from "./BaseBtn";
export default {
  components: {
    BaseBtn,
  },
  props: {
    type: {
      type: String,
      default: "submit",
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    handleClick: {
      type: Function,
      default: () => null,
    },
  },
};
</script>
