<template>
  <transition name="fade">
    <div
      v-show="modalLogout"
      id="modal_window_alert_sizes"
      class="modal in display-reg modal_window_alert"
      @click="body"
    >
      <div class="content modal-dialog modal-lg">
        <div class="content-inside modal-content">
          <div id="mw_signin_register_form">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                aria-label="Close"
                @click.prevent="modalLogout = false"
              >
                <!-- <span aria-hidden="true">x</span> -->
              </button>
              <h4 class="modal-title">Confirmar</h4>
            </div>
            <div class="modal-body">
              <form
                class="form-horizontal form-neu mt0"
                style="margin-top: 0"
                role="form"
                @submit.prevent="continueTo"
              >
                <div class="msr-shirt-modal row">
                  <div class="col-xs-12"></div>
                  <div class="w-full text-left row">
                    <p class="align-center tw-mb-8">
                      Estas seguro de cerrar sesion?
                    </p>
                  </div>
                </div>

                <!-- <div class="row">
                  <div class="tw-flex tw-flex-wrap tw--mx-2">
                    <div class="tw-w-full sm:tw-w-1/2 sm:tw-pr-2">
                      <button
                        type="button"
                        class="btn btn-default btn-width-all CreateAccount border-solid-black bg-transparent secondary text-black"
                        @click.prevent="modalLogout = false"
                      >
                        Cancelar
                      </button>
                    </div>
                    <div class="tw-w-full sm:tw-w-1/2 align-center sm:tw-pl-2">
                      <button
                        type="submit"
                        class="btn btn-default btn-width-all border-solid-black display-flex justify-center"
                        :disabled="loading"
                      >
                        <span v-if="!loading">Confirmar</span>
                        <BtnLoader v-if="loading" />
                      </button>
                    </div>
                  </div>
                </div> -->

                <div
                  class="tw-w-full tw-flex-col-reverse sm:tw-flex-row tw-flex tw-flex-wrap mt3 sm:tw-flex-nowrap sm:tw-space-x-4 tw-mb-8 sm:tw-mb-0 tw-space-y-6 tw-space-y-reverse"
                >
                  <div class="tw-w-full sm:tw-w-1/2">
                    <ThirdBtn
                      type="button"
                      :disabled="loading"
                      :block="true"
                      :handleClick="() => (modalLogout = false)"
                    >
                      CANCELAR
                    </ThirdBtn>
                  </div>
                  <div class="tw-w-full sm:tw-w-1/2">
                    <PrimaryBtn type="submit" :block="true" :loading="loading">
                      CONFIRMAR
                    </PrimaryBtn>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import ThirdBtn from "@/components/buttons/ThirdBtn";
export default {
  components: {
    PrimaryBtn,
    ThirdBtn,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    modalLogout: {
      get() {
        return this.$store.getters["alerts/modalLogout"];
      },
      set(val) {
        this.$store.commit("alerts/modalLogout", val);
      },
    },
    alertClickAway() {
      return this.$store.getters["alerts/alertModalClickAway"];
    },
  },
  methods: {
    logoutDestroy() {
      // this.$store.commit("setAuthenticated", false);
      this.$store.dispatch("logout");
      // window.localStorage.removeItem("token");
      this.$store.dispatch("notifications/addNotification", {
        title: "Has cerrado sesión",
        body: "",
      });
    },
    body(e) {
      console.log(this.loading);
      if (e.target.id === "modal_window_alert" && !this.loading) {
        this.modalLogout = false;
      }
    },
    continueTo() {
      this.logoutDestroy();
      this.$router.push("/first");
      this.modalLogout = false;
    },
  },
};
</script>
