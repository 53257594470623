<template>
  <div
    key="card_checkout"
    class="step pa3 pa4-ns bg-white-40 mb3 nl3 nr3 mh0-ns bg-white is-active"
  >
    <div v-if="false" class="tw-flex tw-w-full tw-mb-4 md:tw-hidden">
      <div class="tw-inline-flex">
        <BorderLessBtn
          type="button"
          :handleClick="
            () => $router.push({ name: 'gift-cards.checkout.shipping' })
          "
        >
          <ArrowLeft x-classes=" tw--ml-1 tw-mr-2 tw-text-gray-600" />VOLVER
        </BorderLessBtn>
      </div>
    </div>
    <form @submit.prevent="processPayment">
      <div class="step--header flex justify-between">
        <h5 class="mb0">Detalles de Pago</h5>
      </div>
      <div class="step--inner pt2" style>
        <p class="mb4">
          Todas nuestras transaciones son encryptadas por seguridad
        </p>
        <div class="radio-tabs radio-tabs--payment mb4">
          <label
            for="card"
            class="tw-flex-none tw-w-full tw-max-w-full tw-m-0 tab md:tw-w-1/2 md:tw-max-w-xl"
          >
            <input
              id="card"
              type="radio"
              name="payment-type"
              value="card"
              checked
            />
            <span>
              <i class="bold">Tarjeta de Credito/Debito</i>
            </span>
          </label>
          <label for="paypal" class="tw-hidden tab">
            <input
              id="paypal"
              type="radio"
              name="payment-type"
              value="paypal"
            />
            <span>
              <img
                width="66"
                alt="PayPal"
                src="https://propercloth.com/cart/imgs/cards/paypal.png"
                class="payment-gateway-logo"
              />
              <i class="visually-hidden">Paypal</i>
            </span>
          </label>
        </div>
        <div class="stripe-form" style>
          <div class="w-100 flex flex-wrap">
            <div class="form-group flex-auto w-100 mb3">
              <div class="card-input-wrap">
                <InputForm
                  :id="'card_number'"
                  v-model="cardNumber"
                  v-mask="generateCardNumberMask"
                  :type="'text'"
                  label="Tarjeta de Credito o Debito"
                  placeholder=" "
                  :name="'card_number'"
                >
                  <template #append-inner>
                    <i class="card-icon">
                      <img
                        id="cc_unknown"
                        src="https://propercloth.com/images/icons/cards/cc_unknown.svg"
                        alt="Unknown Card Type"
                        width="29"
                        height="19"
                        class="cc_images"
                        :class="{ 'is-active': getCardType == 'unknown' }"
                      />
                      <img
                        id="cc_amex"
                        src="https://propercloth.com/images/icons/cards/cc_amex.svg"
                        alt="American Express"
                        width="29"
                        height="19"
                        class="cc_images"
                        :class="{ 'is-active': getCardType == 'amex' }"
                      />
                      <img
                        id="cc_mastercard"
                        src="https://propercloth.com/images/icons/cards/cc_master.svg"
                        alt="Mastercard"
                        width="29"
                        height="19"
                        class="cc_images"
                        :class="{ 'is-active': getCardType == 'mastercard' }"
                      />
                      <img
                        id="cc_visa"
                        src="https://propercloth.com/images/icons/cards/cc_visa.svg"
                        alt="Visa"
                        width="29"
                        height="19"
                        class="cc_images"
                        :class="{ 'is-active': getCardType == 'visa' }"
                      />
                      <img
                        id="cc_discover"
                        src="https://propercloth.com/images/icons/cards/cc_discover.svg"
                        alt="Discover"
                        width="29"
                        height="19"
                        class="cc_images"
                        :class="{ 'is-active': getCardType == 'discover' }"
                      />
                      <img
                        id="cc_diners"
                        src="http://www.hodiafrica.org/wp-content/plugins/give/assets/dist/images/diners-club.svg"
                        alt="Dinners"
                        width="29"
                        height="19"
                        class="cc_images"
                        :class="{ 'is-active': getCardType == 'diners' }"
                      />
                    </i>
                  </template>
                </InputForm>
              </div>
            </div>
            <div class="tw-w-full tw-mb-4 form-group sm:tw-w-1/2 sm:tw-mr-4">
              <label>Exp.</label>
              <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                <SelectForm
                  id="card_expiration_month"
                  v-model="cardMonth"
                  :options="months"
                />
                <SelectForm
                  id="card_expiration_year"
                  v-model="cardYear"
                  :options="years"
                />
              </div>
            </div>
            <div class="tw-w-2/3 tw-mb-6 form-group sm:tw-w-24 w-20-l">
              <InputForm
                id="card_security_code"
                v-model="form_inputs.cvv"
                v-mask="'####'"
                type="tel"
                label="CVC"
                placeholder="123"
                name="card_security_code"
              />
            </div>
            <div
              role="alert"
              class="error w-100 mb3 pa3 bg-black-05 warning-msg dn"
            >
              <span class="message"></span>
            </div>
            <div
              class="tw-flex tw-flex-col-reverse tw-w-full tw-space-y-4 tw-space-y-reverse mt3 lg:tw-flex-row lg:tw-space-y-0 lg:tw-space-x-4"
            >
              <div class="tw-block lg:tw-inline-flex">
                <ThirdBtn
                  type="button"
                  :handleClick="
                    () => $router.push({ name: 'gift-cards.checkout.shipping' })
                  "
                >
                  <ArrowLeft
                    x-classes=" tw--ml-1 tw-mr-2 tw-text-gray-600"
                  />VOLVER
                </ThirdBtn>
              </div>
              <PrimaryBtn type="submit" :loading="loading_state">
                COMPLETAR COMPRA
                <Shield x-classes=" tw--mr-1 tw-ml-2 tw-text-gray-300" />
              </PrimaryBtn>
            </div>

            <div class="message-danger mw_text margin-top-0-imp display-block">
              {{ error_msg }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputForm from "../../components/forms/InputForm.vue";
import SelectForm from "../../components/forms/SelectForm.vue";

import { mask } from "vue-the-mask";

import ArrowLeft from "../../components/icons/ArrowLeft";
import Shield from "../../components/icons/Shield";
import PrimaryBtn from "../../components/buttons/PrimaryBtn";
import ThirdBtn from "../../components/buttons/ThirdBtn";
import BorderLessBtn from "../../components/buttons/BorderLessBtn";
import { mapState } from "vuex";
export default {
  directives: {
    mask
  },
  components: {
    ThirdBtn,
    ArrowLeft,
    Shield,
    PrimaryBtn,
    InputForm,
    SelectForm,
    BorderLessBtn
  },
  data() {
    const setType = type => {
      this.meta.card_type = type;
    };
    return {
      amexCardMask: "#### ###### #####",
      dinersCardMask: "#### ###### ####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      minCardYear: new Date().getFullYear(),
      error_msg: "",
      loading_state: false,
      meta: {
        card_type: "unkown"
      },
      cleave: {
        card: {
          creditCard: true,
          onCreditCardTypeChanged: setType
        },
        exp: {
          date: true,
          datePattern: ["m", "y"]
        },
        cvv: {
          numericOnly: true,
          delimiter: "",
          blocks: [1, 1, 1]
        }
      },
      form_inputs: {
        card: "",
        card_exp: "",
        cvv: ""
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.currentUser,
      order_uuid: state => state.giftCards.order.order_uuid
    }),
    months() {
      let months = [
        {
          text: "Mes",
          value: "",
          disabled: true
        }
      ];
      for (let n = 1; n < 13; n++) {
        const item = n < 10 ? "0" + n : n;
        months = [
          ...months,
          {
            text: item,
            value: item,
            disabled: n < this.minCardMonth
          }
        ];
      }
      return months;
    },
    years() {
      let years = [
        {
          text: "Año",
          value: "",
          disabled: true
        }
      ];
      for (let n = 0; n < 13; n++) {
        const item = n + this.minCardYear;
        years = [
          ...years,
          {
            text: item,
            value: item
          }
        ];
      }
      return years;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp("^(30[0-5]|309|36|38|39)");
      if (number.match(re) != null) return "diners";

      return "unknown"; // default type
    },
    generateCardNumberMask() {
      if (this.getCardType === "amex") {
        return this.amexCardMask;
      }
      if (this.getCardType === "diners") {
        return this.dinersCardMask;
      }
      return this.otherCardMask;
    }
  },

  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log("CARD STEP", to);
    console.log("CARD STEP", from);
    if (from.path === "/" && to.path === "/checkout/confirm") {
      return next("/");
    }
    return next();
  },
  methods: {
    processPayment() {
      this.loading_state = true;
      this.error_msg = "";
      const payload = {
        payment: {
          card: this.cardNumber.replace(/\s/g, ""),
          type: this.getCardType
        },
        culqi: {
          card_number: this.cardNumber.replace(/\s/g, ""),
          cvv: this.form_inputs.cvv,
          expiration_month: this.cardMonth,
          expiration_year: this.cardYear
          // email: this.user.email
        }
      };
      this.$store
        .dispatch("giftCards/processPayment", payload)
        .then(res => {
          console.log({ res });
          this.$router.push(`/thank-you/gift-cards/${this.order_uuid}`);
        })
        .catch(err => {
          console.log(err);
          this.error_msg = err;
        })
        .finally(() => {
          this.loading_state = false;
        });
    }
  }
};
</script>

<style></style>
