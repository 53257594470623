<template>
  <div
    class="tw-flex tw-flex-col tw-min-h-screen tw-h-screen tw-font-sans tw-antialiased tw-overflow-hidden tw-bg-gray-50"
  >
    <simplebar
      data-simplebar-auto-hide="true"
      class="tw-overflow-y-auto tw-h-full"
    >
      <div class="sm:tw-py-2">
        <div
          class="tw-flex-1 tw-px-4 tw-py-12 tw-mx-auto md:tw-max-w-2xl lg:tw-max-w-6xl xl:tw-max-w-7xl lg:tw-px-8 sm:tw-px-12 sm:tw-py-16"
        >
          <div class="tw-space-y-6" role="banner">
            <div class="tw-space-y-4 sm:tw-space-y-3">
              <h1 class="f4 sans f3-ns mt0 mb3 ml3-m tc tl-ns tw-text-left">
                {{ title }} - #{{ order }}
              </h1>

              <h6>
                <span>
                  ¿Necesitas Ayuda? - info@jdoutfit.com - +51 956-444-665
                </span>
              </h6>
            </div>
          </div>
          <hr
            class="tw-mt-5 tw-border-gray-300 tw-mb-9 border-px lg:tw-mt-6 lg:tw-mb-12"
          />
          <main
            class="tw-flex tw-flex-col-reverse lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-9"
          >
            <div class="tw-space-y-6">
              <slot name="left"></slot>
            </div>
            <div>
              <div class="tw-sticky tw-grid tw-grid-cols-1 tw-gap-4 tw-top-8">
                <slot name="right"></slot>
              </div>
            </div>
          </main>
        </div>
      </div>
    </simplebar>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "Finalizar la Compra",
    },
    order: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
</style>