export const AsyncCanvasView = (canvas, img) => {
  return new Promise(function(resolve, reject) {
    const base_image = new Image();
    base_image.crossOrigin = 'anonymous';
    // let replacing = img.replace('https://jdoutfit.com/', 'https://www.guillermocava.com/');
    // replacing = replacing.replace('https://www.jdoutfit.com/', 'https://www.guillermocava.com/');
    // let replacing = img.replace('https://www.guillermocava.com/', 'http://localhost/');
    // let replacing = img.replace('https://jdoutfit.com/', 'http://localhost:8000/');
    console.log(img);
    // replacing = img.replace('https://www.jdoutfit.com/', 'http://localhost/');
    base_image.src = img;
    try {
      base_image.onload = function() {
        resolve(canvas.drawImage(base_image, 0, 0));
      };
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
export const cleanCanvas = (canvas) => {
  const context = canvas.getContext('2d');
  context.clearRect(0, 0, canvas.width, canvas.height);
};
