<template>
  <aside>
    <div data-title="Secure Checkout" class="dn db-ns tr pt0 pb2 mb2">
      <svg
        width="18"
        height="22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 8h16v12H0V8z" fill="#AAA" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.362 11.655a.5.5 0 0 1-.017.707L6.8 16.691l-2.145-2.043a.5.5 0 0 1 .69-.724L6.8 15.31l3.855-3.672a.5.5 0 0 1 .707.017z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 7V6a3.9998 3.9998 0 0 0-4-4 4 4 0 0 0-4 4v1H2V6a6 6 0 1 1 12 0v1h-2z"
          fill="#AAA"
        />
      </svg>
    </div>
    <div class="sticky-wrap pb4">
      <!---->
      <div
        class="
          tw-bg-gray-50
          ph3
          pv3
          pa3-ns
          nl3
          nr3
          center-ns
          sidebar__header
          is-expanded
        "
      >
        <div
          class="
            header-title
            ma0
            pv1
            tl
            tc-ns
            gray
            flex
            align-baseline
            justify-between
          "
        >
          <h5 class="ma0 pv1 tl tc-ns gray">
            <span>Detalles del Pedido</span>
          </h5>
          <!---->
        </div>
        <div class="line-items --products mt3 pt4 bt b--black-20 is-expanded">
          <div class="products-container"></div>
        </div>
      </div>
      <h5 class="mb4 tc dn-ns mt4">Order Summary</h5>
      <div
        class="
          tw-bg-gray-50
          pa3 pa3-ns
          mb0
          nl3
          nr3
          center-ns
          sidebar__lineitems
        "
      >
        <div class="w-100 pr2 pr0-ns">
          <!---->
          <div id="order-line-charges" class="line-items">
            <div class="line-item flex tw-items-center">
              <p class="flex-auto truncate tw-flex tw-items-center">
                <span class="tw-flex pr3 tw-w-full sm:tw-w-24">
                  <span
                    href="#"
                    aria-label="Go to The Everyday Mask v1.3 - Light Grey Melange (Single Mask)"
                    class="
                        tw-shadow
                        tw-py-2
                        tw-px-1
                        tw-h-full
                        tw-w-full
                        tw-bg-white
                      "
                  >
                    <img
                      src="https://cdn2.propercloth.com/images/white/giftbox_red_100_108.jpg"
                      alt
                      class="tw-w-full"
                    />
                  </span>
                </span>
                <span>Gift Card</span>
              </p>
              <p class="price cart_subtotal_row">{{ subtotal }}</p>
            </div>
            <!-- tw-my-2 tw-bg-gray-200 -->
            <div class="b--black-20 bt tw-my-2"></div>
            <div class="line-item flex">
              <p class="flex-auto truncate">Subtotal</p>
              <p class="price cart_subtotal_row">{{ subtotal }}</p>
            </div>
            <div class="line-item flex">
              <p class="flex-auto truncate">
                <strong>Total</strong>
              </p>
              <p class="price">
                <strong>
                  {{ grandTotal }}
                </strong>
              </p>
            </div>
          </div>
          <div class="line-item flex items-baseline vat mt1">
            <p class="flex-auto small tr"></p>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { formatPEN } from "@/helpers/moneyHelpers";
import { mapState } from "vuex";
export default {
  components: {},
  props: {
    is_current: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapState("giftCards", {
      show: state => state.show.data
    }),
    subtotal() {
      return formatPEN(this.show.amount);
    },
    grandTotal() {
      return formatPEN(this.show.amount);
    }
  }
};
</script>

<style></style>
