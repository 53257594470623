export const createCardToken = body => {
  return window.axios.post("https://secure.culqi.com/v2/tokens", body, {
    headers: {
      // Authorization:
      Authorization:
        process.env.NODE_ENV === "production"
          ? "Bearer pk_live_47bd1e219a62f10a"
          : "Bearer pk_test_5566379e66624e1c"
    }
  });
};
export const createCharge = body => {
  return window.NUCLIUZ.post("/api/user/pay", body);
};
