import { applyOrder, createOrder } from "@/api/orders";
import { createCardToken, createCharge } from "@/api/culqi";
import { formatPEN } from "@/helpers/moneyHelpers";

export const Checkout = {
  namespaced: true,
  state: {
    size: {
      id: 0
    },
    coupon: {
      code: "",
      discount: 0,
      is_loading: false,
      applied: false,
      error: false
    },
    order: {
      order_uuid: null,
      order_item_id: null
    },
    // order_uuid: null,
    shipping: {
      full_name: "",
      doc_num: "",
      doc_type: "",
      phone: "",
      district: "",
      address: "",
      reference: "",
      additionals: ""
    },
    card: {
      card_num: "••••",
      type: "VISA"
      // cvc: ''
    }
  },
  mutations: {
    setShipping(state, payload) {
      state.shipping = { ...state.shipping, ...payload };
    },
    setCoupon(state, obj) {
      Object.keys(obj).forEach(k => (state.coupon[k] = obj[k]));
    },
    setOrderIds(state, payload) {
      state.order = { ...state.order, ...payload };
    },
    setCardNumber(state, payload) {
      const only_last_4 = payload.replace(/\d(?=\d{4})/g, "");
      state.card.card_num = `••••${only_last_4}`;
    },
    setCardType(state, payload) {
      state.card.type = payload;
    },
    setFullName(state, payload) {
      state.shipping.full_name = payload;
    },
    setDocType(state, payload) {
      state.shipping.doc_type = payload;
    },
    setDocNum(state, payload) {
      state.shipping.doc_num = payload;
    },
    setPhone(state, payload) {
      state.shipping.phone = payload;
    },
    setDistrict(state, payload) {
      state.shipping.district = payload;
    },
    setAddress(state, payload) {
      state.shipping.address = payload;
    },
    setReference(state, payload) {
      state.shipping.reference = payload;
    },
    setSelectedSize(state, payload) {
      state.size.id = payload;
    }
  },
  getters: {
    items(state, getters, rootState, rootGetters) {
      const items = [];
      const shirt_id = rootState.shirt.tela.id;
      const farbic = rootState.data.fabrics.find(row => row.id == shirt_id);
      if (!farbic) {
        return items;
      }
      items.push({
        label: `Camisa: ${farbic.name}`,
        key: "shirt",
        formatPrice: formatPEN(farbic.price),
        price: farbic.price
      });

      const has_monogram = rootState.shirt.monogram.value;
      if (has_monogram) {
        let monogram = 1500;
        items.push({
          label: "Monograma",
          key: "monogram",
          formatPrice: formatPEN(monogram),
          price: monogram
        });
      }
      // if has second
      const second_purchase = rootGetters.currentUser.user_details?.sastre_once;
      const sizeMethod = rootGetters.sizeMethod;
      const setDateValue = rootGetters.sizeSetDate.value;
      const sastre_second_time = sizeMethod === setDateValue && second_purchase;
      if (sastre_second_time) {
        let saster_second_price = 3000;
        items.push({
          label: "Visita del sastre",
          key: "sastre",
          formatPrice: formatPEN(saster_second_price),
          price: saster_second_price
        });
      }

      return items;
    },
    subtotal(state, getters, rootState, rootGetters) {
      return formatPEN(getters.rawSubtotal);
    },
    rawSubtotal(state, getters, rootState, rootGetters) {
      const shirt_id = rootState.shirt.tela.id;
      const farbic = rootState.data.fabrics.find(row => row.id == shirt_id);
      let total = 0;
      if (!farbic) {
        return formatPEN(total);
      }
      total = farbic.price;
      // price of the clothing
      // if has monogram
      const has_monogram = rootState.shirt.monogram.value;
      if (has_monogram) {
        total = total + 1500;
      }
      // if has second
      const second_purchase = rootGetters.currentUser.user_details?.sastre_once;
      const sizeMethod = rootGetters.sizeMethod;
      const setDateValue = rootGetters.sizeSetDate.value;
      const sastre_second_time = sizeMethod === setDateValue && second_purchase;
      if (sastre_second_time) {
        total = total + 3000;
      }
      return total;
    },
    total(state, getters, rootState, rootGetters) {
      return formatPEN(getters.rawTotal);
    },
    rawTotal(state, getters, rootState, rootGetters) {
      const total = getters.rawSubtotal;
      const shippingTotal = rootGetters["constants/shippingPrice"];
      return total + shippingTotal;
    },
    rawGrandTotal(state, getters) {
      const rawTotal = getters.rawTotal;
      return rawTotal - state.coupon.discount;
    }
  },
  actions: {
    setLatestSize({ commit, rootState }, payload) {
      const arr = rootState.currentUser.user_details.sizes;
      if (arr.length) {
        const id = rootState.currentUser.user_details.sizes[arr.length - 1].id;
        commit("setSelectedSize", id);
      }
    },
    resetShippingFields(context, payload) {
      Object.keys(context.state.shipping).map((key, index) =>
        context.commit("setShipping", { [key]: "" })
      );
      context.commit("setCheckoutShippingFields", null, { root: true });
    },
    applyOrder(
      { dispatch, commit, getters, rootGetters, state, rootState },
      payload
    ) {
      commit("setCoupon", { is_loading: true, error: false });
      return applyOrder({
        code: payload.code,
        raw_total: getters.rawTotal
      })
        .then(({ data }) => {
          console.log({ data });
          commit("setCoupon", {
            discount: data.discount,
            applied: true
          });
        })
        .catch(err => {
          console.log(err);
          commit("setCoupon", {
            error: true
          });
          dispatch(
            "notifications/addNotification",
            {
              title: "El cupon no valido",
              body: "",
              statusType: "warning"
            },
            {
              root: true
            }
          );
        })
        .finally(() => {
          commit("setCoupon", { is_loading: false });
        });
    },
    async createCharge({}, payload) {
      // envias datos al backend con el uuid para actualizar el pedido
      return createCharge(payload);
    },
    async createCardToken({}, payload) {
      return createCardToken(payload);
    },
    async createOrder({}, payload) {
      // set uuid del pedido
      return createOrder(payload);
    },

    async processPayment(
      { dispatch, commit, getters, rootGetters, state, rootState },
      payload
    ) {
      const { culqi, payment } = payload;
      try {
        const shirt = await dispatch(
          "saveOrFirstShirt",
          { format: "key_value", canvas_b64: "b64" },
          { root: true }
        );
        const size_option = {
          size_option: rootState.sizes.step_selected,
          id_size: state.size.id,
          date_option_address: rootState.size.values.meet_with_sastre.address,
          date_option_district: rootState.size.values.meet_with_sastre.district,
          date_option_date: rootState.size.values.meet_with_sastre.date,
          date_option_time: rootState.size.values.meet_with_sastre.hours
        };
        const monogram = {
          has_monogram: rootState.shirt.monogram.value,
          monogram_style: rootState.shirt.monogram.style,
          monogram_color: rootState.shirt.monogram.color,
          monogram_word: rootState.shirt.monogram.word,
          monogram_position: rootState.shirt.monogram.position
        };
        const order = {
          ...state.order,
          ...state.shipping,
          ...payment,
          ...shirt,
          ...size_option,

          ...monogram,
          coupon: state.coupon
        };
        const [data_card_token, data_order] = await Promise.all([
          dispatch("createCardToken", culqi),
          dispatch("createOrder", order).then(res => {
            const { data } = res;
            // console.log(data.order.uuid);
            // console.log(data.order_item.id);
            commit("setOrderIds", {
              order_uuid: data.order.uuid,
              order_item_id: data.order_item.id
            });
            return res;
          })
        ]);
        const payload_create_charge = {
          source_id: data_card_token.data.id,
          uuid: data_order.data.order.uuid,
          coupon: state.coupon
        };
        // const { data } =
        await dispatch("createCharge", payload_create_charge);
        await dispatch("orders/getOrders", {}, { root: true });
        commit("setCardNumber", payment.card);
        commit("setCardType", payment.type);
        dispatch("initUser", {}, { root: true });
        dispatch("resetShippingFields");
      } catch (error) {
        if (error.response?.data?.merchant_message) {
          throw error.response.data.merchant_message;
        } else {
          throw window.errorsValidate(error);
        }
      }
    },
    async processZeroPayment(
      { dispatch, commit, getters, rootGetters, state, rootState },
      payload
    ) {
      const { payment } = payload;
      try {
        const shirt = await dispatch(
          "saveOrFirstShirt",
          { format: "key_value", canvas_b64: "b64" },
          { root: true }
        );
        const size_option = {
          size_option: rootState.sizes.step_selected,
          id_size: state.size.id,
          date_option_address: rootState.size.values.meet_with_sastre.address,
          date_option_district: rootState.size.values.meet_with_sastre.district,
          date_option_date: rootState.size.values.meet_with_sastre.date,
          date_option_time: rootState.size.values.meet_with_sastre.hours
        };
        const monogram = {
          has_monogram: rootState.shirt.monogram.value,
          monogram_style: rootState.shirt.monogram.style,
          monogram_color: rootState.shirt.monogram.color,
          monogram_word: rootState.shirt.monogram.word,
          monogram_position: rootState.shirt.monogram.position
        };
        const order = {
          ...state.order,
          ...state.shipping,
          ...payment,
          ...shirt,
          ...size_option,

          ...monogram,
          coupon: state.coupon
        };
        const [data_order] = await Promise.all([
          dispatch("createOrder", order).then(res => {
            const { data } = res;
            // console.log(data.order.uuid);
            // console.log(data.order_item.id);
            commit("setOrderIds", {
              order_uuid: data.order.uuid,
              order_item_id: data.order_item.id
            });
            return res;
          })
        ]);
        const payload_create_charge = {
          uuid: data_order.data.order.uuid,
          coupon: state.coupon
        };
        // const { data } =
        await dispatch("createCharge", payload_create_charge);
        await dispatch("orders/getOrders", {}, { root: true });
        commit("setCardNumber", payment.card);
        commit("setCardType", payment.type);
        dispatch("initUser", {}, { root: true });
        dispatch("resetShippingFields");
      } catch (error) {
        if (error.response?.data?.merchant_message) {
          throw error.response.data.merchant_message;
        } else {
          throw window.errorsValidate(error);
        }
      }
    }
  }
};
