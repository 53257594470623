<template>
  <span
    class="tw-px-2 tw-py-1 tw-font-semibold tw-leading-tight tw-rounded-md tw-shadow tw-whitespace-nowrap"
    :class="[color]"
  >
    {{ label }}
  </span>
</template>

<script>
const status_classes = {
  created: "tw-text-yellow-700 tw-bg-yellow-100",
  paid: "tw-text-green-700 tw-bg-green-100",
  error: "tw-text-red-700 tw-bg-red-100",
  completed: "tw-text-primary-700 tw-bg-primary-100",
  cancelled: "tw-text-gray-700 tw-bg-gray-100",
};
export default {
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    name: {
      type: String,
      default: "created",
    },
    label: {
      type: String,
      default: "created",
    },
  },

  computed: {
    color() {
      return status_classes[this.name];
    },
  },
};
</script>

<style>
</style>