<template>
  <PurchaseLayout :key="uuid" title="Muchas Gracias" :order="code">
    <template #left>
      <transition
        enter-active-class="tw-ease-out tw-duration-150"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-100"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
        mode="out-in"
      >
        <div
          v-if="loading_order"
          key="loading-container-shirt"
          class="tw-w-full"
        >
          <div
            class="
              tw-border
              tw-border-gray-300
              tw-shadow
              tw-rounded-md
              tw-p-4
              tw-w-full
              tw-mx-auto
            "
          >
            <div class="tw-animate-pulse tw-flex tw-space-x-4">
              <div class="tw-rounded-3xl tw-bg-gray-400 tw-h-32 tw-w-32"></div>
              <div class="tw-flex-1 tw-space-y-4 tw-py-1">
                <div
                  class="
                    tw-grid tw-grid-cols-1
                    sm:tw-grid-cols-2
                    tw-gap-x-6 tw-gap-y-8
                  "
                >
                  <div
                    class="
                      tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full tw-col-span-2
                    "
                  ></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                </div>
                <div class="tw-space-y-4">
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <aside v-if="!loading_order" key="loaded-container-shirt">
          <div data-title="Secure Checkout" class="dn db-ns tr pt0 pb2 mb2">
            <svg
              width="18"
              height="22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 8h16v12H0V8z" fill="#AAA" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.362 11.655a.5.5 0 0 1-.017.707L6.8 16.691l-2.145-2.043a.5.5 0 0 1 .69-.724L6.8 15.31l3.855-3.672a.5.5 0 0 1 .707.017z"
                fill="#fff"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 7V6a3.9998 3.9998 0 0 0-4-4 4 4 0 0 0-4 4v1H2V6a6 6 0 1 1 12 0v1h-2z"
                fill="#AAA"
              />
            </svg>
          </div>
          <div class="sticky-wrap pb4">
            <!---->
            <div
              class="
                tw-bg-gray-50
                ph3
                pv3
                pa3-ns
                nl3
                nr3
                center-ns
                sidebar__header
                is-expanded
              "
            >
              <div
                class="
                  header-title
                  ma0
                  pv1
                  tl
                  tc-ns
                  gray
                  flex
                  align-baseline
                  justify-between
                "
              >
                <h5 class="ma0 pv1 tl tc-ns gray">
                  <span>Detalles del Pedido</span>
                </h5>
                <!---->
              </div>
              <div
                class="line-items --products mt3 pt4 bt b--black-20 is-expanded"
              >
                <div class="products-container">
                  <!---->
                  <!---->
                  <!---->
                  <div class="product-group w-100"></div>
                  <!---->
                  <!---->
                </div>
              </div>
            </div>
            <!-- <h5 class="mb4 tc dn-ns mt4">Order Summary</h5> -->
            <div
              class="
                tw-bg-gray-50
                pa3 pa3-ns
                mb0
                nl3
                nr3
                center-ns
                sidebar__lineitems
              "
            >
              <div class="w-100 pr2 pr0-ns">
                <!---->
                <div id="order-line-charges" class="line-items">
                  <div class="line-item flex tw-items-center">
                    <p class="flex-auto truncate tw-flex tw-items-center">
                      <span class="tw-flex pr3 tw-w-full sm:tw-w-24">
                        <span
                          href="#"
                          aria-label="Go to The Everyday Mask v1.3 - Light Grey Melange (Single Mask)"
                          class="
                        tw-shadow
                        tw-py-2
                        tw-px-1
                        tw-h-full
                        tw-w-full
                        tw-bg-white
                      "
                        >
                          <img
                            src="https://cdn2.propercloth.com/images/white/giftbox_red_100_108.jpg"
                            alt
                            class="tw-w-full"
                          />
                        </span>
                      </span>
                      <span>
                        <div>Gift Card</div>
                        <div class="tw-font-bold">
                          Cupón: {{ giftCardCode }}
                        </div>
                      </span>
                    </p>
                    <p class="price cart_subtotal_row">{{ total }}</p>
                  </div>
                </div>
                <div class="line-item flex items-baseline vat mt1">
                  <p class="flex-auto small tr"></p>
                </div>
              </div>
              <!---->
            </div>
            <!---->
            <div
              class="
                free-ship
                bg-black-05
                br3
                br--bottom
                pa3
                tc
                mt0
                nl1
                nr1
                center-ns
                tw-hidden
              "
            >
              <p class="mb0">Free shipping on orders over $200.00</p>
              <!---->
            </div>
            <!---->
            <div class="pa3 mt2 gift-code tw-hidden">
              <p class="ma0 flex justify-center f7 expand-toggle tc">
                <span>Apply Gift Code</span>
                <span class="icon"></span>
              </p>
              <!---->
            </div>
            <!---->
          </div>
        </aside>
      </transition>
    </template>
    <template #right>
      <transition
        enter-active-class="tw-ease-out tw-duration-150"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-100"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
        mode="out-in"
      >
        <div v-if="loading_order" key="loading-container" class="tw-w-full">
          <div
            class="
              tw-border
              tw-border-gray-300
              tw-shadow
              tw-rounded-md
              tw-p-4
              tw-w-full
              tw-mx-auto
            "
          >
            <div class="tw-animate-pulse tw-flex tw-space-x-4">
              <!-- <div class="tw-rounded-full tw-bg-gray-400 tw-h-12 tw-w-12"></div> -->
              <div class="tw-flex-1 tw-space-y-4 tw-py-1">
                <div
                  class="
                    tw-grid tw-grid-cols-1
                    sm:tw-grid-cols-2
                    tw-gap-x-6 tw-gap-y-8
                  "
                >
                  <div
                    class="
                      tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full tw-col-span-2
                    "
                  ></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                </div>
                <div class="tw-space-y-4">
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!loading_order"
          key="order-container"
          class="w-100 flex-auto"
        >
          <!---->
          <div class="view--checkout">
            <div class="steps">
              <div
                class="
                  step
                  tw-p-0
                  bg-white-40
                  mb3
                  nl3
                  nr3
                  mh0-ns
                  bg-white
                  is-active
                "
              >
                <div class="tw-p-6 sm:tw-p-8">
                  <div class="tw-text-gray-800 tw-text-sm">
                    <div
                      class="
                        tw-text-lg tw-font-medium tw-leading-7 tw-text-gray-900
                      "
                    >
                      Detalles de la Compra
                    </div>
                    <div
                      class="tw-text-sm tw-leading-7 tw-text-gray-900 tw-mb-4"
                    >
                      Revisa tu bandeja por una copia de los detalles de tu
                      compra
                    </div>
                    <div
                      class="
                        tw-min-w-0 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center tw-mb-4
                      "
                    >
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Nombre
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_full_name }}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Documento de Identidad
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.doc_type.label }}
                            {{ selected_order.billing_doc_number }}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div
                      class="
                        tw-min-w-0 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center tw-mb-4
                      "
                    >
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Teléfono
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_phone }}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Email
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_email }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        tw-min-w-0 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center tw-mb-4
                      "
                    >
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Forma de Pago
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            Tarjeta de Credito
                          </h5>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Tarjeta
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.card_type }}
                            {{ selected_order.card_last_four }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        tw-min-w-0 tw-mb-4 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center
                      "
                    >
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            <!-- <p
                class="mb-0 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-gray-400"
              >-->
                            Direccion
                          </p>
                          <!-- <h5
                class="tw-title-font tw-text-lg tw-font-medium tw-text-gray-900 tw-mb-3"
              > -->
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_address }}
                          </h5>
                        </div>
                      </div>
                      <div class="tw-flex tw-space-x-12">
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Distrito
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_district }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        tw-min-w-0 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center
                      "
                    >
                      <div>
                        <p
                          class="
                            tw-text-sm tw-font-medium tw-text-gray-600
                            dark:tw-text-gray-400
                            tw-mb-1
                          "
                        >
                          Referencia
                        </p>
                        <h5
                          class="
                            tw-title-font
                            tw-font-medium
                            tw-text-gray-900
                            tw-mb-3
                          "
                        >
                          {{ selected_order.billing_reference }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!-- tw-hidden md: -->
                  <div class="tw-w-full tw-flex mt3 md:tw-space-x-4">
                    <div class="tw-inline-flex">
                      <PrimaryBtn
                        type="button"
                        :handleClick="() => $router.push('/first')"
                      >
                        Crear un nuevo pedido
                      </PrimaryBtn>
                    </div>
                  </div>
                </div>
                <div class="tw-p-6 sm:tw-p-8 tw-mt-8 tw-bg-blue-300 tw-hidden">
                  <div
                    class="
                      tw-text-lg tw-font-medium tw-leading-7 tw-text-gray-900
                    "
                  >
                    Detalles de la Compra
                  </div>
                  <div class="tw-text-sm tw-leading-7 tw-text-gray-900">
                    Revisa tu bandeja por una copia de los detalles de tu compra
                  </div>
                  <dl
                    class="
                      tw-flex
                      tw-flex-col
                      tw-border-t
                      tw-border-b
                      tw-border-gray-200
                      tw-divide-y
                      tw-divide-gray-200
                    "
                  >
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Amp Models
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">200</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Effects Loop
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">Yes</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Inputs
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">2 x 1/4”</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Outputs
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">
                        1 x 1/4”, 2 x XLR, 2 x 1/4”
                      </dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        MIDI I/O
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">In/Out/Thru</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Height
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">8.54”</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Width
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">14.88”</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Depth
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">6.81”</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Weight
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">11.73 lbs</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </PurchaseLayout>
</template>

<script>
import PurchaseLayout from "@/layout/PurchaseLayout";
import PrimaryBtn from "../../components/buttons/PrimaryBtn";
import { mapState } from "vuex";
import { formatPEN } from "@/helpers/moneyHelpers";
export default {
  components: {
    PrimaryBtn,
    PurchaseLayout
  },
  props: {
    uuid: {
      type: String,
      default: ""
    }
  },
  data: () => ({}),
  computed: {
    giftCardCode() {
      return this.selected_order.gift_card_coupon.coupons[0].code;
    },
    order_item() {
      return this.selected_order.items[0];
    },
    total() {
      return formatPEN(this.order_item.amount);
    },
    ...mapState({
      selected_order: state => state.giftCards.orders.selected.data,
      loading_order: state => state.giftCards.orders.selected.is_loading
    }),
    code() {
      return this.selected_order?.code;
    }
  },
  mounted() {
    this.$store.dispatch("giftCards/getGiftCardOrder", { uuid: this.uuid });
  }
};
</script>

<style></style>
