import { getFabrics, getCurrentStyles, saveShirt } from "../api/fabrics";
import { getShirt, storeShirt } from "@/api/shirt.js";
import { indexFavorites, storeFavorites } from "@/api/favorites";
import router from "../router";
// import { createOrder } from '../api/orders';
import { indexSizes, createSizes } from "../api/sizes";
import { docTypes } from "../api/constants";
export const actions = {
  async initApp({ dispatch }, payload) {
    await dispatch("setInterceptors");
  },
  async initStore({ dispatch }, payload) {
    // await dispatch("setInterceptors");
  },
  setInterceptors({ dispatch }, payload) {
    window.NUCLIUZ.interceptors.response.use(
      response => {
        // NProgress.done();
        // Promise.resolve
        return response;
      },
      error => {
        // NProgress.done();
        if (error.response.status == 401) {
          dispatch("logout");
        }
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }, payload) {
    commit("logoutComplete");
    commit("sizes/step_selected", "");
    router.push("/");
  },
  initConstants(context, body) {
    return docTypes()
      .then(res => {
        context.commit("constants", res.data);
      })
      .catch(err => {
        window.errorsValidate(err);
      });
  },
  initUser(context, body) {
    context.commit("authenticateCold", body);
    return window.NUCLIUZ.get("/api/auth/user")
      .then(res => {
        context.dispatch("login", res.data);
      })
      .catch(err => {
        console.log(window.errorsValidate(err));
      });
  },
  login(context, body) {
    context.commit("loginSuccess", body);

    context.dispatch("orders/getOrders");
    context.dispatch("checkout/setLatestSize");
    context.commit("setCheckoutShippingFields");
  },
  purchaseCheck(context, body) {
    if (!context.getters.purchaseAction) {
      return;
    }
    if (
      context.getters.currentUser.user_details.sastre_once &&
      context.getters.sizeMethod === "meet_with_sastre"
    ) {
      return context.commit("alerts/alertModal", true);
    }
    return context.dispatch("saveAndPay");
  },
  getFabrics(context, body) {
    // Default shirt sellection
    const fabric_id = body ? body.value : 12;
    if (body) {
      context.commit("setTela", body);
    }

    console.log("loading getFabrics");
    context.commit("toggleLoading", true);
    return getFabrics(fabric_id)
      .then(res => {
        const {
          fabrics,
          collar_styles,
          cuff_styles,
          pocket_styles,
          placket_styles,
          buttons,
          initShirt,
          all_cuff_btns,
          all_collar_btns,
          all_pocket_btns
        } = res.data;
        // local storage init
        localStorage.setItem("all_cuff_btns", JSON.stringify(all_cuff_btns));
        localStorage.setItem(
          "all_collar_btns",
          JSON.stringify(all_collar_btns)
        );
        localStorage.setItem(
          "all_pocket_btns",
          JSON.stringify(all_pocket_btns)
        );

        context.commit("setFabrics", fabrics);
        context.commit("setCollarStyles", collar_styles);
        context.commit("setCuffStyles", cuff_styles);
        context.commit("setPocketStyles", pocket_styles);
        context.commit("setPlacketStyles", placket_styles);
        context.commit("setButtons", buttons);

        // context.commit('setTelaNoLoad', initShirt.fabric);
        // context.commit('setButtonNoLoad', initShirt.button);
        // context.commit('setCuelloNoLoad', initShirt.collar);
        // context.commit('setMangaNoLoad', initShirt.cuff);
        // context.commit('setBolsilloNoLoad', initShirt.pocket);
        // context.commit('setPlacketNoLoad', initShirt.placket);

        if (context.state.uuid_shirt_query) {
          context.dispatch("setShirtFromUUID", {
            uuid: context.state.uuid_shirt_query
          });
        } else {
          context.commit("setTelaNoLoad", initShirt.fabric);
          context.commit("setButtonNoLoad", initShirt.button);
          context.commit("setCuelloNoLoad", initShirt.collar);
          context.commit("setMangaNoLoad", initShirt.cuff);
          context.commit("setBolsilloNoLoad", initShirt.pocket);
          context.commit("setPlacketNoLoad", initShirt.placket);
        }

        // return { succes: true };
      })
      .catch(err => console.log(err))
      .finally(() => {
        console.log("Get Fabrics Done");
        // context.commit('setShirtChange', false);

        context.commit("setShirtChange", true);
      });
  },
  changeFabric(context, body) {
    console.log(body);

    const db = {
      fabric_id: body.value,
      collar_style_id: this.state.shirt.cuello.id,
      cuff_style_id: this.state.shirt.manga.id,
      pocket_style_id: this.state.shirt.bolsillo.id,
      button_id: this.state.shirt.boton.id,
      placket_style_id: this.state.shirt.placket.id
    };

    context.commit("toggleLoading", true);
    getCurrentStyles(db)
      .then(res => {
        console.log("Change Fabric Then");
        console.log(res.data);
        const {
          // fabrics,
          collar_styles,
          cuff_styles,
          pocket_styles,
          placket_styles,
          // buttons,
          currentShirt
        } = res.data;

        // context.commit('setFabrics', fabrics);
        context.commit("setCollarStyles", collar_styles);
        context.commit("setCuffStyles", cuff_styles);
        context.commit("setPocketStyles", pocket_styles);
        context.commit("setPlacketStyles", placket_styles);
        // context.commit('setButtons', buttons);

        // context.commit('setTela', body);
        context.commit("setTelaNoLoad", currentShirt.fabric);
        context.commit("setButtonNoLoad", currentShirt.button);
        context.commit("setCuelloNoLoad", currentShirt.collar);
        context.commit("setMangaNoLoad", currentShirt.cuff);
        context.commit("setBolsilloNoLoad", currentShirt.pocket);
        context.commit("setPlacketNoLoad", currentShirt.placket);

        // context.commit('setShirtChange', true);
      })
      .catch(err => console.log(err))
      .finally(() => {
        console.log("Change Fabric Done");
        // context.commit('setShirtChange', false);

        context.commit("setShirtChange", true);
      });
  },
  async saveOrFirstShirt(context, { format, canvas_b64 }) {
    // save design data
    const shirt = {
      tela_id: { id: context.state.shirt.tela.id },
      cuello: {
        id: context.state.shirt.cuello.id,
        item_id: context.state.shirt.cuello.item_id
      },
      manga: {
        id: context.state.shirt.manga.id,
        item_id: context.state.shirt.manga.item_id
      },
      bolsillo: {
        id: context.state.shirt.bolsillo.id,
        item_id: context.state.shirt.bolsillo.item_id
      },
      placket: {
        id: context.state.shirt.placket.id,
        item_id: context.state.shirt.placket.item_id
      },
      boton: {
        id: context.state.shirt.boton.id
      }
      // monogram: context.state.shirt.monogram
    };

    const local_b64 = window.localStorage.getItem("shirt");

    const dataURL =
      canvas_b64 == "b64"
        ? local_b64
        : document.querySelector("canvas").toDataURL();
    let form_data = new FormData();
    form_data.append("imgBase64", dataURL);
    for (let key in shirt) {
      form_data.append(key, shirt[key].id);
    }
    // return form_data;
    let struc_shirt = {
      imgBase64: dataURL
    };
    for (let key in shirt) {
      struc_shirt = { ...struc_shirt, ...{ [key]: shirt[key].id } };
    }
    return format == "key_value" ? struc_shirt : form_data;

    // check if in localstorage base_64
    // try {
    //   const { data } = await storeShirt();
    // } catch (error) {}
  },
  async saveDesign(context, body) {
    // const shirt = {
    //   tela_id: { id: context.state.shirt.tela.id },
    //   cuello: {
    //     id: context.state.shirt.cuello.id,
    //     item_id: context.state.shirt.cuello.item_id
    //   },
    //   manga: {
    //     id: context.state.shirt.manga.id,
    //     item_id: context.state.shirt.manga.item_id
    //   },
    //   bolsillo: {
    //     id: context.state.shirt.bolsillo.id,
    //     item_id: context.state.shirt.bolsillo.item_id
    //   },
    //   placket: {
    //     id: context.state.shirt.placket.id,
    //     item_id: context.state.shirt.placket.item_id
    //   },
    //   boton: {
    //     id: context.state.shirt.boton.id
    //   }
    //   // monogram: context.state.shirt.monogram
    // };

    // let form_data = new FormData();
    // const canvas = document.querySelector('canvas');
    // var dataURL = canvas.toDataURL();
    // form_data.append('imgBase64', dataURL);
    // for (let key in shirt) {
    //   form_data.append(key, shirt[key].id);
    // }
    const form_data = await context.dispatch("saveOrFirstShirt", {
      format: "form",
      canvas_b64: "canvas"
    });
    context.commit("loadingSave", true);
    try {
      await storeFavorites(form_data);
      const { data } = await indexFavorites();
      context.commit("loadingSave", false);
      context.commit("setUserFavorites", data.favorites);
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
      window.errorsValidate(error);
    }
  },
  async saveSize(context, payload) {
    const measurement_created = context.state.size.values.measure_self;
    const body = {
      collar: measurement_created.neck_input,
      sleeve_length: measurement_created.arm_length_input,
      shoulder_width: measurement_created.shoulder_input,
      chest: measurement_created.chest_input,
      waist: measurement_created.waist_input,
      hip: measurement_created.hip_input,
      length: measurement_created.length_input,
      arm_width: measurement_created.bicep_input,
      cuff_width: measurement_created.wrist_input,
      id_finish: measurement_created.type_of_fit_input
    };
    return context.dispatch("saveSizeToDB", body);
  },
  async saveSizeStandard(context, payload) {
    if (!context.state.authenticated) {
      context.commit("toggleModalLoginOpen", true);
      return;
    }
    return context.dispatch("saveSizeToDB", payload);
  },
  async saveSizeToDB(context, payload) {
    try {
      await createSizes(payload);
      const { data } = await indexSizes();
      context.commit("setSizesToUser", data.sizes);
      context.commit("sizes/step_selected", "user_sizes");
      context.commit("toggleModalOpen", false);
      context.commit("toggleModalMeasurements", false);
      context.commit("resetSize");
      context.dispatch("checkout/setLatestSize");
    } catch (error) {
      window.errorsValidate(error);
    }
  },
  async checkIfAuthenticated(context, body) {
    console.log(context.state.authenticated);
    if (!context.state.authenticated) {
      // context.commit('purchaseAction', true);
      context.commit("toggleModalLoginOpen", true);
    } else {
      context.dispatch("saveSize");
    }
  },

  async b64_to_store(context, body) {
    const canvas = document.querySelector("canvas");
    const dataURL = canvas.toDataURL();
    context.commit(current_shirt_b64, dataURL);
    return dataURL;
    // form_data.append('imgBase64', dataURL);
  },
  async saveAndPay(context, body) {
    if (!context.state.authenticated) {
      context.commit("purchaseAction", true);
      return context.commit("toggleModalLoginOpen", true);
    }

    context.commit("toBuyLoading", true);
    // TODO save order to localstorage to be able to be refreshable
    const canvas = document.querySelector("canvas");
    const dataURL = canvas.toDataURL();
    console.log(dataURL);
    window.localStorage.setItem("shirt", dataURL);
    router.push("/checkout");
    context.commit("toBuyLoading", false);
    // try {
    //   const data = await context.dispatch('saveDesign');
    //   console.log('save to pay');
    //   console.log(data);
    // } catch (error) {
    //   console.log(error);
    //   return;
    // }

    // context.commit(current_shirt_b64, dataURL);
    // context
    //   .dispatch('b64_to_store')
    //   .then((res) => {
    //     console.log(res);
    //     router.push('/checkout');
    //   })
    //   .catch((err) => {})
    //   .finally(() => {
    //     context.commit('toBuyLoading', false);
    //   });
    // context
    //   .dispatch('saveDesign')
    //   .then((res) => {
    //     console.log('save to pay');
    //     console.log(res);
    //     const data = res;

    //     const order_data = {
    //       id_shirt: data.new_shirt.id,
    //       size_option: context.state.size.method,
    //       size_values: context.state.size.values[context.state.size.method]
    //     };
    //     console.log(data.new_shirt.id);

    //     // context
    //     //   .dispatch('saveDesign')
    //     //   .then((res) => {
    //     //     console.log('save to pay');
    //     //     console.log(res);
    //     //   })
    //     //   .catch((err) => {
    //     //     context.commit('toBuyLoading', false);
    //     //   })
    //     //   .finally(() => {
    //     //     context.commit('toBuyLoading', false);
    //     //   });

    // createOrder(order_data);
    //       .then((res) => {
    //         console.log('link to iframe');
    //         console.log(res);
    //         console.log(`http://www.jdoutfit.com/checkout/?add-to-cart=1336&custom_shirt=${res.data.order_uuid}`);
    //         return (window.location.href = `http://www.jdoutfit.com/checkout/?add-to-cart=1336&custom_shirt=${res.data
    //           .order_uuid}`);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         context.commit('toBuyLoading', false);
    //       })
    //       .finally(() => {
    //         // context.commit('toBuyLoading', false);
    //       });

    //     context.commit('toBuyLoading', false);
    //   })
    //   .catch((err) => {
    //     context.commit('toBuyLoading', false);
    //   })
    //   .finally(() => {
    //     context.commit('toBuyLoading', false);
    //   });
  },
  setShirtFromUUIDAtMount(context) {
    return context.dispatch("setShirtFromUUID");
  },
  setShirtFromUUID(context, { uuid }) {
    // const { uuid } = body;
    console.log(uuid);
    return getShirt(uuid)
      .then(res => {
        const { shirt } = res.data;
        return context.dispatch("setShirtFromFavorite", shirt);
      })
      .catch(err => {
        console.log(err);
      });
  },
  setShirtFromFavorite(context, body) {
    const {
      id_collar_style,
      id_pocket_style,
      id_cuff_style,
      id_fabric,
      id_placket_style,
      id_button
    } = body;
    const db = {
      fabric_id: id_fabric,
      collar_style_id: id_collar_style,
      cuff_style_id: id_cuff_style,
      pocket_style_id: id_pocket_style,
      button_id: id_button,
      placket_style_id: id_placket_style
    };
    console.log("loading setShirtFromFavorite");
    context.commit("toggleLoading", true);
    context.commit("setShirtChange", false);
    getCurrentStyles(db)
      .then(res => {
        console.log("Change Fabric Then");
        console.log(res.data);
        const {
          // fabrics,
          collar_styles,
          cuff_styles,
          pocket_styles,
          placket_styles,
          // buttons,
          currentShirt
        } = res.data;

        // context.commit('setFabrics', fabrics);
        context.commit("setCollarStyles", collar_styles);
        context.commit("setCuffStyles", cuff_styles);
        context.commit("setPocketStyles", pocket_styles);
        context.commit("setPlacketStyles", placket_styles);
        // context.commit('setButtons', buttons);

        // context.commit('setTela', body);
        context.commit("setTelaNoLoad", currentShirt.fabric);
        context.commit("setButtonNoLoad", currentShirt.button);
        context.commit("setCuelloNoLoad", currentShirt.collar);
        context.commit("setMangaNoLoad", currentShirt.cuff);
        context.commit("setBolsilloNoLoad", currentShirt.pocket);
        context.commit("setPlacketNoLoad", currentShirt.placket);

        // context.commit('setShirtChange', true);
      })
      .catch(err => console.log(err))
      .finally(() => {
        console.log("Change Fabric Done");
        // context.commit('setShirtChange', false);

        context.commit("setShirtChange", true);
      });
  }
};
