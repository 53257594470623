<template>
  <aside>
    <div data-title="Secure Checkout" class="dn db-ns tr pt0 pb2 mb2">
      <svg
        width="18"
        height="22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 8h16v12H0V8z" fill="#AAA" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.362 11.655a.5.5 0 0 1-.017.707L6.8 16.691l-2.145-2.043a.5.5 0 0 1 .69-.724L6.8 15.31l3.855-3.672a.5.5 0 0 1 .707.017z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 7V6a3.9998 3.9998 0 0 0-4-4 4 4 0 0 0-4 4v1H2V6a6 6 0 1 1 12 0v1h-2z"
          fill="#AAA"
        />
      </svg>
    </div>
    <div class="sticky-wrap pb4">
      <!---->
      <div
        class="
          tw-bg-gray-50
          ph3
          pv3
          pa3-ns
          nl3
          nr3
          center-ns
          sidebar__header
          is-expanded
        "
      >
        <div
          class="
            header-title
            ma0
            pv1
            tl
            tc-ns
            gray
            flex
            align-baseline
            justify-between
          "
        >
          <h5 class="ma0 pv1 tl tc-ns gray">
            <span>Detalles del Pedido</span>
          </h5>
          <!---->
        </div>
        <div class="line-items --products mt3 pt4 bt b--black-20 is-expanded">
          <div class="products-container">
            <!---->
            <!---->
            <!---->
            <div class="product-group w-100">
              <h6 class="tw-mb-2">
                <span>Camisa</span>
              </h6>
              <div class="w-100">
                <div
                  class="
                    tw-flex tw-items-center tw-flex-wrap
                    sm:tw-flex-nowrap
                    mb3
                  "
                >
                  <span class="tw-flex pr3 tw-w-full sm:tw-w-1/3">
                    <a
                      href="#"
                      aria-label="Go to The Everyday Mask v1.3 - Light Grey Melange (Single Mask)"
                      class="
                        tw-shadow
                        tw-py-2
                        tw-px-1
                        tw-h-full
                        tw-w-full
                        tw-bg-white
                      "
                    >
                      <!-- src="http://localhost:8000/imgs/custom-shirts/0abd2338-5cbb-4bc2-9b3a-4351ac8e5eee.png" -->
                      <img :src="current_shirt" alt class="tw-w-full" />
                    </a>
                  </span>
                  <div class="flex-auto flex">
                    <div class="shirt-details flex-auto mt1 mb1 f7 pr3">
                      <div>
                        <div>Tela</div>
                        <div>
                          <span>
                            <router-link
                              to="/first"
                              class="tag"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="Editar"
                            >
                              <span>{{ shirt_fabric }}</span>
                            </router-link>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div>Estilo</div>
                        <div>
                          <span>
                            <router-link
                              to="/second/cuello"
                              href="#"
                              class="tag"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="Editar"
                            >
                              <span>{{ shirt_collar }}</span>
                            </router-link>

                            <router-link
                              to="/second/puno"
                              href="#"
                              class="tag"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="Editar"
                            >
                              <span>{{ shirt_cuff }}</span>
                            </router-link>

                            <router-link
                              to="/second/bolsillo"
                              href="#"
                              class="tag"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="Editar"
                            >
                              <span>{{ shirt_pocket }}</span>
                            </router-link>

                            <router-link
                              to="/second/placket"
                              href="#"
                              class="tag"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="Editar"
                            >
                              <span>{{ shirt_placket }}</span>
                            </router-link>

                            <router-link
                              to="/second/botones"
                              href="#"
                              class="tag"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="Editar"
                            >
                              <span>{{ shirt_btn }}</span>
                            </router-link>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div>Monogram</div>
                        <div>
                          <span>
                            <router-link
                              to="/second/monogram"
                              class="tag"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="Editar"
                            >
                              <span>{{
                                monogram ? "Con monograma" : "Sin monograma"
                              }}</span>
                              <span class="add-on tw-hidden">+$0</span>
                            </router-link>
                          </span>
                        </div>
                      </div>

                      <div>
                        <div>Talla</div>
                        <div>
                          <span>
                            <router-link
                              to="/third"
                              class="tag"
                              data-go-to="size"
                              data-toggle="tooltip"
                              data-placement="top"
                              title
                              data-original-title="Editar"
                            >
                              {{
                                sizeName == "" ? "Por favor elegir" : sizeName
                              }}
                            </router-link>
                            <span v-show="sastre_second_time" class="add-on">
                              +S./ 30
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- <p class="price tr flex-none mt1 f7 pl2" style="min-width: 50px;">
                      $25.00
                    </p>-->
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <!---->
          </div>
        </div>
      </div>
      <h5 class="mb4 tc dn-ns mt4">Order Summary</h5>
      <div
        class="
          tw-bg-gray-50
          pa3 pa3-ns
          mb0
          nl3
          nr3
          center-ns
          sidebar__lineitems
        "
      >
        <div class="w-100 pr2 pr0-ns">
          <!---->
          <div id="order-line-charges" class="line-items">
            <div
              v-for="line_item in items"
              :key="line_item.key"
              class="line-item flex"
            >
              <p class="flex-auto truncate">{{ line_item.label }}</p>
              <p class="price cart_subtotal_row">{{ line_item.formatPrice }}</p>
            </div>
            <!-- tw-my-2 tw-bg-gray-200 -->
            <div class="b--black-20 bt tw-my-2"></div>
            <div class="line-item flex">
              <p class="flex-auto truncate">Subtotal</p>
              <p class="price cart_subtotal_row">{{ subtotal }}</p>
            </div>
            <div v-show="sastre_second_time" class="line-item flex">
              <p class="flex-auto truncate">Segunda visita del Sastre</p>
              <p class="price">S/ 30.00</p>
            </div>
            <div class="line-item flex">
              <p class="flex-auto truncate">Envio</p>
              <p class="price">{{ shippingPrice }}</p>
            </div>

            <div v-if="coupon.discount" class="line-item flex">
              <p class="flex-auto truncate">Coupon</p>
              <p class="price">{{ formatDiscount }}</p>
            </div>
            <div class="line-item flex">
              <p class="flex-auto truncate">
                <strong>Total</strong>
              </p>
              <p class="price">
                <strong>
                  {{ grandTotal }}
                </strong>
              </p>
            </div>
          </div>
          <div class="line-item flex items-baseline vat mt1">
            <p class="flex-auto small tr"></p>
          </div>
        </div>
        <!---->
      </div>
      <!---->
      <div>
        <form @submit.prevent="applyDiscount">
          <div class="tw-flex tw-space-x-4 tw-mt-1 tw-items-end">
            <PrimaryBtn
              v-if="coupon.applied"
              type="button"
              :handleClick="removeCoupon"
            >
              Cambiar
            </PrimaryBtn>
            <InputForm
              v-model="code"
              type="text"
              label="Cupon"
              :error="coupon.error"
              :disabled="coupon.applied"
              placeholder="JD123"
            />
            <div class="tw-block">
              <PrimaryBtn
                v-if="!coupon.applied"
                type="submit"
                :loading="coupon.is_loading"
              >
                Aplicar
              </PrimaryBtn>
            </div>
          </div>
        </form>
      </div>
      <div
        class="
          free-ship
          bg-black-05
          br3
          br--bottom
          pa3
          tc
          mt0
          nl1
          nr1
          center-ns
          tw-hidden
        "
      >
        <p class="mb0">Free shipping on orders over $200.00</p>
        <!---->
      </div>
      <!---->
      <div class="pa3 mt2 gift-code tw-hidden">
        <p class="ma0 flex justify-center f7 expand-toggle tc">
          <span>Apply Gift Code</span>
          <span class="icon"></span>
        </p>
        <div class="mt2 gift-code-wrap">
          <div class="flex">
            <input
              type="text"
              placeholder=""
              class="gift-code-input flex-auto br--left"
            />
            <button class="gift-code-btn pa3 br--right" style="width: auto;">
              →
            </button>
          </div>
          <!---->
        </div>
        <!---->
      </div>
      <!---->
    </div>
  </aside>
</template>

<script>
import { formatPEN } from "@/helpers/moneyHelpers";
import { mapGetters, mapState } from "vuex";
import PrimaryBtn from "../buttons/PrimaryBtn.vue";
import InputForm from "../forms/InputForm.vue";
export default {
  components: { PrimaryBtn, InputForm },
  props: {
    is_current: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapState("checkout", {
      coupon: state => state.coupon
    }),
    code: {
      get() {
        return this.coupon.code;
      },
      set(code) {
        this.$store.commit("checkout/setCoupon", { code });
      }
    },
    ...mapGetters({
      order: ["orders/selected"],
      total: ["checkout/total"],
      items: ["checkout/items"],
      subtotal: ["checkout/subtotal"],
      rawTotal: ["checkout/rawTotal"],
      shippingPrice: ["constants/shippingPriceFormat"]
    }),
    sastre_second_time() {
      return this.sizeMethod === this.setDateValue && this.second_purchase;
    },
    sizeMethod() {
      return this.$store.getters.sizeMethod;
    },
    setDateValue() {
      return this.$store.getters.sizeSetDate.value;
    },
    second_purchase() {
      return this.$store.getters.currentUser.user_details?.sastre_once;
    },
    current_shirt() {
      if (this.is_current) {
        return window.localStorage.getItem("shirt");
      } else {
        return this.order ? this.order.items[0].shirt.shirt_img : "";
      }
    },
    sizeCheck() {
      return this.$store.getters.validateSize;
    },
    loadingFire() {
      return this.$store.getters.toBuyLoading;
    },
    shirt_fabric() {
      const shirt = this.$store.getters.getShirt;
      return shirt.tela.name;
    },
    shirt_collar() {
      const shirt = this.$store.getters.getShirt;
      return shirt.cuello.name;
    },
    shirt_cuff() {
      const shirt = this.$store.getters.getShirt;
      return shirt.manga.name;
    },
    shirt_pocket() {
      const shirt = this.$store.getters.getShirt;
      return shirt.bolsillo.name;
    },
    shirt_placket() {
      const shirt = this.$store.getters.getShirt;
      return shirt.placket.name;
    },
    shirt_btn() {
      const shirt = this.$store.getters.getShirt;
      return shirt.boton.name;
    },
    sizeName() {
      return this.$store.getters.sizeName;
    },
    monogram() {
      console.log(this.$store.getters.monogram);
      return this.$store.getters.monogram;
    },
    setDate() {
      return this.$store.getters.sizeSetDate.value;
    },
    grandTotal() {
      return formatPEN(this.rawTotal - this.coupon.discount);
    },
    formatDiscount() {
      return formatPEN(this.coupon.discount);
    }
  },
  beforeDestroy() {
    this.$store.commit("checkout/setCoupon", {
      code: "",
      discount: 0,
      is_loading: false,
      applied: false
    });
  },
  methods: {
    removeCoupon() {
      this.$store.commit("checkout/setCoupon", { applied: false });
    },
    applyDiscount() {
      if (!this.code) {
        return;
      }

      this.$store.dispatch("checkout/applyOrder", { code: this.code });
    }
  }
};
</script>

<style></style>
