<template>
  <section
    id="step-monogram"
    class="style-single options"
    :class="{ 'is-active': isTrue }"
  >
    <div class="inner">
      <header>
        <BackBtn />
        <h5>Monograma</h5>
      </header>

      <div class="flex flex-wrap mb3 checkbox-wrap">
        <label class="w-100 mb2 pa3 checkbox-toggle">
          <input v-model="monogram" class type="checkbox" />
          <span>
            <i class="check-indicator" /> Añadir Monograma
            <i class="add-on">+S./ 15.00</i>
          </span>
        </label>
      </div>

      <div class="tc ph3 mt4">
        <!-- <p class="f7">A monogram emphasizes that your shirt was custom made � just for you.</p>
        <p class="f7">Choose from several styles, colors, and positions.</p>-->
      </div>

      <div v-if="monogram" class="monogram-options">
        <!-- style="display: none;" -->
        <div class="tc mb4 mt4 monogram-initials">
          <label for="monogram-init">Ingresa tus Iniciales</label>
          <input
            id="monogram-init"
            v-model="monogram_word"
            class="block"
            type="text"
            maxlength="5"
            placeholder="ABC"
            name="monogram-init"
            autocomplete="off"
          />
        </div>

        <div class="monogram-option__header hidden">
          <p>Estilo</p>
          <!-- <i class="btn">i</i> -->
        </div>
        <div class="flex flex-wrap mb4 hidden">
          <MonogramRadio
            v-for="(style, index) in styles"
            :key="index"
            :value="style.value"
            :label="style.label"
          />
        </div>

        <div class="monogram-option__header">
          <p>Color</p>
        </div>
        <div class="flex flex-wrap mb4">
          <MonogramRadioColor
            v-for="(color, index) in colors"
            :key="index"
            :value="color.value"
            :label="color.label"
            :hex="color.hex"
            :style_bg="color.style"
          />
        </div>

        <div class="monogram-option__header">
          <p>Posición</p>
        </div>
        <div class="flex flex-wrap mb2">
          <MonogramRadioPosition
            v-for="(position, index) in positions"
            :key="index"
            :value="position.value"
            :label="position.label"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BackBtn from "../../../components/BackBtn";
import MonogramRadio from "../../../components/steps/two/MonogramRadio";
import MonogramRadioColor from "../../../components/steps/two/MonogramRadioColor";
import MonogramRadioPosition from "../../../components/steps/two/MonogramRadioPosition";
export default {
  components: {
    BackBtn,
    MonogramRadio,
    MonogramRadioColor,
    MonogramRadioPosition
  },
  data() {
    return {
      isTrue: false
    };
  },
  computed: {
    styles() {
      return this.$store.getters.monogramData.styles;
    },
    colors() {
      return this.$store.getters.monogramData.colors;
    },
    positions() {
      return this.$store.getters.monogramData.positions;
    },
    monogram: {
      get: function() {
        return this.$store.getters.monogram;
      },
      set: function(value) {
        const data = {
          value: value
          // name: this.name,
          // item_id: this.item_id,
          // img_url: this.img_shirt
        };
        this.$store.commit("setMonogram", data);
      }
    },
    monogram_word: {
      get: function() {
        return this.$store.getters.monogramParams.word;
      },
      set: function(value) {
        const data = {
          target: "word",
          value: value
        };
        this.$store.commit("setMonogramParam", data);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.isTrue = true;
    }, 100);
  }
};
</script>

<style></style>
