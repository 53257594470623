<template>
  <PurchaseLayout title="Muchas Gracias" :order="code">
    <template #left>
      <CheckoutDetails :is_current="false" />
    </template>
    <template #right>
      <div class="w-100 flex-auto">
        <!---->
        <div class="view--checkout">
          <div class="steps">
            <transition
              enter-active-class="tw-ease-out tw-duration-150"
              enter-class="tw-opacity-0"
              enter-to-class="tw-opacity-100"
              leave-active-class="tw-ease-in tw-duration-100"
              leave-class="tw-opacity-100"
              leave-to-class="tw-opacity-0"
              mode="out-in"
            >
              <router-view :key="$route.fullPath" />
            </transition>
          </div>
        </div>
      </div>
    </template>
  </PurchaseLayout>
</template>

<script>
import PurchaseLayout from "@/layout/PurchaseLayout";
import CheckoutDetails from "@/components/static/CheckoutDetails";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    PurchaseLayout,
    CheckoutDetails,
  },
  computed: {
    ...mapGetters({
      order: ["orders/selected"],
    }),
    code() {
      return this.order ? this.order.code : "";
    },
    ...mapState({
      uuid: (state) => state.checkout.order.order_uuid,
    }),
  },
};
</script>

