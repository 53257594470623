<template>
  <div class="tw-shadow">
    <button
      class="back tw-border-solid tw-border tw-bg-black focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300 focus:tw-border-gray-600 tw-transition tw-ease-in-out tw-duration-150 hover:tw-opacity-75"
      @click="back"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="tw-h-4 tw-w-4 tw-text-gray-50"
      >
        <path
          fill-rule="evenodd"
          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <!-- &#8592; -->
    </button>
  </div>
  <!-- <PrimaryBtn type="button" :handleClick="() => back">
    <ArrowLeft x-classes=" tw-text-gray-600" />
  </PrimaryBtn> -->
</template>

<script>
// import PrimaryBtn from "@/components/buttons/PrimaryBtn";
// import ArrowLeft from "@/components/icons/ArrowLeft";
export default {
  components: {
    // ArrowLeft,
    // PrimaryBtn,
  },
  methods: {
    back() {
      return this.$router.push({ path: "/second" });
    },
  },
};
</script>

<style>
</style>