<template>
  <section class="overview is-active" style>
    <div>
      <div class="flex flex-wrap flex-nowrap-ns justify-between">
        <h5 class="flex-auto mb3 mb4-ns w-100 w-auto-ns">Estilos</h5>
        <!-- <div class="style-presets mb3">
          <a id="style-preset-reset" class="reset" href="#" style>Reset</a>
          <div class="style-preset-select-wrap">
            <select id="style-preset">
              <option value="0" disabled="true" style-preset="1">Style Preset - None</option>
              <option
                disabled
              >&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;&#9472;</option>
              <option key="0" value="2" style-preset="1">
                Business - Style Preset - Not Available with Selected
                Fabric
              </option>
              <option key="1" value="1" style-preset="1">Casual - Style Preset</option>
              <option key="2" value="3" style-preset="1">
                Formal - Style Preset - Not Available with Selected
                Fabric
              </option>
              <option key="3" value="4" style-preset="1">Western - Style Preset</option>
              <option key="4" value="5" style-preset="1">
                Tuxedo - Style Preset - Not Available with Selected
                Fabric
              </option>
            </select>
          </div>
          <div id="style-fast-block" class="save-btns">
            <div class="btn-group">
              <button type="button" class="btn btn-primary btn-sm btn-same-name">Save Style</button>
              <button
                type="button"
                class="btn btn-primary btn-sm dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style
              >
                <span class="caret" />
              </button>
              <ul class="dropdown-menu">
                <li class="btn-new-name">
                  <a href="#">Save Style As</a>
                </li>
              </ul>
            </div>
          </div>
        </div>-->
      </div>

      <div class="steps">
        <router-link to="/second/cuello">
          <div class="hover-active-summary">
            <span>Cuello</span>
            <span>{{ shirt_collar }}</span>
          </div>
        </router-link>
        <router-link to="/second/puno">
          <div class="hover-active-summary">
            <span>Puño</span>
            <span>{{ shirt_cuff }}</span>
          </div>
        </router-link>

        <router-link to="/second/bolsillo">
          <div class="hover-active-summary">
            <span>Bolsillo</span>
            <span>{{ shirt_pocket }}</span>
          </div>
        </router-link>

        <router-link to="/second/placket">
          <div class="hover-active-summary">
            <span>Pechera</span>
            <span>{{ shirt_placket }}</span>
          </div>
        </router-link>

        <router-link to="/second/botones">
          <div class="hover-active-summary">
            <span>Botones</span>
            <span>{{ shirt_btn }}</span>
          </div>
        </router-link>

        <router-link to="/second/monogram">
          <div class="hover-active-summary">
            <span>Monogram</span>
            <span>None</span>
          </div>
        </router-link>
      </div>
      <div class="tw-w-full tw-flex tw-justify-end">
        <span class="unit-footnote tw-underline tw-flex">
          <svg
            class="tw-w-6 tw-h-6 tw-mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span> Haga click en cualquier de los estilos para modificarlo </span>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    shirt_collar() {
      const shirt = this.$store.getters.getShirt;
      return shirt.cuello.name;
    },
    shirt_cuff() {
      const shirt = this.$store.getters.getShirt;
      return shirt.manga.name;
    },
    shirt_pocket() {
      const shirt = this.$store.getters.getShirt;
      return shirt.bolsillo.name;
    },
    shirt_placket() {
      const shirt = this.$store.getters.getShirt;
      return shirt.placket.name;
    },
    shirt_btn() {
      const shirt = this.$store.getters.getShirt;
      return shirt.boton.name;
    },
  },
};
</script>

<style>
</style>