<template>
  <PurchaseLayout>
    <template v-slot:left>
      <div class="w-100 flex-auto">
        <!---->
        <div class="view--checkout">
          <!-- <h1 class="f4 sans f3-ns mt0 mb3 ml3-m tc tl-ns">Checkout</h1> -->
          <div class="steps">
            <transition
              enter-active-class="tw-ease-out tw-duration-300"
              enter-class="tw-opacity-0"
              enter-to-class="tw-opacity-100"
              leave-active-class="tw-ease-in tw-duration-200"
              leave-class="tw-opacity-100"
              leave-to-class="tw-opacity-0"
              mode="out-in"
            >
              <router-view :key="$route.fullPath" />
              <!-- <router-view /> -->
            </transition>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <CheckoutDetails />
    </template>
  </PurchaseLayout>
</template>

<script>
import PurchaseLayout from "@/layout/PurchaseLayout";
import CheckoutDetails from "@/components/static/CheckoutDetails";
export default {
  components: {
    CheckoutDetails,
    PurchaseLayout,
  },
};
</script>

<style>
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>