<template>
  <div class="size-method">
    <section class="">
      <!-- size-single -->
      <div class="size-options">
        <div>
          <header class="tw-mb-6 tw-flex tw-justify-between tw-items-center">
            <button
              class="tw-overflow-hidden tw-bg-black f7 hand back-to-sizes focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
              style="justify-content: space-between"
            >
              <span
                class="tw-text-white tw-bg-transparent"
                @click.prevent="step_selected = ''"
              >
                &#8592;
              </span>
            </button>
            <h5 class="tw-mb-0 tw-text-center sm:tw-text-base tw-text-sm">
              Medidas
            </h5>
            <a
              href="#"
              class="tip"
              data-toggle="popover"
              data-placement="bottom"
              data-content="Choose from traditional sizes below. You can fine tune your size in the Size Detail panel."
            >
              ?
            </a>
          </header>

          <div class="tw-w-full tw-flex">
            <div class="w-full text-left row options">
              <ul class="tw-p-0 checkbox-wrap tw-space-y-6 tw-mb-6 sm:tw-mb-16">
                <SizeRow
                  v-for="size in userSizes"
                  :key="size.id"
                  :selected_size="selected_size"
                  :val="size.id"
                  name_radio="size_row"
                  :cuello="size.collar"
                  :manga="size.sleeve_length"
                  :hombros="size.shoulder_width"
                  :pecho="size.chest"
                  :cintura="size.waist"
                  :cadera="size.hip"
                  :largo="size.length"
                  :bicep="size.arm_width"
                  :punno="size.cuff_width"
                  :created_at="size.created_at"
                  :finish="size.finish_label"
                  :isSastre="!!size.is_tailored"
                  @selected="selected"
                />
              </ul>
            </div>
          </div>

          <p class="text-center mt2"></p>

          <span class="unit-footnote">
            <!-- All dimensions shown in&nbsp;
            <i>inches</i>-->
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SizeRow from "@/components/modals/SizeRow";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required"
});
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    SizeRow
  },
  data: () => ({
    // sel: "",
  }),
  computed: {
    selected_size: {
      get() {
        return this.$store.state.checkout.size.id;
      },
      set(value) {
        this.$store.commit("checkout/setSelectedSize", value);
      }
    },
    valueMethod() {
      return this.$store.getters.setSize.value;
    },
    nameMethod() {
      return this.$store.getters.setSize.name;
    },
    sizeMethod: {
      get: function() {
        return this.$store.getters.sizeMethod;
      },
      set: function(value) {
        const data = {
          value: value,
          name: this.nameMethod
        };
        this.$store.commit("sizeMethod", data);
      }
    },
    userSizes() {
      return this.$store.getters.currentUser?.user_details?.sizes
        .slice(0)
        .reverse();
    },
    step_selected: {
      get() {
        return this.$store.getters["sizes/step_selected"];
      },
      set(val) {
        this.$store.commit("sizes/step_selected", val);
      }
    }
  },
  mounted() {
    this.sizeMethod = this.valueMethod;
  },
  beforeDestroy() {
    // this.onSubmit();
  },
  methods: {
    selected(payload) {
      // console.log(payload);
      this.selected_size = payload;
    }
  }
};
</script>

<style></style>
