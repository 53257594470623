<template>
  <section id="step-po" class="style-single options" :class="{ 'is-active': isTrue }">
    <div class="inner">
      <header>
        <BackBtn />
        <h5>Puño / Manga</h5>
      </header>

      <!-- <div class="flex flex-wrap sleeve-length segmented-control">
        <label class="w-50 mb3">
          <input class type="radio" name="short_sleeve" value="0" />
          <span>Manga Larga</span>
        </label>
        <label class="w-50 mb3">
          <input class type="radio" name="short_sleeve" value="1" />
          <span>Manga Corta</span>
        </label>
      </div>-->

      <!-- <div class="flex flex-wrap checkbox-wrap">
                  <label class="w-100 mb3 pa3 checkbox-toggle">
                    <input class="" type="checkbox" name="cuffContrast">
                    <span><i class="check-indicator"></i> Add White Contrast Cuff <i class="add-on">+$5</i>

                    </span>
                  </label>
      </div>-->
      <div class="flex flex-wrap option-grid">
        <CuffInput
          v-for="(style,index) in cuff_styles"
          id="check"
          :key="index"
          :value="style.id"
          :img="style.url_img"
          :name="style.name"
          :data-img="style.url_img"
          :img_shirt="style.url_img_body"
          :item_id="style.item_id"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BackBtn from "../../../components/BackBtn";
import CuffInput from "../../../components/steps/two/CuffInput";
export default {
  components: {
    BackBtn,
    CuffInput
  },
  data() {
    return {
      isTrue: false
    };
  },
  computed: {
    cuff_styles() {
      return this.$store.getters.cuffStyles;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isTrue = true;
    }, 100);
  }
};
</script>

<style>
</style>