<template>
  <div id="main-panel">
    <main id="builder">
      <img
        src="https://cdn2.propercloth.com/das4/assets/imgs/placeholder.png"
        class="clip"
      />

      <!-- <span class="nav-toggle" @click="toggleHeader" /> -->
      <div
        class="flex flex-auto w-100-ns flex-wrap flex-nowrap-ns tc builder"
        data-step="2"
      >
        <nav class="flex flex-auto w-100 z-3">
          <div class="step-nav">
            <router-link to="/first" data-id="1" class="step-nav-item">
              <span>Tela</span>
            </router-link>
            <router-link to="/second" data-id="2" class="step-nav-item">
              <span>Estilo</span>
            </router-link>
            <router-link to="/third" data-id="3" class="step-nav-item">
              <span>Tallas</span>
            </router-link>
            <router-link to="/summary" data-id="4" class="step-nav-item">
              <span>Resumen</span>
            </router-link>
            <!-- <router-link to="/checkout" data-id="4" class="step-nav-item">
              <span>Checkout</span>
            </router-link>-->
          </div>
        </nav>
        <Canvas />
        <StepBody />
        <FilterWrap />
        <LeftMenu />
      </div>
      <!-- /.modal -->
      <ModalContainer />
      <!-- /.modal -->
    </main>
  </div>
</template>

<script>
import Canvas from "@/components/Canvas";
import FilterWrap from "@/components/FilterWrap";
import ModalContainer from "@/components/ModalContainer";
import LeftMenu from "@/components/steps/two/LeftMenu";
import StepBody from "@/views/StepBody";
export default {
  components: {
    Canvas,
    FilterWrap,
    StepBody,
    LeftMenu,
    ModalContainer,
  },
  computed: {
    boolMobile: {
      get() {
        return this.$store.getters.toggles.navBarMobile;
      },
      set(val) {
        const body = {
          target: "mobile",
          val: val,
        };
        this.$store.commit("toggles", body);
      },
    },
  },
  mounted() {
    if (this.$store.getters.fabrics.length > 0) {
      this.$store.commit("setShirtChange", true);
    }
    this.$store.commit("checkout/setOrderIds", {
      order_uuid: null,
      order_item_id: null,
    });
  },
  methods: {
    toggleHeader() {
      console.log(this.$store.getters.toggles);
      console.log(this.boolMobile);
      return (this.boolMobile = !this.boolMobile);
    },
  },
};
</script>

<style>
</style>