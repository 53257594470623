<template>
  <label class="w-20 mb2">
    <input v-model="monogram_color" class type="radio" name="monogram-color" :value="value" />
    <span :data-hex="hex" :style="style_bg">
      <span :data-name="label" />
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    hex: {
      type: String,
      default: ""
    },
    style_bg: {
      type: String,
      default: ""
    }
  },
  computed: {
    monogram_color: {
      get: function() {
        return this.$store.getters.monogramParams.color;
      },
      set: function(value) {
        const data = {
          target: "color",
          value: value
        };
        this.$store.commit("setMonogramParam", data);
      }
    }
  }
};
</script>

<style>
</style>