window.errorsValidate = (error) => {
  // Error 😨
  let errorMessage = '';
  if (error.response) {
    /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    errorMessage = error.response.status;
    if (error.response.data.errors) {
      for (let item in error.response.data.errors) {
        errorMessage += ` | ${error.response.data.errors[item][0]}`;
        break;
      }
    } else {
      errorMessage += ' | ' + error.response.data.message;
    }
  } else if (error.request) {
    /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
    console.log('request: ', error.request);
    errorMessage = error.message;
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log('message: ', error.message);
    errorMessage = error.message;
  }
  console.log('config: ', error.config);
  return errorMessage;
};
window.deviceName = () => {
  const browser = navigator.userAgent.split(/\s*[;)(]\s*/);
  return `${browser[1]} ${browser[2]} ${browser[3]}`;
};

window.m_d_y = function(item) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'Mayo',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  let dateObj = new Date(item);
  let month = monthNames[dateObj.getMonth()];
  let day = String(dateObj.getDate()).padStart(2, '0');
  let year = dateObj.getFullYear();
  let output = day + '\n' + month + ',' + year;

  return output;
};
