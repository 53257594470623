<template>
  <BaseBtn
    :type="type"
    :disabled="disabled"
    :block="block"
    :loading="loading"
    x-classes="btn-primary tw-border-transparent tw-border-solid tw-border"
    :handleClick="handleClick"
  >
    <slot></slot>
  </BaseBtn>
</template>

<script>
import BaseBtn from "./BaseBtn.vue";
export default {
  components: {
    BaseBtn
  },
  props: {
    type: {
      type: String,
      default: "submit"
    },
    loading: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    handleClick: {
      type: Function,
      default: () => null
    }
  }
};
</script>
