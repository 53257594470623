<template>
  <div>
    <transition name="fade">
      <MeasureBody v-if="modalMeasurement" />
      <!-- /.modal -->
      <MailShirt v-if="modalMailShirt" />
      <SetDate v-if="modalSetDate" />
    </transition>
  </div>
</template>

<script>
import MeasureBody from "./modals/MeasureBody";
import MailShirt from "./modals/MailShirt";
import SetDate from "./modals/SetDate";
export default {
  components: {
    MeasureBody,
    MailShirt,
    SetDate
  },
  computed: {
    modalMailShirt() {
      return this.$store.getters.modalMailShirt;
    },
    modalMeasurement() {
      return this.$store.getters.modalMeasurement;
    },
    modalSetDate() {
      return this.$store.getters.modalSetDate;
    }
  }
};
</script>

<style>
</style>