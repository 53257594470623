<template>
  <div id="mw_register_form">
    <div class="modal-header tw-px-6">
      <button
        type="button"
        class="close"
        aria-label="Close"
        :disabled="loading"
        @click.prevent="toggleLoginModal"
      >
        <span aria-hidden="true">x</span>
      </button>
      <h4 class="modal-title">Registrar</h4>
    </div>
    <div class="modal-body tw-flex tw-flex-col tw-px-6">
      <form
        class="form-horizontal form-neu tw-w-full tw-mb-4 mt0"
        style="margin-top: 0"
        role="form"
        @submit.prevent="register"
      >
        <div class="tw-w-full sm:tw-w-2/3 tw-mb-4">
          <FloatInputForm
            id="full_name"
            v-model="form_inputs.name"
            label="Nombre Completo"
            placeholder="Nombres Apellidos"
          />
        </div>

        <div
          class="tw-flex tw-flex-wrap md:tw-flex-nowrap md:tw-space-x-4 tw-space-y-4 md:tw-space-y-0 tw-w-full tw-mb-4"
        >
          <div
            class="tw-w-full tw-grid tw-grid-cols-1 sm:tw-grid-cols-12 md:tw-grid-cols-2 tw-gap-4"
          >
            <div
              class="tw-w-full tw-col-span-1 sm:tw-col-span-5 md:tw-col-span-1"
            >
              <FloatSelectForm
                id="doc_type"
                v-model="form_inputs.doc_type"
                :options="doc_types"
                label="Tipo de Documento"
              />
            </div>
            <div
              class="tw-w-full tw-col-span-1 sm:tw-col-span-7 md:tw-col-span-1"
            >
              <FloatInputForm
                id="doc_num"
                v-model="form_inputs.doc_num"
                label="Numero de Documento"
                placeholder=""
              />
            </div>
          </div>
          <div class="tw-w-full">
            <FloatInputForm
              id="phone"
              v-model="form_inputs.phone"
              label="Teléfono"
              type="tel"
              placeholder="Teléfono"
            />
          </div>
        </div>

        <div class="tw-w-full tw-mb-4">
          <FloatInputForm
            id="email"
            v-model="form_inputs.email"
            label="Email"
            type="email"
            placeholder="nombre@dominio.com"
          />
        </div>
        <div
          class="tw-flex sm:tw-flex-nowrap sm:tw-space-x-4 sm:tw-space-y-0 tw-space-y-4 tw-flex-wrap"
        >
          <div class="sm:tw-w-1/2 w-full">
            <FloatInputForm
              id="password"
              v-model="form_inputs.password"
              label="Contraseña"
              type="password"
              placeholder=" "
              autocomplete="new-password"
            />
          </div>

          <div class="sm:tw-w-1/2 w-full">
            <FloatInputForm
              id="password_confirm"
              v-model="form_inputs.password_confirmation"
              label="Confirmar Contraseña"
              type="password"
              placeholder=" "
            />
          </div>
        </div>
        <div
          class="tw-w-full tw-flex-col-reverse sm:tw-flex-row tw-flex tw-flex-wrap mt3 sm:tw-flex-nowrap sm:tw-space-x-4 tw-mb-8 sm:tw-mb-0 tw-space-y-6 tw-space-y-reverse"
        >
          <div class="tw-w-full sm:tw-w-1/2">
            <ThirdBtn
              type="button"
              :disabled="loading"
              :block="true"
              :handleClick="toggleLogin"
            >
              <ArrowLeft x-classes=" tw--ml-1 tw-mr-2 tw-text-gray-600" />VOLVER
              A INGRESAR
            </ThirdBtn>
          </div>
          <div class="tw-w-full sm:tw-w-1/2">
            <PrimaryBtn type="submit" :block="true" :loading="loading">
              REGISTRAR
            </PrimaryBtn>
          </div>
        </div>
      </form>

      <transition name="fade" mode="out-in">
        <div
          v-show="errorMsg"
          class="message-danger mw_text margin-top-0-imp display-block"
        >
          {{ errorMsg }}
        </div>
      </transition>
      <p />
    </div>
  </div>
</template>

<script>
import FloatSelectForm from "@/components/forms/FloatSelectForm";
// import SelectForm from "@/components/forms/SelectForm";
// import InputForm from "@/components/forms/InputForm";
import FloatInputForm from "@/components/forms/FloatInputForm";
import ArrowLeft from "@/components/icons/ArrowLeft";
// import BtnLoader from "@/components/btnLoader";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import ThirdBtn from "@/components/buttons/ThirdBtn";
import { register } from "@/api/auth";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  components: {
    // BtnLoader,
    FloatSelectForm,
    FloatInputForm,
    // InputForm,
    // SelectForm,
    ArrowLeft,
    PrimaryBtn,
    ThirdBtn,
  },
  data() {
    return {
      form_inputs: {
        name: "",
        doc_num: "",
        doc_type: "dni",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      errorMsg: "",
    };
  },
  computed: {
    ...mapState({
      doc_types: (state) =>
        state.constants.doc_types.map((item) => {
          const new_item = {
            text: item.label,
            value: item.name,
          };
          return new_item;
        }),
    }),
    ...mapGetters({
      loading: ["auth/isAuthLoading"],
      purchaseAction: "purchaseAction",
    }),
  },
  methods: {
    toggleLoginModal() {
      this.$store.commit("toggleModalLoginOpen", false);
    },
    toggleLogin() {
      return this.$store.commit("auth/toggleForm", "login");
    },
    ...mapMutations({
      // toggleRegister
      toggleLoginFuck: "auth/toggleForm",
    }),
    register() {
      this.$store.commit("auth/setAuthLoading", true);
      const body = {
        // name: this.name,
        // email: this.email,
        // password: this.password,
        // password_confirmation: this.password_confirmation,
        ...this.form_inputs,
        device_name: window.deviceName(),
      };
      this.errorMsg = "";
      return register(body)
        .then((res) => {
          this.$store.dispatch("login", res);
          // this.$store.commit("loginSuccess", res);
          this.$store.dispatch("notifications/addNotification", {
            title: "Inicio sesión correctamente",
            body: "",
          });
          this.toggleLoginModal();
          this.$store.dispatch("purchaseCheck");
        })
        .catch((err) => {
          this.errorMsg = err;
        })
        .finally(() => {
          this.$store.commit("auth/setAuthLoading", false);
        });
    },
  },
};
</script>

<style>
</style>