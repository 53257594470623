var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:"shipping_checkout",staticClass:"step pa3 pa4-ns bg-white-40 mb3 nl3 nr3 mh0-ns bg-white is-active"},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.confirmShipping($event)}}},[_c('div',{staticClass:"step--header flex justify-between"},[_c('h5',{staticClass:"mb0"},[_vm._v("Envio")])]),_c('div',{staticClass:"step--inner pt4"},[_c('div',{staticClass:"flex flex-wrap w-100 mb3"},[_c('div',{staticClass:"tw-w-full tw-mb-4 sm:tw-w-2/3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputForm',{attrs:{"id":"shipping_name","error":!!errors[0],"label":"Nombre Completo","placeholder":"Nombres Apellidos"},model:{value:(_vm.full_name),callback:function ($$v) {_vm.full_name=$$v},expression:"full_name"}})]}}])})],1),_c('div',{staticClass:"tw-w-full tw-mb-4 sm:tw-w-2/3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputForm',{attrs:{"id":"shipping_phone","type":"tel","error":!!errors[0],"label":"Teléfono","placeholder":" "},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])})],1),_c('div',{staticClass:"tw-w-full tw-mb-4 md:tw-w-1/3"}),_c('div',{staticClass:"form-group w-100 md:tw-w-1/3 md:tw-pr-2"},[_c('SelectForm',{attrs:{"id":"shipping_doc_type","options":_vm.doc_types,"label":"Tipo de Documento"},model:{value:(_vm.doc_type),callback:function ($$v) {_vm.doc_type=$$v},expression:"doc_type"}})],1),_c('div',{staticClass:"form-group w-100 md:tw-w-2/3 md:tw-pl-2"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputForm',{attrs:{"id":"shipping_doc_num","error":!!errors[0],"label":"Numero de Documento","placeholder":" "},model:{value:(_vm.doc_num),callback:function ($$v) {_vm.doc_num=$$v},expression:"doc_num"}})]}}])})],1),_c('div',{staticClass:"form-group w-100 md:tw-w-1/3 md:tw-pr-2"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectForm',{attrs:{"id":"district","options":_vm.districts,"error":!!errors[0],"label":"Distrito"},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}})]}}])})],1),_c('div',{staticClass:"form-group w-100 md:tw-w-2/3 md:tw-pl-2"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputForm',{attrs:{"id":"shipping_street_address1","error":!!errors[0],"label":"Dirección","placeholder":" "},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}])})],1),_c('div',{staticClass:"form-group flex-auto w-100 mb3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputForm',{attrs:{"id":"shipping_reference","error":!!errors[0],"label":"Referencia","placeholder":" "},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})]}}])})],1),_c('div',{staticClass:"form-group flex-auto w-100 mb3"},[_c('validation-provider',{attrs:{"rules":"min:0|max:180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextareaForm',{attrs:{"id":"shipping_reference","label":"Detalles Adicionales","placeholder":" ","error":!!errors[0]},model:{value:(_vm.additionals),callback:function ($$v) {_vm.additionals=$$v},expression:"additionals"}})]}}])})],1)]),_c('div',{staticClass:"\n            tw-flex\n            tw-flex-col-reverse\n            tw-w-full\n            tw-space-y-4\n            tw-space-y-reverse\n            mt3\n            lg:tw-flex-row lg:tw-space-y-0 lg:tw-space-x-4\n          "},[_c('div',{staticClass:"tw-block lg:tw-inline-flex"},[_c('ThirdBtn',{attrs:{"type":"button","handleClick":function () { return _vm.$router.push('/summary'); }}},[_c('ArrowLeft',{attrs:{"x-classes":" tw--ml-1 tw-mr-2 tw-text-gray-600"}}),_vm._v("VOLVER ")],1)],1),_c('PrimaryBtn',{attrs:{"type":"submit","disabled":_vm.submit_event_disabled}},[_vm._v(" Continuar "),_c('ArrowRight',{attrs:{"x-classes":"tw--mr-1 tw-ml-2 tw-text-gray-300"}})],1)],1),(false)?_c('div',{staticClass:"tw-flex tw-w-full tw-mb-4 md:tw-hidden"},[_c('div',{staticClass:"tw-inline-flex"},[_c('BorderLessBtn',{attrs:{"type":"button","handleClick":function () { return _vm.$router.push('/summary'); }}},[_c('ArrowLeft',{attrs:{"x-classes":" tw--ml-1 tw-mr-2 tw-text-gray-600"}}),_vm._v("VOLVER ")],1)],1)]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }