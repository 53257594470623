<template>
  <div id="step-2" data-step="style" style>
    <div class="style-steps--mob">
      <div>
        <ul class="style-steps inset z-1 is-visible" style>
          <li class="step-2-nav-item" data-id="step-cuello">
            <router-link to="/second/cuello" data-id="4" class="step-nav-item">
              <span>Cuello</span>
            </router-link>
          </li>
          <li class="step-2-nav-item" data-id="step-po">
            <router-link to="/second/puno" data-id="4" class="step-nav-item">
              <span>Puño</span>
            </router-link>
          </li>
          <li class="step-2-nav-item" data-id="step-bolsillo">
            <router-link to="/second/bolsillo" data-id="4" class="step-nav-item">
              <span>Bolsillo</span>
            </router-link>
          </li>
          <li class="step-2-nav-item" data-id="step-placket">
            <router-link to="/second/placket" data-id="4" class="step-nav-item">
              <span>Pechera</span>
            </router-link>
          </li>
          <li class="step-2-nav-item" data-id="step-botones">
            <router-link to="/second/botones" data-id="4" class="step-nav-item">
              <span>Botones</span>
            </router-link>
          </li>
          <li class="step-2-nav-item" data-id="step-monogram">
            <router-link to="/second/monogram" data-id="4" class="step-nav-item">
              <span>Monogram</span>
            </router-link>
          </li>
          <li class="step-2-nav-item" data-id="step-monogram">
            <router-link to="/second" data-id="4" class="step-nav-item">
              <span>☰ Todos</span>
            </router-link>
          </li>
          <!--           
          <li class="is-current">
            <span>Cuello</span>
          </li>
          <li>
            <span>Puño</span>
          </li>
          <li>
            <span>Bolsillo</span>
          </li>
          <li>
            <span>Placket</span>
          </li>
          <li>
            <span>Botones</span>
          </li>
          <li>
            <span>Monogram</span>
          </li>
          <li class="spacer" />
          <li>
            <span>☰ All</span>
          </li>-->
        </ul>
      </div>
    </div>

    <router-view />

    <div class="selected-panel selected-panel--style is-visible" style="display: none;">
      <div class="flex flex-wrap justify-between items-center">
        <div class="w-100 pr3">
          <h3 class="f7">
            <!-- <a href="#" v-on:click.prevent="openModal('style-detail')" v-if="ui.styleStep == 'collar'">{{ currItemInfo.name }}</a> -->
            <span>Button Down 2</span>
          </h3>
          <p class="f7 mb0">A business appropriate button down collar. Fused for a crisp appearance.</p>
        </div>
        <!-- <div class="w-10 tr">
                      <i class="btn" v-on:click.prevent="openModal('style-detail')">i</i>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>