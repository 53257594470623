<template>
  <div class="summary">
    <div class="sizes__header flex justify-between">
      <h5>Crea tu talla</h5>

      <div class="have-a-size">
        <p class="f7 tw-flex items-center">
          <!-- <span
            v-if="!auth"
            class="btn btn-sm btn-secondary dib ml2"
            @click="toggleLoginModal"
          >
            Ingresa
          </span> -->
          <ThirdBtn type="button" v-if="!auth" :handleClick="toggleLoginModal">
            Ingresa
          </ThirdBtn>
        </p>
      </div>
    </div>
    <div id="new-size-long" class="overlay-container">
      <div class="mt3-ns">
        <div v-if="auth_and_valid" class="bg-white pa3 pa4-ns">
          <h3
            class="f5 f4-ns mb2 hand"
            data-target="#ss-modal"
            data-toggle="modal"
          >
            Tallas de compras pasadas
            <span v-if="sizeMethod === setSize" class="most-pop special_span">
              Seleccionada
            </span>
          </h3>
          <p class="f7 mb3">Las ultimas medidas tomadas</p>
          <div class="flex">
            <!-- <PrimaryBtn
              type="button"
              :handleClick="() => (modalUserSizes = true)"
            > -->
            <PrimaryBtn
              type="button"
              :handleClick="() => (step_selected = 'user_sizes')"
            >
              Ver medidas
            </PrimaryBtn>
          </div>
        </div>
        <div v-else class="bg-white pa3 pa4-ns">
          <h3
            class="f5 f4-ns mb2 hand"
            data-target="#ss-modal"
            data-toggle="modal"
          >
            Cita con nuestro Sastre
            <span class="most-pop">La mas Popular</span>
            <span
              v-if="sizeMethod === sizeSetDate && sizeCheck.date"
              class="most-pop span_error"
            >
              Error
            </span>
            <span
              v-if="sizeMethod === sizeSetDate"
              class="most-pop special_span"
            >
              Seleccionada
            </span>
          </h3>
          <p class="f7 mb3">
            Permítenos coordinar una cita contigo. Nuestro sastre tomará las
            medidas apropiadas en su domicilio, para que su camisa le quede
            perfecto
          </p>
          <div class="flex">
            <PrimaryBtn
              type="button"
              :handleClick="() => (step_selected = 'date')"
            >
              Saca una cita
            </PrimaryBtn>
          </div>
        </div>

        <div class="other-methods pa3 pa4-ns">
          <h6 class="caps light-silver">O</h6>
          <div class="flex flex-wrap">
            <div v-if="auth_and_valid" class="w-100">
              <a
                href="#"
                data-size-method="standard"
                class="slide-trigger"
                @click.prevent="step_selected = 'date'"
              >
                <!-- @click.prevent="toggleSetDate" -->
                <span>Saca una cita con el sastre</span>
                <span
                  v-if="sizeMethod === sizeSetDate"
                  class="most-pop special_span"
                  >Seleccionada</span
                >
                <span>&#8594;</span>
              </a>
            </div>
            <div class="w-100">
              <a
                href="#"
                data-size-method="standard"
                class="slide-trigger"
                @click.prevent="step_selected = 'sizes'"
              >
                <!-- @click.prevent="toggleStandardSizesModal" -->
                <span>Medidas Standard</span>
                <CheckBadge v-if="sizeMethod === sizeStandardSize" />
                <span>&#8594;</span>
              </a>
            </div>
            <div class="w-100">
              <a
                href="#"
                data-target="#body-size-modal"
                data-toggle="modal"
                data-size-method="measure-body"
                @click.prevent="toggleMeasureModal"
              >
                <span>Mide tu cuerpo</span>
                <CheckBadge
                  v-if="sizeMethod === sizeMeasureBody && sizeCheck.tallas"
                  error="true"
                />
                <CheckBadge v-if="sizeMethod === sizeMeasureBody" />
                <span>&#8594;</span>
              </a>
            </div>
            <div class="w-100">
              <a
                href="#"
                class="mail"
                data-target="#mail-shirt-modal"
                data-toggle="modal"
                data-size-method="mail-shirt"
                @click.prevent="step_selected = 'mail'"
              >
                <!-- @click.prevent="toggleMailModal" -->
                <span>Envíanos una camisa para copiar</span>
                <CheckBadge v-if="sizeMethod === sizeMailShirt" />
                <span>&#8594;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt5" />
    </div>
  </div>
</template>

<script>
import ThirdBtn from "@/components/buttons/ThirdBtn";
import CheckBadge from "@/components/markers/CheckBadge";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
export default {
  components: {
    CheckBadge,
    ThirdBtn,
    PrimaryBtn
  },
  computed: {
    setSize() {
      return this.$store.getters.setSize.value;
    },

    second_purchase() {
      // return !this.$store.getters.currentUser.user_details.sastre_once;
      return this.$store.state.currentUser.user_details.sastre_once;
    },
    auth_and_valid() {
      return this.user_sizes.length > 0 && this.auth;
    },
    user_sizes() {
      // return !this.$store.state.currentUser.user_details.sastre_once;

      return Object.keys(this.$store.state.currentUser).length !== 0
        ? this.$store.state.currentUser.user_details.sizes
        : [];
    },
    sizeCheck() {
      return this.$store.getters.validateSize;
    },
    standardSizeVar() {
      return this.$store.getters.getThirdStandardSizeToggle;
    },
    auth() {
      return this.$store.getters.isAuth;
    },
    sizeMethod: {
      get: function() {
        return this.$store.getters.sizeMethod;
      },
      set: function(value) {
        console.log(value);
        // console.log(this.name);
        const data = {
          value: value,
          // name: this.nameMethod,
          name: value
        };
        this.$store.commit("sizeMethod", data);
      }
    },
    sizeSetDate() {
      return this.$store.getters.sizeSetDate.value;
    },
    sizeStandardSize() {
      return this.$store.getters.sizeStandardSize.value;
    },
    sizeMeasureBody() {
      return this.$store.getters.sizeMeasureBody.value;
    },
    sizeMailShirt() {
      return this.$store.getters.sizeMailShirt.value;
    },
    modalUserSizes: {
      get() {
        return this.$store.getters["alerts/modalUserSizes"];
      },
      set(val) {
        this.$store.commit("alerts/modalUserSizes", val);
      }
    },
    step_selected: {
      get() {
        return this.$store.getters["sizes/step_selected"];
      },
      set(val) {
        this.$store.commit("sizes/step_selected", val);
      }
    }
  },

  mounted() {
    this.sizeMethod = "";
  },
  methods: {
    toggleStandardSizesModal() {
      this.$store.commit("setThirdStandardSizeToggle", true);
    },
    toggleMailModal() {
      this.$store.commit("toggleModalOpen", true);
      this.$store.commit("setModalMailShirt", true);
    },
    toggleMeasureModal() {
      this.$store.commit("toggleModalOpen", true);
      this.$store.commit("toggleModalMeasurements", true);
    },
    toggleSetDate() {
      this.$store.commit("toggleModalOpen", true);
      this.$store.commit("setModalSetDate", true);
    },
    toggleLoginModal() {
      this.$store.commit("toggleModalLoginOpen", true);
    }
  }
};
</script>

<style></style>
