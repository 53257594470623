import { formatPEN } from "@/helpers/moneyHelpers";
export const Constants = {
  namespaced: true,
  state: {
    version: "",
    doc_types: [],
    standard_sizes: [],
    finishes: [],
    shipping: {
      fixed: 0
    }
  },
  mutations: {},
  getters: {
    shippingPrice({ shipping }) {
      return parseFloat(shipping.fixed);
    },
    shippingPriceFormat({ shipping }) {
      return formatPEN(shipping.fixed);
    },
    sizes(state) {
      return state.standard_sizes.map(row => ({
        text: row.size,
        value: parseFloat(row.size)
      }));
    },
    finishes(state) {
      return state.finishes.map(row => ({
        text: row.label,
        value: row.id
      }));
    }
  },

  actions: {}
};
