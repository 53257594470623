<template>
  <li class="checkbox-toggle">
    <label
      class="tw-block hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-bg-gray-50 tw-transition tw-duration-150 tw-ease-in-out tw-w-full tw-cursor-pointer tw-py-0 tw-shadow tw-rounded-sm"
      style="height: auto !important"
    >
      <div class="tw-min-w-0 tw-flex-1 tw-flex tw-items-center">
        <!-- v-model="inputVal" -->
        <input
          type="radio"
          :name="name_radio"
          :value="val"
          :checked="val == selected_size"
          @change="selected"
        />
        <span
          class="tw-w-full tw-flex tw-flex-wrap tw-items-center tw-rounded-sm"
          style="display: flex"
        >
          <i
            class="check-indicator tw-h-5 sm:tw-h-6 tw-w-5 sm:tw-w-6 tw-mr-0 tw-ml-2"
          ></i>
          <div
            class="tw-min-w-0 tw-flex-1 tw-px-4 sm:tw-px-2 tw-grid tw-grid-cols-2 md:tw-grid md:tw-grid-cols-5 md:tw-gap-2"
          >
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ cuello }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-truncate tw-text-primary-600"
              >
                Cuello
              </div>
            </div>
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ manga }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Largo de brazos
              </div>
            </div>
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ hombros }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Hombros
              </div>
            </div>
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ pecho }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Pecho
              </div>
            </div>
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ cintura }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Cintura
              </div>
            </div>
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ cadera }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Caderas
              </div>
            </div>
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ largo }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Largo de camisa
              </div>
            </div>
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ bicep }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Ancho de brazos
              </div>
            </div>
            <div>
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500 justify-center"
              >
                <div class="tw-truncate">{{ punno }}</div>
              </div>
              <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Puño
              </div>
            </div>
            <div
              class="tw-flex tw-justify-center tw-items-center tw-pointer-events-none tw-relative"
            >
              <!-- @click="() => console.log('cog')" -->
              <div
                class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-leading-5 justify-center tw-pointer-events-auto tw-z-50"
              >
                <!-- w-px-2 tw-py-2 tw-rounded-full hover:tw-bg-gray-300 focus:tw-outline-none focus:shadow-outline-gray tw-bg-gray-100 tw-text-gray-500 hover:tw-text-gray-50 duration-150 ease-in-out transition -->

                <div v-click-outside="closeEvent" class="relative">
                  <button
                    :disabled="isSastre"
                    type="button"
                    :class="{
                      'tw-cursor-not-allowed tw-bg-gray-200 ': isSastre,
                      'tw-cursor-pointer tw-bg-gray-100 ': !isSastre,
                    }"
                    class="focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300 focus:tw-border-gray-600 tw-inline-flex tw-items-center tw-justify-center tw-transition tw-ease-in-out tw-duration-150 tw-text-gray-500 tw-border-transparent tw-border-solid tw-border hover:tw-bg-gray-300 focus:tw-bg-gray-300 tw-p-2 tw-rounded-full"
                    @click="isOpen = !isOpen"
                  >
                    <svg
                      class="tw-w-6 tw-h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      ></path>
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      ></path>
                    </svg>
                  </button>
                  <transition
                    enter-active-class="tw-transition tw-ease-out tw-duration-100"
                    enter-class="tw-transform tw-opacity-0 tw-scale-95"
                    enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
                    leave-active-class="tw-transition tw-ease-in tw-duration-75"
                    leave-class="tw-transform tw-opacity-100 tw-scale-100"
                    leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
                  >
                    <div
                      v-show="isOpen"
                      class="tw-z-40 tw-mt-1 tw-absolute tw-w-48 tw-right-0 tw-origin-top-right tw-text-left"
                    >
                      <div
                        class="tw-w-48 tw-bg-white tw-rounded-sm tw-shadow-lg tw-overflow-hidden"
                      >
                        <div
                          class="tw-py-0 tw-divide-y tw-divide-gray-100"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          <button
                            type="button"
                            class="tw-block transition duration-150 tw-ease-in-out tw-px-3 tw-py-1.5 tw-leading-tight hover:tw-bg-gray-300 focus:tw-bg-gray-300 focus:tw-outline-none focus:tw-shadow-outline-blue tw-w-full"
                          >
                            <div
                              class="tw-inline-flex tw-items-center tw-w-full"
                            >
                              <svg
                                class="tw-h-5 tw-w-5 tw-text-gray-400 tw-flex-shrink-0"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                ></path>
                              </svg>

                              <span
                                class="tw-ml-2 tw-text-gray-900 tw-border-0 tw-text-left tw-uppercase tw-font-bold"
                              >Editar
                              </span>
                            </div>
                          </button>
                          <button
                            type="button"
                            class="tw-block transition duration-150 tw-ease-in-out tw-px-3 tw-py-1.5 tw-bg-red-100 tw-leading-tight hover:tw-bg-red-300 focus:tw-bg-red-300 focus:tw-outline-none focus:tw-shadow-outline-blue tw-w-full"
                          >
                            <div
                              class="tw-inline-flex tw-items-center tw-w-full"
                            >
                              <svg
                                class="tw-h-5 tw-w-5 tw-text-red-600 bg-red-100 tw-flex-shrink-0"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"
                                ></path>
                              </svg>
                              <span
                                class="tw-ml-2 tw-text-gray-900 tw-border-0 tw-text-left tw-uppercase tw-font-bold"
                              >Eliminar
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <!-- <div
                class="tw-text-sm tw-leading-5 tw-font-medium tw-text-primary-600 tw-truncate"
              >
                Opciones
              </div> -->
            </div>
          </div>
          <div
            class="tw-flex tw-items-center tw-px-4 tw-pt-4 tw-pb-2 sm:tw-px-6 w-full"
          >
            <div
              class="tw-flex tw-justify-center sm:tw-space-x-8 w-full tw-flex-col sm:tw-flex-row tw-items-center"
            >
              <!-- <div class="tw-flex tw-items-center tw-ml-3">
                  <div
                    class="tw-bg-primary-900 tw-px-2.5 tw-py-2 tw-border tw-border-gray-200 tw-rounded-l-sm tw-inline-flex tw-items-center tw-justify-center tw-text-sm tw-leading-5 hover:tw-bg-gray-100"
                  >
                    Acabado
                  </div>
                  <div
                    class="tw-bg-white tw-px-3 tw-py-2 tw-border tw-border-gray-200 tw-rounded-r-sm tw-inline-flex tw-items-center tw-justify-center tw-text-sm tw-leading-5 hover:tw-text-blue-600 tw-truncate"
                  >
                    {{ finish }}
                  </div>
                </div> -->
              <div
                class="tw-flex tw-border tw-py-0 tw-border-primary-900 tw-mb-2 sm:tw-mb-0 tw-border-solid tw-rounded-sm tw-overflow-hidden"
              >
                <div
                  class="tw-text-xs tw-h-auto tw-flex tw-items-center justify-center tw-leading-5 tw-font-medium tw-text-primary-100 tw-px-3 tw-py-1 tw-bg-primary-900 tw-truncate"
                >
                  <div>Acabado</div>
                </div>
                <div
                  class="tw-flex tw-items-center tw-truncate tw-text-xs tw-leading-5 tw-text-gray-900 tw-px-3 tw-py-1 justify-center tw-bg-primary-100"
                >
                  <div class=" ">
                    {{ finish }}
                  </div>
                </div>
              </div>
              <div class="tw-text-sm tw-leading-5 tw-text-gray-900">
                Creada
                <time datetime="2020-01-07">{{ created_at | m_d_y }}</time>
              </div>
              <div
                v-if="isSastre"
                class="tw-flex tw-items-center tw-text-sm tw-leading-5 tw-text-gray-500"
              >
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  class="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-green-400"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                por sastre
              </div>
            </div>
            <!-- <div class="tw-flex w-full">
            </div> -->
          </div>
        </span>
      </div>
    </label>
  </li>
</template>

<script>
export default {
  filters: {
    m_d_y: function (item) {
      const monthNames = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Dicienbre",
      ];
      let dateObj = new Date(item);
      let month = monthNames[dateObj.getMonth()];
      let day = String(dateObj.getDate()).padStart(2, "0");
      let year = dateObj.getFullYear();
      let output = day + "\n" + month + "," + year;

      return output;
    },
  },
  inheritAttrs: false,
  props: {
    selected_size: {
      type: Number,
      default: 0,
    },
    val: {
      type: Number,
      default: 0,
    },
    name_radio: {
      type: String,
      default: "",
    },
    cuello: { type: Number, default: 0 },
    manga: { type: Number, default: 0 },
    hombros: { type: Number, default: 0 },
    pecho: { type: Number, default: 0 },
    cintura: { type: Number, default: 0 },
    cadera: { type: Number, default: 0 },
    largo: { type: Number, default: 0 },
    bicep: { type: Number, default: 0 },
    punno: { type: Number, default: 0 },
    isSastre: { type: Boolean, default: false },
    created_at: { type: String, default: "" },
    finish: { type: String, default: "" },
  },
  data: () => ({
    isOpen: false,
    value: "",
  }),
  computed: {
    // inputVal: {
    //   get() {
    //     return this.value;
    //   },
    //   set(val) {
    //     this.$emit("input", val);
    //   },
    // },
  },
  methods: {
    selected(val) {
      // console.log(val);
      this.$emit("selected", this.val);
    },
    closeEvent: function () {
      console.log("close event called");
      this.isOpen = false;
    },
  },
};
</script>

<style>
</style>
