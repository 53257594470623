<template>
  <div class="size-method pb-8">
    <section class="size-single">
      <div class="tw-pr-0 size-options" data-method="standard">
        <div class="tw-p-4 sm:tw-p-0">
          <header class="tw-flex tw-items-center tw-justify-between tw-mb-6">
            <!-- <span
              class="f7 hand back-to-sizes"
              style="justify-content: space-between"
            >
              <span class="" @click.prevent="step_selected = ''">&#8592;</span>
            </span> -->
            <button
              class="tw-overflow-hidden tw-bg-black f7 hand back-to-sizes focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
              style="justify-content: space-between"
              @click.prevent="step_selected = ''"
            >
              <span class="tw-text-white tw-bg-transparent"> &#8592; </span>
            </button>
            <h5 class="tw-mb-0 tw-text-sm tw-text-center sm:tw-text-base">
              Envíanos una camisa para copiar
            </h5>
            <a
              href="#"
              class="tip"
              data-toggle="popover"
              data-placement="bottom"
              data-content="
              Si tienes dudas o consultas no dudes en llamar o escribir al:
              +51 956-444-665
              "
            >
              ?
            </a>
          </header>
          <div class="size--standard">
            <div
              class="tw-grid tw-grid-cols-1 tw-gap-2 sm:tw-grid-cols-3 sm:tw-gap-4"
            >
              <div class="sm:tw-col-span-2">
                <p class="tw-mb-3">
                  ¿Quieres copiar la talla de una camisa que ya tienes, pero no
                  estás seguro de cómo medirla correctamente?
                </p>

                <p class="tw-mb-3">
                  Envíenos su camisa por courier, nuestros expertos la medirán y
                  crearán un tamaño especial.
                </p>

                <p>Enviar a:</p>
                <p class="address">
                  <span class="black">Jermyn & Duke</span>
                  <br />
                  <span class="black">Attn: Para Ignacio Palacios</span>
                  <br />
                  <span class="black">
                    Calle Galeon 322, Dpto 301, San Borja
                  </span>
                  <br />
                  <span class="black">Lima, Peru</span>
                  <br />
                </p>
              </div>
              <div>
                <p>Cuando envíes, asegurate de:</p>
                <div
                  class="tw-block tw-pl-6 tw-mb-8 tw-border-l tw-border-gray-300 tw-border-solid md:tw-pl-8 sm:tw-mb-0"
                >
                  <ol class="tw-py-3 tw-list-decimal tw-list-outside">
                    <!-- <li>
                  <a
                    href="https://propercloth.com/documents/Measure-a-Shirt.pdf"
                    target="_blank"
                  >This Form</a>
                  (Optional, but helpful)
                </li>-->
                    <li>
                      Escríbenos por WhatsApp al
                      <br />+51 956-444-665
                    </li>
                    <li>Coméntanos cuando estará enviando su camisa</li>
                    <!-- <li>Phone number</li>
                <li>Email address</li>
                <li>Return shipping address</li>-->
                    <!-- <li>Cuentenos cualquier detalle adicional</li> -->
                  </ol>
                </div>
              </div>
            </div>

            <p class="tw-text-xs tw-underline sm:tw-text-sm">
              * Tenga en cuenta que nuestra medición no causa daños a la camisa
              y se la devolveremos con su pedido de la camisa.
            </p>
          </div>

          <p class="text-center mt2"></p>

          <span class="unit-footnote">
            <!-- All dimensions shown in&nbsp;
            <i>inches</i>-->
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    valueMethod() {
      return this.$store.getters.sizeMailShirt.value;
    },
    nameMethod() {
      return this.$store.getters.sizeMailShirt.name;
    },
    ...mapGetters({
      standardSize: "getThirdStandardSizeToggle",
      sizeFinishes: "sizeFinishes",
      sizeStandards: "sizeStandards"
    }),
    step_selected: {
      get() {
        return this.$store.getters["sizes/step_selected"];
      },
      set(val) {
        this.$store.commit("sizes/step_selected", val);
      }
    },
    sizeMethod: {
      get: function() {
        return this.$store.getters.sizeMethod;
      },
      set: function(value) {
        const data = {
          value: value,
          name: this.nameMethod
        };
        this.$store.commit("sizeMethod", data);
      }
    },
    standart_type_size_name: {
      get: function() {
        return this.$store.getters.sizeValues[this.valueMethod]
          .standart_type_size_name;
      },
      set: function(value) {
        const data = {
          parent: this.valueMethod,
          target: "standart_type_size_name",
          value: value
        };
        this.$store.commit("sizeValues", data);
      }
    },
    standart_type_of_fit_form: {
      get: function() {
        return this.$store.getters.sizeValues[this.valueMethod]
          .standart_type_of_fit_form;
      },
      set: function(value) {
        const data = {
          parent: this.valueMethod,
          target: "standart_type_of_fit_form",
          value: value
        };
        this.$store.commit("sizeValues", data);
      }
    }
  },
  mounted() {
    this.sizeMethod = this.valueMethod;
  },
  methods: {
    toggleStandardSize() {
      this.$store.commit("setThirdStandardSizeToggle", false);
    }
  }
};
</script>

<style></style>
