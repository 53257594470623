var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.label)?_c('label',{attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"tw-relative tw-shadow-sm"},[((_vm.type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"focus:tw-bg-white tw-bg-white tw-border hover:tw-border-gray-600 hover:tw-bg-secondary-100 focus:tw-border-gray-600 tw-transition tw-duration-150 tw-ease-in-out focus:tw-ring focus:tw-ring-gray-300 focus:tw-outline-none tw-border-solid tw-shadow text-left tw-pt-4 tw-py-3 tw-px-3 tw-leading-5 tw-font-thin tw-text-base tw-rounded-sm tw-w-full",class:{
        'tw-border-red-500 tw-shadow-outline-red': _vm.error,
        'tw-border-gray-300 ': !_vm.error
      },attrs:{"id":_vm.id,"name":_vm.id,"placeholder":_vm.placeholder ? _vm.placeholder : _vm.label,"aria-label":_vm.label,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.content)?_vm._i(_vm.content,null)>-1:(_vm.content)},on:{"change":function($event){var $$a=_vm.content,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.content=$$a.concat([$$v]))}else{$$i>-1&&(_vm.content=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.content=$$c}}}},'input',_vm.$attrs,false),_vm.inputListeners)):((_vm.type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"focus:tw-bg-white tw-bg-white tw-border hover:tw-border-gray-600 hover:tw-bg-secondary-100 focus:tw-border-gray-600 tw-transition tw-duration-150 tw-ease-in-out focus:tw-ring focus:tw-ring-gray-300 focus:tw-outline-none tw-border-solid tw-shadow text-left tw-pt-4 tw-py-3 tw-px-3 tw-leading-5 tw-font-thin tw-text-base tw-rounded-sm tw-w-full",class:{
        'tw-border-red-500 tw-shadow-outline-red': _vm.error,
        'tw-border-gray-300 ': !_vm.error
      },attrs:{"id":_vm.id,"name":_vm.id,"placeholder":_vm.placeholder ? _vm.placeholder : _vm.label,"aria-label":_vm.label,"type":"radio"},domProps:{"checked":_vm._q(_vm.content,null)},on:{"change":function($event){_vm.content=null}}},'input',_vm.$attrs,false),_vm.inputListeners)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"focus:tw-bg-white tw-bg-white tw-border hover:tw-border-gray-600 hover:tw-bg-secondary-100 focus:tw-border-gray-600 tw-transition tw-duration-150 tw-ease-in-out focus:tw-ring focus:tw-ring-gray-300 focus:tw-outline-none tw-border-solid tw-shadow text-left tw-pt-4 tw-py-3 tw-px-3 tw-leading-5 tw-font-thin tw-text-base tw-rounded-sm tw-w-full",class:{
        'tw-border-red-500 tw-shadow-outline-red': _vm.error,
        'tw-border-gray-300 ': !_vm.error
      },attrs:{"id":_vm.id,"name":_vm.id,"placeholder":_vm.placeholder ? _vm.placeholder : _vm.label,"aria-label":_vm.label,"type":_vm.type},domProps:{"value":(_vm.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value}}},'input',_vm.$attrs,false),_vm.inputListeners)),(false)?_c('div',{staticClass:"tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none"},[_c('svg',{staticClass:"tw-h-5 tw-w-5 tw-text-red-500",attrs:{"fill":"tw-currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])]):_vm._e(),_vm._t("append-inner")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }