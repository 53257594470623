<template>
  <!-- x-classes="btn btn-secondary tw-bg-gray-100 tw-text-black tw-border-transparent tw-border-solid tw-border hover:tw-bg-gray-300 focus:tw-bg-gray-300 tw-justify-center" -->
  <BaseBtn
    :type="type"
    :disabled="disabled"
    :block="block"
    :loading="loading"
    :x-classes="x_classes"
    :handleClick="handleClick"
  >
    <slot></slot>
  </BaseBtn>
</template>

<script>
import BaseBtn from "./BaseBtn";
export default {
  components: {
    BaseBtn,
  },
  props: {
    type: {
      type: String,
      default: "submit",
    },

    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    handleClick: {
      type: Function,
      default: () => null,
    },
  },
  data: () => ({
    classes:
      "btn btn-secondary  tw-text-black tw-border-transparent tw-border-solid tw-border hover:tw-bg-gray-300 focus:tw-bg-gray-300 tw-justify-center",
  }),
  computed: {
    x_classes() {
      return this.loading
        ? `tw-bg-black ${this.classes}`
        : `tw-bg-gray-100 ${this.classes}`;
    },
  },
};
</script>
