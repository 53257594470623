<template>
  <section id="step-bolsillo" class="style-single options" :class="{ 'is-active': isTrue }">
    <div class="inner">
      <header>
        <BackBtn />
        <h5>Bolsillo</h5>
      </header>
      <div class="flex flex-wrap option-grid">
        <PocketInput
          v-for="(style,index) in pocket_styles"
          id="check"
          :key="index"
          :value="style.id"
          :img="style.url_img"
          :name="style.name"
          :data-img="style.url_img"
          :img_shirt="style.url_img_body"
          :item_id="style.item_id"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BackBtn from "../../../components/BackBtn";
import PocketInput from "../../../components/steps/two/PocketInput";
export default {
  components: {
    BackBtn,
    PocketInput
  },
  data() {
    return {
      isTrue: false
    };
  },
  computed: {
    pocket_styles() {
      return this.$store.getters.pocketStyles;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isTrue = true;
    }, 100);
  }
};
</script>

<style>
</style>