export const createOrder = body => {
  return window.NUCLIUZ.post(`/api/user/orders`, body);
};
export const allOrder = (params = "") => {
  return window.NUCLIUZ.get(`/api/user/orders?${params}`);
};
export const showOrder = (uuid = "") => {
  return window.NUCLIUZ.get(`/api/user/orders/${uuid}`);
};
export const applyOrder = (body = "") => {
  return window.NUCLIUZ.post(`/api/user/apply-coupon`, body);
};
