/**
 *
 * @param {string} currency_code
 * @returns
 */
export const moneyFormat = (currency_code = "PEN") =>
  new Intl.NumberFormat(currency_code === "PEN" ? "es-PE" : "en-EN", {
    style: "currency",
    currency: currency_code,
    minimumFractionDigits: 2
  });
/**
 *
 * @param {number} total
 * @returns
 */
export const formatPEN = total => moneyFormat("PEN").format(total / 100);
