<template>
  <transition name="fade">
    <!-- :class="{'display-block':modalLogin}" -->
    <div
      v-show="modalLogin"
      id="modal_window"
      class="modal in display-reg"
      @click="body"
    >
      <div class="content modal-dialog modal-lg tw-h-full sm:tw-h-auto">
        <div class="content-inside modal-content">
          <transition name="fade" mode="out-in">
            <Login v-if="toggle == 'login'" />
            <Register v-if="toggle == 'register'" />
            <PasswordRecovery v-if="toggle == 'forgot'" />
            <ResetPassword v-if="toggle == 'reset'" />
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Login from "./Login";
import Register from "./Register";
import PasswordRecovery from "./PasswordRecovery";
import ResetPassword from "./ResetPassword";
export default {
  components: {
    Login,
    Register,
    PasswordRecovery,
    ResetPassword,
  },
  data() {
    return {
      email: "",
      password: "",
      errorLogin: "",
    };
  },
  computed: {
    modalLogin() {
      return this.$store.getters.isModalLoginOpen;
    },
    toggle() {
      return this.$store.getters["auth/formCurrent"];
    },
    loading() {
      return this.$store.getters["auth/isAuthLoading"];
    },
  },
  mounted() {
    // return this.$store.commit("auth/toggleForm", "login");
    //  (this.toggle = "login");
  },
  methods: {
    toggleLoginModal() {
      this.$store.commit("toggleModalLoginOpen", false);
    },
    // toggleForgot() {
    //   return this.$store.commit("auth/toggleForm", "forgot");
    // },
    // toggleReset() {
    //   return this.$store.commit("auth/toggleForm", "reset");
    // },
    // toggleLogin() {
    //   return this.$store.commit("auth/toggleForm", "login");
    // },
    // toggleRegister() {
    //   return this.$store.commit("auth/toggleForm", "register");
    // },
    body(e) {
      console.log(this.loading);
      if (e.target.id === "modal_window" && !this.loading) {
        this.toggleLoginModal();
      }
    },
  },
};
</script>

<style>
</style>