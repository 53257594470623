<template>
  <!-- 
      class="  
      focus:tw-bg-white 
      focus:tw-border-gray-600 
      focus:tw-outline-none 
      focus:tw-ring focus:tw-ring-gray-300 
      tw-border 
      tw-border-gray-300 
      hover:tw-border-gray-600 
      hover:tw-bg-secondary-100 
      tw-font-thin 
      tw-leading-5
       tw-block"

       sm:tw-leading-6 tw-text-sm
      tw-shadow 
      style="font-size: 16px !important" -->
  <div class="g-form-floating tw-relative tw-rounded-sm tw-shadow-sm">
    <input
      :id="id"
      v-model="content"
      v-bind="$attrs"
      :name="id"
      :type="type"
      :placeholder="placeholder ? placeholder : label"
      :aria-label="label"
      class="g-form-control focus:tw-bg-white tw-bg-white tw-border tw-border-gray-300 hover:tw-border-gray-600 tw-rounded-sm hover:tw-bg-secondary-100 focus:tw-border-gray-600 tw-transition tw-duration-150 tw-ease-in-out focus:tw-ring focus:tw-ring-gray-300 focus:tw-outline-none tw-border-solid tw-shadow text-left tw-pt-6 tw-pb-1 tw-px-3 tw-leading-5 tw-font-thin tw-w-full tw-text-base"
    />
    <label class="tw-truncate tw-flex-inline tw-font-medium" :for="id">{{
      label
    }}</label>
    <div
      v-if="false"
      class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none z-10"
    >
      <svg
        class="tw-h-5 tw-w-5 tw-text-red-500"
        fill="tw-currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>

    <slot name="append-inner"></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>