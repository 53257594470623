<template>
  <div
    key="shipping_checkout"
    class="step pa3 pa4-ns bg-white-40 mb3 nl3 nr3 mh0-ns bg-white is-active"
  >
    <ValidationObserver ref="form">
      <form @submit.prevent="confirmShipping">
        <div class="step--header flex justify-between">
          <h5 class="mb0">Envio</h5>
        </div>
        <div class="step--inner pt4">
          <div class="flex flex-wrap w-100 mb3">
            <div class="tw-w-full tw-mb-4 sm:tw-w-2/3">
              <validation-provider v-slot="{ errors }" rules="required">
                <InputForm
                  id="shipping_name"
                  v-model="full_name"
                  :error="!!errors[0]"
                  label="Nombre Completo"
                  placeholder="Nombres Apellidos"
                />
              </validation-provider>
            </div>

            <div class="tw-w-full tw-mb-4 sm:tw-w-2/3">
              <validation-provider v-slot="{ errors }" rules="required">
                <InputForm
                  id="shipping_email"
                  v-model="email"
                  type="email"
                  :error="!!errors[0]"
                  label="Email"
                  placeholder=" "
                />
              </validation-provider>
            </div>
            <div class="tw-w-full tw-mb-4 sm:tw-w-2/3">
              <validation-provider v-slot="{ errors }" rules="required">
                <InputForm
                  id="shipping_phone"
                  v-model="phone"
                  type="tel"
                  :error="!!errors[0]"
                  label="Teléfono"
                  placeholder=" "
                />
              </validation-provider>
            </div>
            <div class="tw-w-full tw-mb-4 md:tw-w-1/3"></div>
            <div class="form-group w-100 md:tw-w-1/3 md:tw-pr-2">
              <SelectForm
                id="shipping_doc_type"
                v-model="doc_type"
                :options="doc_types"
                label="Tipo de Documento"
              />
            </div>
            <div class="form-group w-100 md:tw-w-2/3 md:tw-pl-2">
              <validation-provider v-slot="{ errors }" rules="required">
                <InputForm
                  id="shipping_doc_num"
                  v-model="doc_num"
                  :error="!!errors[0]"
                  label="Numero de Documento"
                  placeholder=" "
                />
              </validation-provider>
            </div>
            <div class="form-group w-100 md:tw-w-1/3 md:tw-pr-2">
              <validation-provider v-slot="{ errors }" rules="required">
                <SelectForm
                  id="district"
                  v-model="district"
                  :options="districts"
                  :error="!!errors[0]"
                  label="Distrito"
                />
              </validation-provider>
            </div>
            <div class="form-group w-100 md:tw-w-2/3 md:tw-pl-2">
              <validation-provider v-slot="{ errors }" rules="required">
                <InputForm
                  id="shipping_street_address1"
                  v-model="address"
                  :error="!!errors[0]"
                  label="Dirección"
                  placeholder=" "
                />
              </validation-provider>
            </div>
            <div class="form-group flex-auto w-100 mb3">
              <validation-provider v-slot="{ errors }" rules="required">
                <InputForm
                  id="shipping_reference"
                  v-model="reference"
                  :error="!!errors[0]"
                  label="Referencia"
                  placeholder=" "
                />
              </validation-provider>
            </div>
            <div class="form-group flex-auto w-100 mb3">
              <!-- v-model="additionals" -->
              <validation-provider v-slot="{ errors }" rules="min:0|max:180">
                <TextareaForm
                  id="shipping_reference"
                  v-model="additionals"
                  label="Detalles Adicionales"
                  placeholder=" "
                  :error="!!errors[0]"
                />
              </validation-provider>
            </div>
          </div>
          <div
            class="
              tw-flex
              tw-flex-col-reverse
              tw-w-full
              tw-space-y-4
              tw-space-y-reverse
              mt3
              lg:tw-flex-row lg:tw-space-y-0 lg:tw-space-x-4
            "
          >
            <div class="tw-block lg:tw-inline-flex">
              <a href="https://www.jdoutfit.com/gift-cards">
                <ThirdBtn type="button">
                  <ArrowLeft
                    x-classes=" tw--ml-1 tw-mr-2 tw-text-gray-600"
                  />VOLVER
                </ThirdBtn>
              </a>
            </div>
            <PrimaryBtn type="submit" :disabled="submit_event_disabled">
              Continuar
              <ArrowRight x-classes="tw--mr-1 tw-ml-2 tw-text-gray-300" />
            </PrimaryBtn>
          </div>

          <div v-if="false" class="tw-flex tw-w-full tw-mb-4 md:tw-hidden">
            <div class="tw-inline-flex">
              <a href="https://www.jdoutfit.com/gift-cards">
                <BorderLessBtn type="button">
                  <ArrowLeft
                    x-classes=" tw--ml-1 tw-mr-2 tw-text-gray-600"
                  />VOLVER
                </BorderLessBtn>
              </a>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import { mapFields } from "@/helpers/gstate";
extend("required", {
  ...required,
  message: "This field is required"
});
extend("max", {
  ...max,
  message: "Field is too long"
});
extend("min", {
  ...min,
  message: "Field is too long"
});

import SelectForm from "@/components/forms/SelectForm";
import InputForm from "@/components/forms/InputForm.vue";
import ArrowLeft from "@/components/icons/ArrowLeft";
import ArrowRight from "@/components/icons/ArrowRight";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import ThirdBtn from "@/components/buttons/ThirdBtn";
import BorderLessBtn from "@/components/buttons/BorderLessBtn";
import TextareaForm from "@/components/forms/TextareaForm.vue";
export default {
  components: {
    ThirdBtn,
    ArrowLeft,
    ArrowRight,
    PrimaryBtn,
    BorderLessBtn,
    InputForm,
    SelectForm,
    TextareaForm
  },
  data() {
    return {
      submit_event_disabled: false
    };
  },
  computed: {
    ...mapState({
      doc_types: state =>
        state.constants.doc_types.map(item => {
          const new_item = {
            text: item.label,
            value: item.name
          };
          return new_item;
        }),
      districts: state =>
        state.size.init.district.map(item => {
          return {
            text: item.label,
            value: item.value
          };
        })
    }),
    ...mapFields({
      fields: [
        "full_name",
        "doc_num",
        "doc_type",
        "phone",
        "email",
        "district",
        "address",
        "reference",
        "additionals"
      ],
      base: ["giftCards", "shipping"],
      mutation: "giftCards/setShipping"
    }),
    user() {
      return this.$store.getters.currentUser;
    }
  },
  methods: {
    confirmShipping() {
      console.log("form");
      this.$refs.form.validate().then(success => {
        if (success) {
          this.submit_event_disabled = true;
          return this.$router.push({ name: "gift-cards.checkout.confirm" });
        }
      });
    }
  }
};
</script>

<style></style>
