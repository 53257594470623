import { createCardToken } from "@/api/culqi";
import {
  showGiftCard,
  storeGiftCard,
  processGiftCard,
  getGiftCardOrder
} from "@/api/giftCards";

export const GiftCards = {
  namespaced: true,
  state: {
    order: {
      order_uuid: null,
      order_item_id: null,

      is_loading: true,
      data: {}
    },
    orders: {
      selected: {
        is_loading: true,
        data: {}
      }
    },
    show: {
      is_loading: true,
      data: {}
    },
    // order_uuid: null,
    shipping: {
      full_name: "",
      doc_num: "",
      email: "",
      doc_type: "",
      phone: "",
      district: "",
      address: "",
      reference: "",
      additionals: ""
    },
    card: {
      card_num: "••••",
      type: "VISA"
    }
  },
  mutations: {
    setShipping(state, payload) {
      state.shipping = { ...state.shipping, ...payload };
    },
    setGiftCard(state, payload) {
      Object.keys(payload).forEach(key => (state.show[key] = payload[key]));
    },
    setGiftCardSelectedOrder(state, payload) {
      Object.keys(payload).forEach(
        key => (state.orders.selected[key] = payload[key])
      );
    },
    setGiftCardOrder(state, payload) {
      Object.keys(payload).forEach(key => (state.order[key] = payload[key]));
    }
  },
  getters: {},
  actions: {
    show({ commit }, { id }) {
      commit("setGiftCard", { is_loading: true });
      return showGiftCard(id)
        .then(({ data: { gift_card } }) => {
          commit("setGiftCard", { data: gift_card });
        })
        .finally(() => {
          commit("setGiftCard", { is_loading: false });
        });
    },

    async processGiftCard({}, payload) {
      // envias datos al backend con el uuid para actualizar el pedido
      return processGiftCard(payload);
    },
    async createCardToken({}, payload) {
      // 100% culqi
      return createCardToken(payload);
    },
    async storeGiftCard({}, payload) {
      // set uuid del pedido
      return storeGiftCard(payload);
    },
    getGiftCardOrder({ commit }, { uuid }) {
      commit("setGiftCardSelectedOrder", {
        is_loading: true
      });
      return getGiftCardOrder(uuid)
        .then(({ data: { gift_card } }) => {
          return commit("setGiftCardSelectedOrder", {
            data: gift_card
          });
        })
        .finally(() => {
          commit("setGiftCardSelectedOrder", {
            is_loading: false
          });
        });
    },

    async processPayment({ dispatch, commit, state }, { culqi, payment }) {
      try {
        const order = {
          ...state.shipping,
          ...state.order,
          ...payment,
          gift_card_id: state.show.data.id
        };
        const [data_card_token, data_order] = await Promise.all([
          dispatch("createCardToken", {
            ...culqi,
            email: state.shipping.email
          }),
          dispatch("storeGiftCard", order).then(({ data }) => {
            commit("setGiftCardOrder", {
              order_uuid: data.order.uuid,
              order_item_id: data.order_item.id
            });
            return data;
          })
        ]);
        const payload_create_charge = {
          source_id: data_card_token.data.id,
          uuid: data_order.order.uuid
        };
        await dispatch("processGiftCard", payload_create_charge);
        // await dispatch("orders/getOrders", {}, { root: true });
        // commit("setCardNumber", payment.card);
        // commit("setCardType", payment.type);
        // dispatch("resetShippingFields");
      } catch (error) {
        if (error.response?.data?.merchant_message) {
          throw error.response.data.merchant_message;
        } else {
          throw window.errorsValidate(error);
        }
      }
    }
  }
};
