<template>
  <PurchaseLayout>
    <template v-slot:left>
      <div class="w-100 flex-auto">
        <div class="view--checkout">
          <div class="steps">
            <transition
              enter-active-class="tw-ease-out tw-duration-300"
              enter-class="tw-opacity-0"
              enter-to-class="tw-opacity-100"
              leave-active-class="tw-ease-in tw-duration-200"
              leave-class="tw-opacity-100"
              leave-to-class="tw-opacity-0"
              mode="out-in"
            >
              <router-view :key="$route.fullPath" />
            </transition>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <transition
        mode="out-in"
        enter-active-class="ease-out duration-150"
        enter-class="opacity-0 "
        enter-to-class="opacity-100 "
        leave-active-class="ease-in duration-100"
        leave-class="opacity-100 "
        leave-to-class="opacity-0 "
      >
        <CheckoutGiftCardDetails v-if="!is_loading" />
      </transition>
    </template>
  </PurchaseLayout>
</template>

<script>
import PurchaseLayout from "@/layout/PurchaseLayout";
import { mapState } from "vuex";
import CheckoutGiftCardDetails from "@/components/static/CheckoutGiftCardDetails";
export default {
  components: {
    CheckoutGiftCardDetails,
    PurchaseLayout
  },
  props: {
    giftCardId: {
      type: [String, Number],
      default: ""
    }
  },
  computed: {
    ...mapState("giftCards", {
      is_loading: state => state.show.is_loading
    })
  },
  mounted() {
    this.$store.dispatch("giftCards/show", { id: this.giftCardId });
  }
};
</script>

<style>
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
