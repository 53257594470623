import Vue from "vue";
import Vuex from "vuex";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { Sizes } from "./modules/sizes";
import { Orders } from "./modules/orders";
import { Checkout } from "./modules/checkout";
import { GiftCards } from "./modules/giftCards";
import { AuthParams } from "./authState";
import { AlertsParams } from "./alertState";
import { Notifications } from "./modules/notifications";
import { Constants } from "./modules/constants";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    checkout: Checkout,
    auth: AuthParams,
    giftCards: GiftCards,
    alerts: AlertsParams,
    notifications: Notifications,
    sizes: Sizes,
    orders: Orders,
    constants: Constants
  },
  state: {
    uuid_shirt_query: null,
    toBuyLoading: false,
    loadingSave: false,
    purchaseAction: false,
    loading: false,
    toggles: {
      navBarMobile: false
    },
    authenticated: false,
    currentUser: {},
    modalOpen: false,
    MobileNavOpen: false,
    modalLoginOpen: false,
    shirtChange: false,
    second_purchase: false,
    validateSize: { tallas: false, date: false },
    modals: {
      measurements: false,
      mail: false,
      tailor: false
    },
    steps: {
      third: {
        toggleStandard: false
      }
    },
    data: {
      fabrics: [],
      collar_styles: [],
      cuff_styles: [],
      pocket_styles: [],
      placket_styles: [],
      buttons: [],
      monogram: {
        styles: [
          { value: "SCRIPT", label: "Script" },
          { value: "SERIF", label: "Serif" }
        ],
        colors: [
          {
            value: "BLACK",
            label: "Black",
            hex: "#111",
            style: "background: rgb(17, 17, 17);"
          },
          {
            value: "WHITE",
            label: "White",
            hex: "#fff",
            style: "background: rgb(255, 255, 255);"
          },
          {
            value: "GRAY",
            label: "Gray",
            hex: "#777777",
            style: "background: rgb(119, 119, 119);"
          },
          {
            value: "LIGHT BLUE",
            label: "Light Blue",
            hex: "#C0D1DF",
            style: "background: rgb(192, 209, 223);"
          },
          {
            value: "NAVY",
            label: "Navy",
            hex: "#26354F",
            style: "background: rgb(38, 53, 79);"
          }
        ],
        positions: [
          { value: "PUÑO", label: "Puño" },
          { value: "PECHO BOLSILLO", label: "Pecho / Bolsillo" },
          { value: "PECHERA ABAJO", label: "Pechera Abajo" }
          // { value: "MANGA IZQUIERDA", label: "Manga Izquierda" },
          // { value: "MANGA DERECHA", label: "Manga Derecha" },
          // { value: "PECHO", label: "Pecho" },
          // {
          //   value: "ESTOMAGO PARTE IZQUIERDA",
          //   label: "Estomago parte izquierda"
          // }
        ]
      }
    },
    shirt: {
      current_shirt_b64: "",
      body: "",
      tela: {
        id: "",
        url: "",
        name: ""
      },
      cuello: {
        id: "",
        item_id: "",
        url: "",
        name: ""
      },
      manga: {
        id: "",
        item_id: "",
        url: "",
        name: ""
      },
      bolsillo: {
        id: "",
        item_id: "",
        url: "",
        name: ""
      },
      placket: {
        id: "",
        item_id: "",
        url: "",
        name: ""
      },
      boton: {
        id: "",
        name: "",
        places: {
          boton_manga: "",
          boton_cuello: "",
          boton_camisa: ""
        },
        additionals: {
          collar: "",
          cuff: "",
          pocket: ""
        }
      },
      huecos_placket: {
        id: "",
        url:
          "https://s3.amazonaws.com/proper-media/das4-images/1601/4f12444c3de608bd4d395459e49be017_2_btnh_body.png",
        name: ""
      },
      hueco_mango: {
        id: "",
        url:
          "https://s3.amazonaws.com/proper-media/das4-images/1601/4f12444c3de608bd4d395459e49be017_2_btnh_lobc.png",
        name: ""
      },
      monogram: {
        value: false,
        word: "",
        style: "",
        color: "",
        position: ""
      },
      brand: {
        id: "",
        // url: 'https://jdoutfit.com/cdn/images/shirt_tag.png',
        url: "https://jdoutfit-shirt-creator.s3.amazonaws.com/shirt_tag.png",
        name: ""
      }
    },
    size: {
      init: {
        finish: [
          { value: "LOOSE_FIT", label: "Loose fit" },
          { value: "NORMAL_FIT", label: "Normal fit" },
          { value: "SLIM_FIT", label: "Slim fit" }
        ],
        size: [
          { value: "s", label: "Small" },
          { value: "m", label: "Medium" },
          { value: "l", label: "Large" },
          { value: "xl", label: "Extra Large" }
        ],
        district: [
          { value: "Miraflores", label: "Miraflores" },
          { value: "Surco", label: "Surco" },
          { value: "San Borja", label: "San Borja" },
          { value: "San isidro", label: "San Isidro" },
          { value: "La Molina", label: "La Molina" },
          { value: "Barranco", label: "Barranco" },
          { value: "Surquillo", label: "Surquillo" }
        ],
        hour: [
          { value: "8:00 pm", label: "8:00 pm" },
          { value: "9:00 pm", label: "9:00 pm" },
          { value: "10:00 pm", label: "10:00 pm" }
        ],
        setDate: {
          name: "Cita con Sastre",
          value: "meet_with_sastre"
        },
        standardSize: {
          name: "Talla Standard",
          value: "standard_size"
        },
        measureBody: {
          name: "Mide tu cuerpo",
          value: "measure_self"
        },
        mailShirt: {
          name: "Enviar una camisa",
          value: "mail_shirt"
        },
        setSize: {
          name: "Medida",
          value: "previous_size"
        }
      },
      method: "",
      nameMethod: "",
      values: {
        meet_with_sastre: {
          address: "",
          district: "Miraflores",
          date: [
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            new Date().getDate() + 1
          ]
            .map((val, index) => {
              if (index > 0) {
                return val.toString().length < 2 ? "0" + val : val;
              }
              return val;
            })
            .join("-"),
          hours: "8:00 pm"
        },
        standard_size: {
          standart_type_size_name: "m",
          standart_type_of_fit_form: "NORMAL_FIT"
        },
        measure_self: {
          neck_input: "",
          arm_length_input: "",
          shoulder_input: "",
          chest_input: "",
          waist_input: "",
          hip_input: "",
          length_input: "",
          bicep_input: "",
          wrist_input: "",
          type_of_fit_input: 2
        },
        mail_shirt: {}
      }
    },
    route: {
      currentPath: ""
    }
  },
  getters: {
    current_shirt_b64(state) {
      return state.shirt.current_shirt_b64;
    },
    toggles(state) {
      return state.toggles;
    },
    loadingSave(state) {
      return state.loadingSave;
    },
    currentUser(state) {
      return state.currentUser;
    },
    toBuyLoading(state) {
      return state.toBuyLoading;
    },
    MobileNavOpen(state) {
      return state.MobileNavOpen;
    },
    isAuth(state) {
      return state.authenticated;
    },
    sizeMethod(state) {
      return state.size.method;
    },
    sizeValues(state) {
      return state.size.values;
    },
    sizeFinishes(state) {
      return state.size.init.finish;
    },
    sizeDistrict(state) {
      return state.size.init.district;
    },
    sizeStandards(state) {
      return state.size.init.size;
    },
    sizeName(state) {
      return state.size.nameMethod;
    },
    sizeSetDate(state) {
      return state.size.init.setDate;
    },
    setSize(state) {
      return state.size.init.setSize;
    },
    sizeStandardSize(state) {
      return state.size.init.standardSize;
    },
    sizeMeasureBody(state) {
      return state.size.init.measureBody;
    },
    sizeMailShirt(state) {
      return state.size.init.mailShirt;
    },
    modalSetDate(state) {
      return state.modals.tailor;
    },
    validateSize(state) {
      return state.validateSize;
    },
    isModalLoginOpen(state) {
      return state.modalLoginOpen;
    },
    getThirdStandardSizeToggle(state) {
      return state.steps.third.toggleStandard;
    },
    shirtChange(state) {
      return state.shirtChange;
    },
    getShirt(state) {
      return state.shirt;
    },
    isModalOpen(state) {
      return state.modalOpen;
    },
    modalMeasurement(state) {
      return state.modals.measurements;
    },
    isLoading(state) {
      return state.loading;
    },
    monogram(state) {
      return state.shirt.monogram.value;
    },
    monogramParams(state) {
      return state.shirt.monogram;
    },
    tela(state) {
      return state.shirt.tela.id;
    },
    cuello(state) {
      return state.shirt.cuello.id;
    },
    manga(state) {
      return state.shirt.manga.id;
    },
    bolsillo(state) {
      return state.shirt.bolsillo.id;
    },
    placket(state) {
      return state.shirt.placket.id;
    },
    boton(state) {
      return state.shirt.boton.id;
    },
    currentPath(state) {
      return state.route.currentPath;
    },
    fabrics(state) {
      return state.data.fabrics;
    },
    collarStyles(state) {
      return state.data.collar_styles;
    },
    cuffStyles(state) {
      return state.data.cuff_styles;
    },
    pocketStyles(state) {
      return state.data.pocket_styles;
    },
    placketStyles(state) {
      return state.data.placket_styles;
    },
    buttons(state) {
      return state.data.buttons;
    },
    monogramData(state) {
      return state.data.monogram;
    },
    modalMailShirt(state) {
      return state.modals.mail;
    },
    purchaseAction(state) {
      return state.purchaseAction;
    },
    second_purchase(state) {
      return state.second_purchase;
    }
  },
  mutations: mutations,
  actions: actions
});
