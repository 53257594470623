<template>
  <div class="">
    <label v-if="label" :for="id">{{ label }}</label>
    <div class="tw-relative tw-shadow-sm">
      <textarea
        :id="id"
        v-model="content"
        v-bind="$attrs"
        :name="id"
        :type="type"
        :placeholder="placeholder ? placeholder : label"
        :aria-label="label"
        class="tw-w-full tw-h-24 focus:tw-bg-white tw-bg-white tw-border hover:tw-border-gray-600 hover:tw-bg-secondary-100 focus:tw-border-gray-600 tw-transition tw-duration-150 tw-ease-in-out focus:tw-ring focus:tw-ring-gray-300 focus:tw-outline-none tw-border-solid tw-shadow text-left tw-pt-4 tw-py-3 tw-px-3 tw-leading-5 tw-font-thin tw-text-base tw-rounded-sm"
        :class="{
          'tw-border-red-500 tw-shadow-outline-red': error,
          'tw-border-gray-300 ': !error,
        }"
        v-on="inputListeners"
      />
      <div
        v-if="false"
        class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none"
      >
        <svg
          class="tw-h-5 tw-w-5 tw-text-red-500"
          fill="tw-currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>

      <slot name="append-inner"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Date],
      default: "",
      // required: true,
      // validator: (v) => true,
      //   validator(value) {
      //   return ['indigo', 'white'].includes(value)
      // },
    },

    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    inputListeners: function () {
      let vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event.target.value);
          },
        }
      );
    },
  },
};
</script>

<style>
</style>