<template>
  <label key="1601" class="block w-33 mb3 single-fabric" :data-name="name">
    <input
      :id="id"
      v-model="tela"
      class
      type="radio"
      name="fabric"
      :value="value"
      :data-name="name"
      data-construction="100"
      data-thickness="0.009"
      data-price="95"
      data-id="1601"
      :data-img="img"
    />
    <span>
      <img height="217" width="372" :src="img" :data-src="img" />
      <!-- src="https://cdn2.propercloth.com/pictures/1601.jpg" -->
      <i class="single-fabric__info">i</i>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    img_url: {
      type: String,
      default: ""
    }
  },
  computed: {
    tela: {
      get: function() {
        return this.$store.getters.tela;
      },
      set: function(value) {
        console.log(value);
        console.log(this.name);
        console.log(this.img_url);
        const data = {
          value: value,
          name: this.name,
          img_url: this.img_url
        };
        // console.log(this);
        // const myAttribute = this.$el.getAttribute("data-img");
        // console.log(myAttribute);

        // this.$store.dispatch("getTelas", value);
        this.$store.dispatch("changeFabric", data);
        // this.$store.commit("setTela", value);
      }
    }
  }
};
</script>

<style>
</style>