import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// import Home from '../views/Home.vue';
// import StepBody from '../views/StepBody.vue';
import ShowShirt from "../views/steps/ShowShirt.vue";
import FirstStep from "../views/steps/FirstStep.vue";
import SecondStep from "../views/steps/SecondStep.vue";
import ThirdStep from "../views/steps/ThirdStep.vue";
import FourthStep from "../views/steps/FourthStep.vue";
import Summary from "../views/steps/second/Summary.vue";
import Cuello from "../views/steps/second/Cuello.vue";
import Puno from "../views/steps/second/Puno.vue";
import Bolsillo from "../views/steps/second/Bolsillo.vue";
import Botones from "../views/steps/second/Botones.vue";
import Placket from "../views/steps/second/Placket.vue";
import Monogram from "../views/steps/second/Monogram.vue";
import Customizer from "../views/Customizer.vue";
import CommonContainer from "../layout/CommonContainer";
import Checkout from "../pages/CheckoutLayout.vue";
import CheckoutGiftCardLayout from "../pages/CheckoutGiftCardLayout.vue";
import GiftCardShipping from "../views/giftCards/GiftCardShipping.vue";
import GiftCardThankYou from "../views/giftCards/GiftCardThankYou.vue";
import GiftCardPayment from "../views/giftCards/GiftCardPayment.vue";
import OrderCompleted from "../pages/OrderCompletedLayout.vue";
import ShippingStep from "../views/checkout/ShippingStep.vue";
import CardStep from "../views/checkout/CardStep.vue";
import ThankYou from "../views/checkout/ThankYou.vue";
import ResetPasswordResponse from "../views/reset/ResetPasswordResponse";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Customizer,
    children: [
      {
        path: "",
        name: "home",
        redirect: "first"
      },
      {
        path: "/shirt/:uuid",
        name: "fetch_shirt",
        component: ShowShirt,
        props: true
      },
      {
        path: "/first",
        name: "first",
        component: FirstStep
      },
      {
        path: "/second",
        name: "second",
        component: SecondStep,
        children: [
          {
            path: "/",
            name: "sumary_second",
            component: Summary
          },
          {
            path: "cuello",
            name: "cuello_second",
            component: Cuello
          },
          {
            path: "puno",
            name: "puno_second",
            component: Puno
          },
          {
            path: "bolsillo",
            name: "bolsillo_second",
            component: Bolsillo
          },
          {
            path: "placket",
            name: "placket_second",
            component: Placket
          },
          {
            path: "botones",
            name: "botones_second",
            component: Botones
          },
          {
            path: "monogram",
            name: "monogram_second",
            component: Monogram
          }
        ]
      },
      {
        path: "/third",
        name: "third",
        component: ThirdStep
      },
      {
        path: "/summary",
        name: "summary",
        component: FourthStep
      }
    ]
  },
  {
    path: "/gift-cards/:giftCardId",
    component: CheckoutGiftCardLayout,
    props: true,
    children: [
      {
        path: "",
        name: "gift-cards.checkout.shipping",
        component: GiftCardShipping
      },
      {
        path: "confirm",
        name: "gift-cards.checkout.confirm",
        component: GiftCardPayment
      }
    ]
  },
  {
    path: "/checkout",
    component: Checkout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "shiping_checkout",
        component: ShippingStep
      },
      {
        path: "confirm",
        name: "confirm_checkout",
        component: CardStep
      }
    ]
  },
  {
    path: "/thank-you",
    component: CommonContainer,
    // component: OrderCompleted,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "order/:uuid",
        name: "thank_you",
        component: ThankYou,
        props: true
      },
      {
        path: "gift-cards/:uuid",
        name: "thank-you.gift-cards",
        component: GiftCardThankYou,
        props: true
      }
    ]
  },
  {
    path: "/reset/response-password-reset",
    component: ResetPasswordResponse,
    name: "trigger_reset_pass"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "is-active"
});

router.beforeEach(async (to, from, next) => {
  console.log("BEFORE EACH");
  store.dispatch("initApp").then(
    response => {
      console.log("PROMISE");
      console.log("from: ", from);
      console.log("to: ", to);
      // admin

      if (
        to.matched.some(record => record.meta.requiresAuth) &&
        !store.state.authenticated
      ) {
        console.log("First Match");
        next("/");
        return;
      }

      if (
        to.matched.some(record => record.meta.requiresGuest) &&
        store.state.authenticated
      ) {
        console.log("route requires Guest");
        next("/");
        return;
      }

      console.log("Last Match");
      next();
      return;
    },
    error => {
      // handle error here
    }
  );
});

export default router;
