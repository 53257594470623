<template>
  <div class="tw-w-full">
    <label v-if="label" :for="id">{{ label }}</label>

    <div class="tw-relative tw-rounded-sm tw-shadow-sm">
      <select
        :id="id"
        :ref="id"
        v-model="content"
        v-bind="$attrs"
        :name="id"
        class="hover:tw-bg-secondary-100 focus:tw-bg-white tw-bg-white tw-border  hover:tw-border-gray-600 focus:tw-border-gray-600 tw-transition tw-duration-150 tw-ease-in-out focus:tw-ring focus:tw-ring-gray-300 focus:tw-outline-none tw-rounded-sm tw-border-solid tw-shadow tw-pt-4 tw-py-3 tw-px-3 tw-leading-5 tw-text-base"
        :class="{
          'tw-border-red-500 tw-shadow-outline-red': error,
          'tw-border-gray-300 ': !error
        }"
        v-on="inputListeners"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :disabled="
            option.hasOwnProperty('disabled') ? option.disabled : false
          "
          :selected="content == option.value"
        >
          {{ option.text }}
        </option>
      </select>

      <div
        v-if="false"
        class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none z-10"
      >
        <svg
          class="tw-h-5 tw-w-5 tw-text-red-500"
          fill="tw-currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    errorLabel: {
      type: String,
      default: "Revisa este campo."
    },
    error: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: function() {
        return true;
      }
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    inputListeners: function() {
      let vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function(event) {
            vm.$emit("input", event.target.value);
          }
        }
      );
    }
  }
};
</script>

<style></style>
