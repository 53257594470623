<template>
  <label class="w-50 mb2">
    <input v-model="boton" class type="radio" name="button" :value="value" layer="stnd" />
    <span>
      <img :src="img" />
      <span>{{ name }}</span>

      <i class="info-btn">Our standard button. Made from high quality plastic resin. Very durable.</i>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 1
    },
    name: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    botton_img_shirt: { type: String, default: "" },
    botton_img_cuff: { type: String, default: "" },
    botton_img_neck: { type: String, default: "" }
  },
  computed: {
    boton: {
      get: function() {
        return this.$store.getters.boton;
      },
      set: function(value) {
        const data = {
          value: value,
          name: this.name,
          botton_img_shirt: this.botton_img_shirt,
          botton_img_cuff: this.botton_img_cuff,
          botton_img_neck: this.botton_img_neck
        };
        this.$store.commit("setButton", data);
      }
    }
  }
};
</script>

<style>
</style>