<template>
  <div class="ph2 step--summary">
    <div>
      <h3>{{ shirt_fabric }}</h3>
      <p>
        <span class="orig">
          <span class="price">{{ total }}</span>
          <!-- <span v-if="sastre_second_time" key="sastre_price" class="price">
            S./ 199
          </span>
          <span v-else key="regular_price" class="price">S./ 169</span> -->
        </span>
      </p>
    </div>

    <div>
      <p class="warn error-add-to-cart" :class="{ 'display-none': sizeName }">
        Por favor,
        <router-link to="/third">seleccione una talla</router-link>
        {{ " " }}antes de agregar al carrito.
      </p>

      <p
        v-if="
          (sizeCheck.tallas && sizeMethod === measureBody) ||
            (sizeCheck.date && sizeMethod === setDate)
        "
        class="warn error-add-to-cart"
      >
        Porfavor,
        <router-link to="/third">corrija los errores en tallas</router-link>
        {{ " " }}antes de agregar al carrito.
      </p>
    </div>

    <!-- <div class="shirt-actions flex flex-wrap mt3 mt4-ns">
      <div class="tw-w-full sm:tw-w-1/2 sm:tw-pr-2">
        <a
          href="#"
          :class="{ disabled: !sizeName }"
          class="btn btn-add-to-cart tc mr2-ns mb2 mb0-ns justify-center display-flex"
          @click.prevent="fire"
        >
          <transition name="fade" mode="out-in">
            <span v-if="!loadingFire">Comprar</span>
            <BtnLoader v-if="loadingFire" />
          </transition>
        </a>
      </div>
      <div class="tw-w-full sm:tw-w-1/2 align-center sm:tw-pl-2">
        <a
          id="btn-modal-save-wishlist"
          href="#"
          class="btn btn-secondary tc pl2 pr2 justify-center display-flex"
          @click.prevent="saveShirt"
        >
          <transition name="fade" mode="out-in">
            <span v-if="!loadingSave">Guardar Camisa</span>
            <BtnLoader v-if="loadingSave" />
          </transition>
        </a>
      </div>
    </div> -->
    <div
      class="
        tw-flex tw-flex-row tw-flex-wrap tw-w-full tw-mb-8 tw-space-y-4
        mt3
        sm:tw-flex-nowrap sm:tw-space-x-4 sm:tw-mb-0 sm:tw-space-y-0
      "
    >
      <div class="tw-w-full sm:tw-w-1/2">
        <PrimaryBtn
          :disabled="!sizeName"
          type="button"
          :loading="loadingFire"
          :block="true"
          :handleClick="fire"
        >
          Comprar
        </PrimaryBtn>
      </div>
      <div class="tw-w-full sm:tw-w-1/2">
        <BorderLessBtn
          type="submit"
          :handleClick="saveShirt"
          :block="true"
          :loading="loadingSave"
        >
          Agregar a favorito
        </BorderLessBtn>
      </div>
    </div>

    <div class="shirt-details mt4">
      <div>
        <div>Tela</div>
        <div>
          <span>
            <router-link
              to="/first"
              class="tag"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Editar"
            >
              <span>{{ shirt_fabric }}</span>
            </router-link>
          </span>
        </div>
      </div>
      <div>
        <div>Estilo</div>
        <div>
          <span>
            <router-link
              to="/second/cuello"
              href="#"
              class="tag"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Editar"
            >
              <span>{{ shirt_collar }}</span>
            </router-link>

            <router-link
              to="/second/puno"
              href="#"
              class="tag"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Editar"
            >
              <span>{{ shirt_cuff }}</span>
            </router-link>

            <router-link
              to="/second/bolsillo"
              href="#"
              class="tag"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Editar"
            >
              <span>{{ shirt_pocket }}</span>
            </router-link>

            <router-link
              to="/second/placket"
              href="#"
              class="tag"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Editar"
            >
              <span>{{ shirt_placket }}</span>
            </router-link>

            <router-link
              to="/second/botones"
              href="#"
              class="tag"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Editar"
            >
              <span>{{ shirt_btn }}</span>
            </router-link>
          </span>
        </div>
      </div>
      <div>
        <div>Monogram</div>
        <div>
          <span>
            <router-link
              to="/second/monogram"
              class="tag"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Editar"
            >
              <span>{{ monogram ? "Con monograma" : "Sin monograma" }}</span>
              <span class="tw-hidden add-on">+$0</span>
            </router-link>
          </span>
        </div>
      </div>

      <div>
        <div>Talla</div>
        <div>
          <span>
            <router-link
              to="/third"
              class="tag"
              data-go-to="size"
              data-toggle="tooltip"
              data-placement="top"
              title
              data-original-title="Editar"
            >
              {{ sizeName == "" ? "Por favor elegir" : sizeName }}
            </router-link>
            <span v-show="sastre_second_time" class="add-on"> +S./ 30 </span>
          </span>
        </div>
      </div>
    </div>

    <div class="tw-shadow flex bg-black-05 shipping-bar ph1 mt5">
      <div class="pa3 w-50">
        <img src="@/assets/summary-page/package.svg" alt />
        <p>Nuestras camisas estarán en sus manos 7 dias despues del pedido</p>
      </div>
      <div class="pa3 w-50">
        <img src="@/assets/summary-page/guarantee.svg" alt />
        <p>
          Garantizamos el fit perfecto en tu primera camisa, sino te queda te la
          cambiamos sin costo.
          <!-- <a
            href="https://propercloth.com/perfect-fit-guarantee"
            target="_blank"
          ></a>-->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import BorderLessBtn from "@/components/buttons/BorderLessBtn";
import BtnLoader from "@/components/btnLoader";
import { mapGetters } from "vuex";
export default {
  components: {
    // BtnLoader,
    BorderLessBtn,
    PrimaryBtn
  },
  computed: {
    ...mapGetters({
      total: ["checkout/subtotal"]
    }),
    sastre_second_time() {
      return this.sizeMethod === this.setDateValue && this.second_purchase;
    },
    second_purchase() {
      return this.$store.getters.currentUser.user_details?.sastre_once;
    },
    sizeCheck() {
      return this.$store.getters.validateSize;
    },
    loadingFire() {
      return this.$store.getters.toBuyLoading;
    },
    shirt_fabric() {
      const shirt = this.$store.getters.getShirt;
      return shirt.tela.name;
    },
    shirt_collar() {
      const shirt = this.$store.getters.getShirt;
      return shirt.cuello.name;
    },
    shirt_cuff() {
      const shirt = this.$store.getters.getShirt;
      return shirt.manga.name;
    },
    shirt_pocket() {
      const shirt = this.$store.getters.getShirt;
      return shirt.bolsillo.name;
    },
    shirt_placket() {
      const shirt = this.$store.getters.getShirt;
      return shirt.placket.name;
    },
    shirt_btn() {
      const shirt = this.$store.getters.getShirt;
      return shirt.boton.name;
    },
    sizeName() {
      return this.$store.getters.sizeName;
    },
    monogram() {
      console.log(this.$store.getters.monogram);
      return this.$store.getters.monogram;
    },
    sizeMethod() {
      return this.$store.getters.sizeMethod;
    },
    setDateValue() {
      return this.$store.getters.sizeSetDate.value;
    },
    measureBody() {
      return this.$store.getters.sizeMeasureBody.value;
    },
    setDate() {
      return this.$store.getters.sizeSetDate.value;
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
    loadingSave: {
      get() {
        return this.$store.getters.loadingSave;
      },
      set(val) {
        this.$store.commit("loadingSave", val);
      }
    }
  },
  methods: {
    fire() {
      this.$store.dispatch("saveAndPay");
    },
    toggleLoginModal() {
      this.$store.commit("toggleModalLoginOpen", true);
    },
    saveShirt() {
      if (!this.isAuth) {
        this.toggleLoginModal();
      }
      this.$store
        .dispatch("saveDesign")
        .then(res => {
          this.addNotification();
        })
        .catch(err => {});
    },
    addNotification() {
      this.$store.dispatch("notifications/addNotification", {
        title: "Camisa Guardada",
        body: "Este modelo de camisa a sido guardada"
      });
    }
  }
};
</script>

<style></style>
