import { render, staticRenderFns } from "./ShippingStep.vue?vue&type=template&id=03fb4718&"
import script from "./ShippingStep.vue?vue&type=script&lang=js&"
export * from "./ShippingStep.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports