<template>
  <label class="w-50 mb2">
    <input
      v-model="monogram_position"
      class
      type="radio"
      name="monogram-placement"
      :value="value"
      checked
    />
    <span>
      <span>{{ label }}</span>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    monogram_position: {
      get: function() {
        return this.$store.getters.monogramParams.position;
      },
      set: function(value) {
        const data = {
          target: "position",
          value: value
        };
        this.$store.commit("setMonogramParam", data);
      }
    }
  }
};
</script>

<style>
</style>