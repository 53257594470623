<template>
  <PurchaseLayout :key="uuid" title="Muchas Gracias" :order="code">
    <template #left>
      <transition
        enter-active-class="tw-ease-out tw-duration-150"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-100"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
        mode="out-in"
      >
        <div
          v-if="loading_order"
          key="loading-container-shirt"
          class="tw-w-full"
        >
          <div
            class="
              tw-border
              tw-border-gray-300
              tw-shadow
              tw-rounded-md
              tw-p-4
              tw-w-full
              tw-mx-auto
            "
          >
            <div class="tw-animate-pulse tw-flex tw-space-x-4">
              <div class="tw-rounded-3xl tw-bg-gray-400 tw-h-32 tw-w-32"></div>
              <div class="tw-flex-1 tw-space-y-4 tw-py-1">
                <div
                  class="
                    tw-grid tw-grid-cols-1
                    sm:tw-grid-cols-2
                    tw-gap-x-6 tw-gap-y-8
                  "
                >
                  <div
                    class="
                      tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full tw-col-span-2
                    "
                  ></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                </div>
                <div class="tw-space-y-4">
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <aside v-if="!loading_order" key="loaded-container-shirt">
          <div data-title="Secure Checkout" class="dn db-ns tr pt0 pb2 mb2">
            <svg
              width="18"
              height="22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 8h16v12H0V8z" fill="#AAA" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.362 11.655a.5.5 0 0 1-.017.707L6.8 16.691l-2.145-2.043a.5.5 0 0 1 .69-.724L6.8 15.31l3.855-3.672a.5.5 0 0 1 .707.017z"
                fill="#fff"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 7V6a3.9998 3.9998 0 0 0-4-4 4 4 0 0 0-4 4v1H2V6a6 6 0 1 1 12 0v1h-2z"
                fill="#AAA"
              />
            </svg>
          </div>
          <div class="sticky-wrap pb4">
            <!---->
            <div
              class="
                tw-bg-gray-50
                ph3
                pv3
                pa3-ns
                nl3
                nr3
                center-ns
                sidebar__header
                is-expanded
              "
            >
              <div
                class="
                  header-title
                  ma0
                  pv1
                  tl
                  tc-ns
                  gray
                  flex
                  align-baseline
                  justify-between
                "
              >
                <h5 class="ma0 pv1 tl tc-ns gray">
                  <span>Detalles del Pedido</span>
                </h5>
                <!---->
              </div>
              <div
                class="line-items --products mt3 pt4 bt b--black-20 is-expanded"
              >
                <div class="products-container">
                  <!---->
                  <!---->
                  <!---->
                  <div class="product-group w-100">
                    <h6
                      class="tw-mb-2 tw-flex tw-justify-between tw-items-start"
                    >
                      <div>Camisa</div>
                      <status-badge
                        :label="selected_order.status.label"
                        :name="selected_order.status.name"
                      />
                    </h6>
                    <div class="w-100">
                      <div
                        class="
                          tw-flex tw-items-center tw-flex-wrap
                          sm:tw-flex-nowrap
                          mb3
                        "
                      >
                        <span class="tw-flex pr3 tw-w-full sm:tw-w-1/3">
                          <a
                            href="#"
                            aria-label="Go to The Everyday Mask v1.3 - Light Grey Melange (Single Mask)"
                            class="
                              tw-shadow
                              tw-py-2
                              tw-px-1
                              tw-h-full
                              tw-w-full
                              tw-bg-white
                            "
                          >
                            <!-- src="http://localhost:8000/imgs/custom-shirts/0abd2338-5cbb-4bc2-9b3a-4351ac8e5eee.png" -->
                            <img :src="shirt.shirt_img" alt class="tw-w-full" />
                          </a>
                        </span>
                        <div class="flex-auto flex">
                          <div class="shirt-details flex-auto mt1 mb1 f7 pr3">
                            <div>
                              <div>Tela</div>
                              <div>
                                <span>
                                  <router-link
                                    to="/first"
                                    class="tag tw-pointer-events-none"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Editar"
                                  >
                                    <span>{{ shirt.fabric.name }}</span>
                                  </router-link>
                                </span>
                              </div>
                            </div>
                            <div>
                              <div>Estilo</div>
                              <div>
                                <span>
                                  <router-link
                                    to="/second/cuello"
                                    href="#"
                                    class="tag tw-pointer-events-none"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Editar"
                                  >
                                    <span>Cuello {{ shirt.collar.name }}</span>
                                  </router-link>

                                  <router-link
                                    to="/second/puno"
                                    href="#"
                                    class="tag tw-pointer-events-none"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Editar"
                                  >
                                    <span>Puño {{ shirt.cuff.name }}</span>
                                  </router-link>

                                  <router-link
                                    to="/second/bolsillo"
                                    href="#"
                                    class="tag tw-pointer-events-none"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Editar"
                                  >
                                    <span>{{ shirt.pocket.name }}</span>
                                  </router-link>

                                  <router-link
                                    to="/second/placket"
                                    href="#"
                                    class="tag tw-pointer-events-none"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Editar"
                                  >
                                    <span>{{ shirt.placket.name }}</span>
                                  </router-link>

                                  <router-link
                                    to="/second/botones"
                                    href="#"
                                    class="tag tw-pointer-events-none"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Editar"
                                  >
                                    <span>Botones {{ shirt.button.name }}</span>
                                  </router-link>
                                </span>
                              </div>
                            </div>
                            <div>
                              <div>Monogram</div>
                              <div>
                                <span>
                                  <router-link
                                    to="/second/monogram"
                                    class="tag tw-pointer-events-none"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-original-title="Editar"
                                  >
                                    <!-- monogram -->
                                    <span>{{
                                      order_item.id_monogram
                                        ? "Con monograma"
                                        : "Sin monograma"
                                    }}</span>
                                    <span class="add-on tw-hidden">+$0</span>
                                  </router-link>
                                </span>
                              </div>
                            </div>

                            <div>
                              <div>Talla</div>
                              <div>
                                <span>
                                  <router-link
                                    to="/third"
                                    class="tag tw-pointer-events-none"
                                    data-go-to="size"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title
                                    data-original-title="Editar"
                                  >
                                    {{ size.label }}
                                  </router-link>
                                  <span
                                    v-show="sastre_second_time"
                                    class="add-on"
                                  >
                                    +S./ 30
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- <p class="price tr flex-none mt1 f7 pl2" style="min-width: 50px;">
                      $25.00
                    </p>-->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!---->
                  <!---->
                </div>
              </div>
            </div>
            <!-- <h5 class="mb4 tc dn-ns mt4">Order Summary</h5> -->
            <div
              class="
                tw-bg-gray-50
                pa3 pa3-ns
                mb0
                nl3
                nr3
                center-ns
                sidebar__lineitems
              "
            >
              <div class="w-100 pr2 pr0-ns">
                <!---->
                <div id="order-line-charges" class="line-items">
                  <div class="line-item flex">
                    <p class="flex-auto truncate">Envio</p>
                    <p class="price">Gratis</p>
                  </div>
                  <div class="line-item flex">
                    <p class="flex-auto truncate">
                      <strong>Total</strong>
                    </p>
                    <p class="price">
                      <strong>
                        {{ total }}
                      </strong>
                    </p>
                  </div>
                </div>
                <div class="line-item flex items-baseline vat mt1">
                  <p class="flex-auto small tr"></p>
                </div>
              </div>
              <!---->
            </div>
            <!---->
            <div
              class="
                free-ship
                bg-black-05
                br3
                br--bottom
                pa3
                tc
                mt0
                nl1
                nr1
                center-ns
                tw-hidden
              "
            >
              <p class="mb0">Free shipping on orders over $200.00</p>
              <!---->
            </div>
            <!---->
            <div class="pa3 mt2 gift-code tw-hidden">
              <p class="ma0 flex justify-center f7 expand-toggle tc">
                <span>Apply Gift Code</span>
                <span class="icon"></span>
              </p>
              <!---->
            </div>
            <!---->
          </div>
        </aside>
      </transition>
    </template>
    <template #right>
      <transition
        enter-active-class="tw-ease-out tw-duration-150"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-ease-in tw-duration-100"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
        mode="out-in"
      >
        <div v-if="loading_order" key="loading-container" class="tw-w-full">
          <div
            class="
              tw-border
              tw-border-gray-300
              tw-shadow
              tw-rounded-md
              tw-p-4
              tw-w-full
              tw-mx-auto
            "
          >
            <div class="tw-animate-pulse tw-flex tw-space-x-4">
              <!-- <div class="tw-rounded-full tw-bg-gray-400 tw-h-12 tw-w-12"></div> -->
              <div class="tw-flex-1 tw-space-y-4 tw-py-1">
                <div
                  class="
                    tw-grid tw-grid-cols-1
                    sm:tw-grid-cols-2
                    tw-gap-x-6 tw-gap-y-8
                  "
                >
                  <div
                    class="
                      tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full tw-col-span-2
                    "
                  ></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-full"></div>
                </div>
                <div class="tw-space-y-4">
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded"></div>
                  <div class="tw-h-4 tw-bg-gray-400 tw-rounded tw-w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!loading_order"
          key="order-container"
          class="w-100 flex-auto"
        >
          <!---->
          <div class="view--checkout">
            <div class="steps">
              <div
                class="
                  step
                  tw-p-0
                  bg-white-40
                  mb3
                  nl3
                  nr3
                  mh0-ns
                  bg-white
                  is-active
                "
              >
                <div class="tw-p-6 sm:tw-p-8">
                  <div class="tw-text-gray-800 tw-text-sm">
                    <div
                      class="
                        tw-text-lg tw-font-medium tw-leading-7 tw-text-gray-900
                      "
                    >
                      Detalles de la Compra
                    </div>
                    <div
                      class="tw-text-sm tw-leading-7 tw-text-gray-900 tw-mb-4"
                    >
                      Revisa tu bandeja por una copia de los detalles de tu
                      compra
                    </div>
                    <div
                      class="
                        tw-min-w-0 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center tw-mb-4
                      "
                    >
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Nombre
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_full_name }}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Documento de Identidad
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.doc_type.label }}
                            {{ selected_order.billing_doc_number }}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div
                      class="
                        tw-min-w-0 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center tw-mb-4
                      "
                    >
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Teléfono
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_phone }}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Email
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_email }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        tw-min-w-0 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center tw-mb-4
                      "
                    >
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Forma de Pago
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            Tarjeta de Credito
                          </h5>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Tarjeta
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.card_type }}
                            {{ selected_order.card_last_four }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        tw-min-w-0 tw-mb-4 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center
                      "
                    >
                      <div>
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            <!-- <p
                class="mb-0 tw-text-sm tw-font-medium tw-text-gray-600 dark:tw-text-gray-400"
              >-->
                            Direccion
                          </p>
                          <!-- <h5
                class="tw-title-font tw-text-lg tw-font-medium tw-text-gray-900 tw-mb-3"
              > -->
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_address }}
                          </h5>
                        </div>
                      </div>
                      <div class="tw-flex tw-space-x-12">
                        <div>
                          <p
                            class="
                              tw-text-sm tw-font-medium tw-text-gray-600
                              dark:tw-text-gray-400
                              tw-mb-1
                            "
                          >
                            Distrito
                          </p>
                          <h5
                            class="
                              tw-title-font
                              tw-font-medium
                              tw-text-gray-900
                              tw-mb-3
                            "
                          >
                            {{ selected_order.billing_district }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        tw-min-w-0 tw-flex-1
                        md:tw-grid md:tw-grid-cols-2 md:tw-gap-4
                        tw-items-center
                      "
                    >
                      <div>
                        <p
                          class="
                            tw-text-sm tw-font-medium tw-text-gray-600
                            dark:tw-text-gray-400
                            tw-mb-1
                          "
                        >
                          Referencia
                        </p>
                        <h5
                          class="
                            tw-title-font
                            tw-font-medium
                            tw-text-gray-900
                            tw-mb-3
                          "
                        >
                          {{ selected_order.billing_reference }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!-- tw-hidden md: -->
                  <div class="tw-w-full tw-flex mt3 md:tw-space-x-4">
                    <div class="tw-inline-flex">
                      <PrimaryBtn
                        type="button"
                        :handleClick="() => $router.push('/first')"
                      >
                        Crear un nuevo pedido
                      </PrimaryBtn>
                    </div>
                  </div>
                </div>
                <div class="tw-p-6 sm:tw-p-8 tw-mt-8 tw-bg-blue-300 tw-hidden">
                  <div
                    class="
                      tw-text-lg tw-font-medium tw-leading-7 tw-text-gray-900
                    "
                  >
                    Detalles de la Compra
                  </div>
                  <div class="tw-text-sm tw-leading-7 tw-text-gray-900">
                    Revisa tu bandeja por una copia de los detalles de tu compra
                  </div>
                  <dl
                    class="
                      tw-flex
                      tw-flex-col
                      tw-border-t
                      tw-border-b
                      tw-border-gray-200
                      tw-divide-y
                      tw-divide-gray-200
                    "
                  >
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Amp Models
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">200</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Effects Loop
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">Yes</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Inputs
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">2 x 1/4”</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Outputs
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">
                        1 x 1/4”, 2 x XLR, 2 x 1/4”
                      </dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        MIDI I/O
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">In/Out/Thru</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Height
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">8.54”</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Width
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">14.88”</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Depth
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">6.81”</dd>
                    </div>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-py-4"
                    >
                      <dt
                        class="
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-900
                        "
                      >
                        Weight
                      </dt>
                      <dd class="tw-text-sm tw-leading-5">11.73 lbs</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </PurchaseLayout>
</template>

<script>
import PurchaseLayout from "@/layout/PurchaseLayout";
import PrimaryBtn from "../../components/buttons/PrimaryBtn";
import ThirdBtn from "../../components/buttons/ThirdBtn";
import { mapGetters, mapState } from "vuex";
import StatusBadge from "@/components/markers/StatusBadge.vue";
import { formatPEN } from "@/helpers/moneyHelpers";
export default {
  components: {
    // ThirdBtn,
    PrimaryBtn,
    PurchaseLayout,
    StatusBadge
  },
  props: {
    uuid: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    // current_order: {},
  }),
  computed: {
    total() {
      return formatPEN(this.selected_order.total);
    },
    order_item() {
      return this.selected_order.items[0];
    },
    sastre_second_time() {
      return (
        !(this.second_purchase?.id === this.selected_order.id) &&
        this.size.ref === "meet_with_sastre"
      );
    },
    second_purchase() {
      return this.$store.getters.currentUser.user_details?.sastre_once;
    },
    shirt() {
      return this.order_item.shirt;
    },
    size() {
      return this.order_item.size;
    },
    ...mapState({
      shipping: state => state.checkout.shipping,
      card: state => state.checkout.card,
      currentUser: state => state.currentUser,
      selected_order: state => state.orders.selected.order,
      loading_order: state => state.orders.selected.loading
    }),
    ...mapGetters({
      order: ["orders/selected"]
    }),
    code() {
      return this.order?.code;
    }
    // order() {
    //   return this.uuid;
    // },
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      if (
        ["/checkout/confirm"].includes(to.path) &&
        [`/thank-you/order/${this.uuid}`].includes(from.path)
      ) {
        next(false);
      } else {
        next();
      }
    });
    this.$store.dispatch("orders/showOrder", { uuid: this.uuid });
    this.$store.dispatch("orders/setSelected", { uuid: this.uuid });
  }
};
</script>

<style></style>
