<template>
  <!-- mw_signin_register_form -->
  <div id="">
    <div class="modal-header tw-px-6">
      <button
        type="button"
        class="close"
        aria-label="Close"
        :disabled="loading"
        @click.prevent="toggleCloseLoginModal"
      >
        <!-- <span aria-hidden="true">x</span> -->
      </button>
      <h4 class="modal-title">Ingresar a mi cuenta</h4>
    </div>
    <div class="modal-body tw-flex tw-flex-col tw-px-6">
      <form
        class="form-horizontal mt0"
        style="margin-top: 0"
        role="form"
        @submit.prevent="login"
      >
        <div class="tw-w-full tw-mb-4">
          <FloatInputForm
            id="email_login"
            v-model="email"
            type="email"
            label="Email"
            placeholder=" "
          />
        </div>

        <div class="tw-w-full tw-mb-4">
          <FloatInputForm
            id="full_name"
            v-model="password"
            type="password"
            label="Contraseña"
            placeholder=" "
          >
            <template #append-inner>
              <div
                class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-text-sm"
              >
                <a
                  href="#"
                  class="tw-text-gray-500 hover:tw-text-gray-900 tw-transition tw-duration-150 tw-ease-in-out underline"
                  @click.prevent="toggleForgot"
                >
                  ¿Olvidaste?
                </a>
              </div>
            </template>
          </FloatInputForm>
        </div>

        <div class="form-group no-border">
          <div id="captcha_container" />
        </div>
        <div
          class="tw-w-full tw-flex-col-reverse sm:tw-flex-row tw-flex tw-flex-wrap mt3 sm:tw-flex-nowrap sm:tw-space-x-4 tw-mb-8 sm:tw-mb-0 tw-space-y-6 tw-space-y-reverse"
        >
          <div class="tw-w-full sm:tw-w-1/2">
            <ThirdBtn
              type="button"
              :disabled="loading"
              :block="true"
              :handleClick="toggleRegister"
            >
              CREAR UNA CUENTA
            </ThirdBtn>
          </div>
          <div class="tw-w-full sm:tw-w-1/2">
            <PrimaryBtn type="submit" :block="true" :loading="loading">
              INGRESAR
            </PrimaryBtn>
          </div>
        </div>
      </form>
      <p />
      <div class="message-danger mw_text margin-top-0-imp display-block">
        {{ errorLogin }}
      </div>
      <p />
    </div>
  </div>
</template>

<script>
import FloatInputForm from "@/components/forms/FloatInputForm";
import { login } from "@/api/auth";
// import BtnLoader from "@/components/btnLoader";

import PrimaryBtn from "@/components/buttons/PrimaryBtn";
import ThirdBtn from "@/components/buttons/ThirdBtn";
export default {
  components: {
    // BtnLoader,
    PrimaryBtn,
    ThirdBtn,
    FloatInputForm,
  },
  data() {
    return {
      email: "",
      password: "",
      errorLogin: "",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["auth/isAuthLoading"];
    },
    purchaseAction() {
      return this.$store.getters.purchaseAction;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    sizeMethod() {
      return this.$store.getters.sizeMethod;
    },
  },
  methods: {
    toggleCloseLoginModal() {
      this.$store.commit("toggleModalLoginOpen", false);
    },
    toggleForgot() {
      return this.$store.commit("auth/toggleForm", "forgot");
    },
    toggleLogin() {
      return this.$store.commit("auth/toggleForm", "login");
    },
    toggleRegister() {
      return this.$store.commit("auth/toggleForm", "register");
    },
    login() {
      this.$store.commit("auth/setAuthLoading", true);
      const body = {
        email: this.email,
        password: this.password,
        device_name: window.deviceName(),
      };
      this.errorLogin = "";
      return login(body)
        .then((res) => {
          this.$store.dispatch("login", res);
          // this.$store.commit("loginSuccess", res);
          this.email = "";
          this.password = "";
          this.errorLogin = "";
          this.$store.dispatch("notifications/addNotification", {
            title: "Inicio sesión correctamente",
            body: "",
          });
          this.toggleCloseLoginModal();
          return this.$store.dispatch("purchaseCheck");
        })
        .catch((err) => {
          this.errorLogin = err;
        })
        .finally(() => {
          this.$store.commit("auth/setAuthLoading", false);
        });
    },
  },
};
</script>

<style>
</style>