<template>
  <div class="step__sizes" data-step="sizes">
    <!-- pages components  -->
    <transition
      enter-active-class=" tw-transform tw-ease-out  tw-duration-300 tw-transition "
      enter-class="  tw-opacity-0"
      enter-to-class=" tw-opacity-100"
      leave-active-class="tw-transition tw-ease-in tw-duration-150"
      leave-class="tw-opacity-100"
      leave-to-class="tw-opacity-0"
      mode="out-in"
    >
      <Home v-if="!step_selected" key="home_step_3" />
      <UserSizesOption
        v-if="step_selected == 'user_sizes'"
        key="user_sizes_3"
      />
      <SizesOption v-if="step_selected == 'sizes'" key="size_step_3" />
      <DateOption v-if="step_selected == 'date'" />
      <MailOption v-if="step_selected == 'mail'" key="mail_step_3" />
    </transition>
  </div>
</template>

<script>
import Home from "@/components/steps/three/Home.vue";
import DateOption from "@/components/steps/three/DateOption";
import MailOption from "@/components/steps/three/MailOption";
import SizesOption from "@/components/steps/three/SizesOption";
import UserSizesOption from "@/components/steps/three/UserSizesOption";
export default {
  components: {
    Home,
    UserSizesOption,
    SizesOption,
    DateOption,
    MailOption,
  },
  computed: {
    step_selected() {
      return this.$store.getters["sizes/step_selected"];
    },
  },
};
</script>

<style>
</style>