/* eslint-disable vue/prop-name-casing */
<template>
  <label class="w-50 mb2">
    <input v-model="placket" class type="radio" name="placket" :value="value" layer="french" />
    <span>
      <img :src="img" />
      <span>{{ name }}</span>

      <i class="info-btn">
        The front of the shirt simply folds back on itself for a cleaner yet less
        structured look.
      </i>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    img_shirt: {
      type: String,
      default: ""
    },
    item_id: { type: Number, default: 0 }
  },
  computed: {
    placket: {
      get: function() {
        return this.$store.getters.placket;
      },
      set: function(value) {
        const data = {
          value: value,
          name: this.name,
          item_id: this.item_id,
          img_url: this.img_shirt
        };
        this.$store.commit("setPlacket", data);
      }
    }
  }
};
</script>

<style>
</style>