<template>
  <!-- class="modal module-body-size msr-shirt-modal" -->
  <div
    id="body-size-modal"
    class="modal module-body-size msr-shirt-modal in"
    role="dialog"
    aria-hidden="false"
    style="display: block"
    @click="body"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="backToSteps"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">Mide tu cuerpo</h4>
        </div>
        <div class="modal-body text-left row" style="display: block">
          <ValidationObserver ref="form">
            <form class="w-full" @submit.prevent="onSubmit">
              <ul class="size-form col-sm-5">
                <li data-toggle="tab" class>
                  <a
                    href="#body-neck-around"
                    data-help="neck-around"
                    data-vid="z7zB8GYQ0Sc"
                  >
                    Cuello
                  </a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:30,56"
                    >
                      <div class="input-group">
                        <input
                          id="neck_input"
                          v-model="neck_input"
                          type="text"
                          class="form-control"
                          tabindex="1"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'cuello'"
                        />
                        <!-- @keyup="validateSize(neck_input,'cuello',30,90)" -->
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li data-toggle="tab" class>
                  <a href="#body-sleeve-length" data-vid="mGyQF-kvzRw">Manga</a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:45,107"
                    >
                      <div class="input-group">
                        <input
                          id="arm_length_input"
                          v-model="arm_length_input"
                          type="text"
                          class="form-control"
                          tabindex="2"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'manga'"
                        />
                        <!-- @keyup="validateSize($event,'manga',55,107)" -->
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li class>
                  <a href="#body-shoulder-width">Hombros</a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:30,72"
                    >
                      <div class="input-group">
                        <input
                          id="shoulder_input"
                          v-model="shoulder_input"
                          type="text"
                          class="form-control"
                          tabindex="3"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'hombros'"
                        />
                        <!-- @keyup="validateSize($event,'shoulder',35,72)" -->
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li class>
                  <a href="#body-chest-around">Pecho</a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:76,152"
                    >
                      <div class="input-group">
                        <input
                          id="chest_input"
                          v-model="chest_input"
                          type="text"
                          class="form-control"
                          tabindex="4"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'pecho'"
                        />
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li class>
                  <a href="#body-waist-around">Cintura</a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:50,152"
                    >
                      <div class="input-group">
                        <input
                          id="waist_input"
                          v-model="waist_input"
                          type="text"
                          class="form-control"
                          tabindex="5"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'cintura'"
                        />
                        <!-- @keyup="validateSize($event,'waist',50,152)" -->
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li class>
                  <a href="#body-hip-around">Cadera</a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:60,162"
                    >
                      <div class="input-group">
                        <input
                          id="hip_input"
                          v-model="hip_input"
                          type="text"
                          class="form-control"
                          tabindex="5"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'cintura'"
                        />
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li class>
                  <a href="#body-shirt-length">Largo</a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:60,96"
                    >
                      <div class="input-group">
                        <input
                          id="length_input"
                          v-model="length_input"
                          type="text"
                          class="form-control"
                          tabindex="6"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'largo'"
                        />
                        <!-- @keyup="validateSize($event,'length',50,96)" -->
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li class>
                  <a href="#body-bicep-around">Biceps</a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:20,60"
                    >
                      <div class="input-group">
                        <input
                          id="bicep_input"
                          v-model="bicep_input"
                          type="text"
                          class="form-control"
                          tabindex="7"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'brazo'"
                        />
                        <!-- @keyup="validateSize($event,'bicept',20,60)" -->
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li class>
                  <a href="#body-wrist-around">Puño</a>
                  <div class="form-inline pull-right">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|minmax:15,25"
                    >
                      <div class="input-group">
                        <input
                          id="wrist_input"
                          v-model="wrist_input"
                          type="text"
                          class="form-control"
                          tabindex="8"
                          :class="{ 'not-valid': errors[0] }"
                          @focus="focus = 'punno'"
                        />
                        <!-- @keyup="validateSize($event,'wrist',15,25)" -->
                        <div class="input-group-addon">
                          <span>cm</span>
                        </div>
                      </div>
                    </validation-provider>
                  </div>
                </li>
                <li>
                  <a href="#body-fit">Acabado</a>
                  <div class="form-inline pull-right">
                    <select
                      id="type_of_fit_input"
                      v-model="type_of_fit_input"
                      class="form-control"
                      tabindex="9"
                      @focus="measures = false"
                      @blur="measures = true"
                    >
                      <option
                        v-for="(finish, index) in finishOptions"
                        :key="index"
                        :value="finish.id"
                      >
                        {{ finish.label }}
                      </option>
                    </select>
                  </div>
                </li>
                <!-- <li>
                  <div class="options row w-100">
                    <div class="flex flex-wrap checkbox-wrap">
                      <label class="w-100 pa3 checkbox-toggle">
                        <input
                          v-model="sizeMethod"
                          tabindex="10"
                          type="radio"
                          name="size_method"
                          :value="valueMethod"
                        />
                        <span>
                          <i class="check-indicator"></i> Selecionar este metodo
                        </span>
                      </label>
                    </div>
                  </div>
                </li> -->
                <li>
                  <button
                    class="btn btn-default btn-save-size"
                    data-role="body"
                    rel="new_size_id"
                    type="submit"
                  >
                    Crear Talla
                  </button>
                </li>
              </ul>
            </form>
          </ValidationObserver>

          <article class="msr-help col-md-7 bg-white">
            <div class="tab-content">
              <div
                id="body-neck-around"
                class="tab-pane active"
                data-help="neck-around"
                data-vid="z7zB8GYQ0Sc"
              >
                <transition key="measures" name="fade" mode="out-in">
                  <div v-if="measures">
                    <h2>Como medir tu cuerpo: {{ info[focus].title }}</h2>
                    <p style="font-size: 18px">{{ info[focus].body }}</p>

                    <img src="../../assets/jdoutfit.png" alt="tallas" />
                  </div>
                  <div v-if="!measures" key="estilos_acabados">
                    <h2>Estilos de Acabados</h2>
                    <p style="font-size: 18px">
                      Para su primera compra sugerimos comenzar con "Normal Fit"
                      También conocido como fit estándar. Te quedará bien,
                      cómodo y no demasiado apretado. Puede utilizarlo para ir a
                      la oficina como para un evento social.
                    </p>
                  </div>
                </transition>
              </div>
            </div>
          </article>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("minmax", {
  validate(value, { min, max }) {
    return parseInt(value) >= min && parseInt(value) <= max;
  },
  params: ["min", "max"],
});
export default {
  data() {
    return {
      focus: "cuello",
      measures: true,
      info: {
        cuello: {
          title: "Cuello",
          body: "Párate relajado con la mirada recta. Coloque la cinta métrica alrededor del cuello donde normalmente descansaría el cuello de la camisa, justo por debajo de la manzana de Adán. Mantenga un dedo debajo de la cinta métrica para asegurar comodidad. Asegúrate que la cinta métrica se pueda mover suavemente y no la tense.",
        },
        pecho: {
          title: "Pecho",
          body: "Párete de manera relajada y natural con los brazos relajados a los costados. No hinches el pecho ni arquees la espalda. Pasa la cinta métrica alrededor del pecho por debajo de las axilas. Asegúrate que la cinta este de forma paralela. No tenses la cinta métrica y evita tener ropa muy gruesa al realizar la medición",
        },
        cintura: {
          title: "Cintura",
          body: "Es importante pararse en una postura relajada, no contenga el aire ni empuje la barriga. Pase la cinta métrica alrededor de vientre a la altura del ombligo o de la zona más ancha. La cinta métrica debe estar ajustada pero no tensa.",
        },
        caderas: {
          title: "Caderas",
          body: "Retira cualquier objeto de los bolsillos de adelante y atrás. Coloca la cintra métrica en el punto más ancho de tus caderas. Párate de manera relajada sin inclinarte hacia los lados. Asegúrate que la cinta métrica este de manera paralela y de no tensarla.",
        },
        hombros: {
          title: "Hombros",
          body: "Coloque la cinta métrica donde la parte horizontal del hombre se encuentra con la parte vertical del brazo. Mida hasta el otro extremo del otro hombro pasando por encima en la parte curva de los hombros. Normalmente esta medida puede requerir la ayuda de un compañero, de no poder hacerlo con alguien más se puede medir una camisa actual. Asegúrese que esta camisa sea una con las medidas correctas.",
        },
        manga: {
          title: "Largo de la manga",
          body: "Párete de manera natural con los brazos relajados a los costados sin contraerlos.  Coloque la cinta métrica exactamente en el punto de la medida de “hombros” es decir donde la parte horizontal los hombros llega a la parte vertical de la manga. Mida hasta el centro de la parte carnosa de su palma.",
        },
        punno: {
          title: " Puño",
          body: "Mida alrededor de su muñeca alrededor del hueso de la muñeca. La cinta métrica debe estar en contacto total con su piel. Redondee al centímetro más alto. Nosotros haremos los ajustes necesarios",
        },

        brazo: {
          title: "Bíceps ",
          body: "Párete de manera natural con los brazos relajados a los costados sin flexionar. Coloque la cinta métrica en lugar más ancho del bíceps, normalmente cerca a la axila. Asegúrese de no tensar la cinta métrica.",
        },
        largo: {
          title: "Largo de la camisa",
          body: "Párate de manera natural y relajada. Coloca la cinta métrica en el lugar más alto de tu hombro y mida hacia abajo hasta el punto donde desea que termine la camisa. Este punto suele ser la parte inferior de las nalgas, aunque es posible que desee ir un poco más largo si planea siempre meter la camisa, o un poco más corto si planea usar la camisa sin meter dentro del pantalón. La diferencia entre usar la camisa adentro o fuera son 5 cm aproximadamente.",
        },
      },
    };
  },
  computed: {
    sizeCheck: {
      get: function () {
        return this.$store.getters.validateSize;
      },
      set: function (value) {
        const data = {
          value: value,
          name: "tallas",
        };
        this.$store.commit("validateSize", data);
      },
    },
    valueMethod() {
      return this.$store.getters.sizeMeasureBody.value;
    },
    nameMethod() {
      return this.$store.getters.sizeMeasureBody.name;
    },
    finishOptions() {
      return this.$store.state.constants.finishes;
    },
    sizeMethod: {
      get: function () {
        return this.$store.getters.sizeMethod;
      },
      set: function (value) {
        console.log(value);
        // console.log(this.name);
        const data = {
          value: value,
          name: this.nameMethod,
        };
        this.$store.commit("sizeMethod", data);
      },
    },
    neck_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].neck_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "neck_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    arm_length_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod]
          .arm_length_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "arm_length_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    shoulder_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].shoulder_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "shoulder_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    chest_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].chest_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "chest_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    waist_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].waist_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "waist_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    hip_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].hip_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "hip_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    length_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].length_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "length_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    bicep_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].bicep_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "bicep_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    wrist_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod].wrist_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "wrist_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
    type_of_fit_input: {
      get: function () {
        return this.$store.getters.sizeValues[this.valueMethod]
          .type_of_fit_input;
      },
      set: function (value) {
        const data = {
          parent: this.valueMethod,
          target: "type_of_fit_input",
          value: value,
        };
        this.$store.commit("sizeValues", data);
      },
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        this.sizeCheck = !success;
        if (!success && this.sizeMethod == this.valueMethod) {
          return;
        }

        this.$store.dispatch("checkIfAuthenticated");
        // this.$store.dispatch("checkIfAuthenticated");
        // this.backToSteps();

        // // Resetting Values
        // this.firstName = this.lastName = this.email = '';

        // // Wait until the models are updated in the UI
        // this.$nextTick(() => {
        //   this.$refs.form.reset();
        // });
      });
    },
    body(e) {
      if (e.target.id === "body-size-modal") {
        this.backToSteps();
      }
    },
    backToSteps() {
      if (this.sizeMethod == this.valueMethod) {
        this.$refs.form.validate().then((success) => {
          this.sizeCheck = !success;
        });
      }
      this.$store.commit("toggleModalOpen", false);
      this.$store.commit("toggleModalMeasurements", false);
    },
  },
};
</script>

<style></style>
