import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./css/checkout-cart.css";
import "./css/checkout.css";
import "./css/shirts.css";
import "./css/modals.css";
import "./css/custom.css";
import "./css/tailwind.css";

import VueGtag from "vue-gtag";

import PortalVue from "portal-vue";
import VCalendar from "v-calendar";
// import 'simplebar-core/dist/simplebar.min.css';
import "simplebar/dist/simplebar.min.css";
import simplebar from "simplebar-vue";
import "./filters.js";
import "./ajax.js";
Vue.use(VCalendar, {
  locale: "es"
});
Vue.config.productionTip = false;
import { ValidationProvider, ValidationObserver } from "vee-validate";
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});
Vue.component("simplebar", simplebar);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.use(PortalVue);

if (process.env.NODE_ENV === "production") {
  Vue.use(
    VueGtag,
    {
      config: {
        id: "G-PFJHPFH7N7"
      },
      pageTrackerUseFullPath: true
    },
    router
  );
}

document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});

/**
cuello 30 - 66

manga 55 - 107

Ancho de Hombros 35 -72

Pecho 76 - 152

Cintura 50 - 152

Largo 66 - 96

Ancho de Brazo 20 - 60

Muñeca 15 - 25
 */
