<template>
  <span>
    <span @click="startConfirmingPassword">
      <slot />
    </span>

    <jet-dialog-modal :show="confirmingPassword" @close="closeModal">
      <template #title>
        <div class="tw-flex tw-justify-between tw-w-full">
          <div>Editar Perfil</div>
          <div>
            <button
              class="tw-p-1 tw-bg-white focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-200"
              @click="closeModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="tw-w-4 tw-h-4 tw-text-gray-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <!-- {{ title }} -->
      </template>

      <template #content>
        <!-- {{ content }} -->
        <form class="" @submit.prevent="updateProfile">
          <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-pt-4 tw-mb-4">
            <div class="tw-col-span-2">
              <FloatInputForm
                id="full_name"
                v-model="form_inputs.name"
                label="Nombre Completo"
                placeholder="Nombres Apellidos"
              />
            </div>
          </div>

          <div
            class="tw-grid tw-grid-cols-1 tw-gap-4 tw-mb-4 lg:tw-grid-cols-5"
          >
            <div class="tw-w-full tw-col-span-1 sm:tw-col-span-2">
              <FloatSelectForm
                id="doc_type"
                v-model="form_inputs.doc_type"
                :options="doc_types"
                label="Tipo de Documento"
              />
            </div>
            <div class="tw-w-full tw-col-span-1 sm:tw-col-span-3">
              <FloatInputForm
                id="doc_num"
                v-model="form_inputs.doc_num"
                label="Numero de Documento"
                placeholder=""
              />
            </div>
          </div>
          <div class="tw-w-full tw-mb-4">
            <div>
              <FloatInputForm
                id="email"
                v-model="form_inputs.email"
                label="Email"
                type="email"
                placeholder="nombre@dominio.com"
              />
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-mb-4">
            <div class="tw-col-span-2">
              <FloatInputForm
                id="phone"
                v-model="form_inputs.phone"
                label="Teléfono"
                type="tel"
                placeholder="Teléfono"
              />
            </div>
          </div>

          <transition name="fade" mode="out-in">
            <div v-if="profile_error" class="tw-mb-4">
              <!-- This example requires Tailwind CSS v2.0+ -->
              <div class="tw-p-4 tw-rounded-md tw-bg-red-50">
                <div class="tw-flex">
                  <div class="tw-flex-shrink-0">
                    <!-- Heroicon name: solid/x-circle -->
                    <svg
                      class="tw-w-5 tw-h-5 tw-text-red-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-tw-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="tw-ml-3">
                    <h3 class="tw-text-sm tw-font-medium tw-text-red-800">
                      Hubo un error actualizando sus datos
                    </h3>
                    <div class="tw-mt-2 tw-text-sm tw-text-red-700">
                      <ul class="tw-pl-5 tw-space-y-1 tw-list-disc">
                        <li>{{ profile_error }}</li>
                        <!-- <li>
                          Your password must include at least one pro wrestling
                          finishing move
                        </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="tw-pt-2">
            <div class="tw-flex tw-justify-end">
              <PrimaryBtn :loading="profile_processing"
                >Actualizar Perfil</PrimaryBtn
              >
            </div>
          </div>
        </form>
        <form
          class="tw-mt-8 tw-border-t tw-border-gray-300 tw-border-solid"
          @submit.prevent="updatePassword"
        >
          <div class="tw-my-8">
            <div class="tw-text-lg">Cambiar Contraseña</div>
          </div>
          <div
            class="tw-grid tw-grid-cols-1 tw-gap-4 tw-mb-4 md:tw-grid-cols-2"
          >
            <div>
              <FloatInputForm
                id="password"
                v-model="form.password"
                label="Contraseña"
                type="password"
                placeholder=" "
                autocomplete="new-password"
              />
            </div>
            <div>
              <FloatInputForm
                id="password_confirm"
                v-model="form.password_confirmation"
                label="Confirmar Contraseña"
                type="password"
                placeholder=" "
              />
            </div>
          </div>

          <transition name="fade" mode="out-in">
            <div v-if="password_error" class="tw-mb-4">
              <!-- This example requires Tailwind CSS v2.0+ -->
              <div class="tw-p-4 tw-rounded-md tw-bg-red-50">
                <div class="tw-flex">
                  <div class="tw-flex-shrink-0">
                    <!-- Heroicon name: solid/x-circle -->
                    <svg
                      class="tw-w-5 tw-h-5 tw-text-red-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-tw-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="tw-ml-3">
                    <h3 class="tw-text-sm tw-font-medium tw-text-red-800">
                      Hubo un error actualizando sus datos
                    </h3>
                    <div class="tw-mt-2 tw-text-sm tw-text-red-700">
                      <ul class="tw-pl-5 tw-space-y-1 tw-list-disc">
                        <li>{{ password_error }}</li>
                        <!-- <li>
                          Your password must include at least one pro wrestling
                          finishing move
                        </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="tw-pt-2">
            <div class="tw-flex tw-justify-end">
              <PrimaryBtn :loading="password_processing"
                >Actualizar Contraseña</PrimaryBtn
              >
            </div>
          </div>
        </form>
      </template>

      <template #footer>
        <!-- <jet-secondary-button @click.native="closeModal">
          Cerrar
        </jet-secondary-button>

        <jet-button
          class="ml-2"
          @click.native="confirmPassword"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          {{ button }}
        </jet-button> -->
      </template>
    </jet-dialog-modal>
  </span>
</template>

<script>
import FloatInputForm from "@/components/forms/FloatInputForm";
import FloatSelectForm from "@/components/forms/FloatSelectForm";
import PrimaryBtn from "@/components/buttons/PrimaryBtn";
// import JetButton from "@/Jetstream/Button";
import JetDialogModal from "./DialogModal";
import { mapState } from "vuex";
// import JetInput from "@/Jetstream/Input";
// import JetInputError from "@/Jetstream/InputError";
// import JetSecondaryButton from "@/Jetstream/SecondaryButton";
import { updateProfile, updatePassword } from "@/api/user";

export default {
  components: {
    // JetButton,
    JetDialogModal,
    FloatInputForm,
    FloatSelectForm,
    PrimaryBtn,
    // JetInput,
    // JetInputError,
    // JetSecondaryButton,
  },
  props: {
    title: {
      type: String,
      default: "Confirm Password",
    },
    content: {
      type: String,
      default: "For your security, please confirm your password to continue.",
    },
    button: {
      type: String,
      default: "Confirmar",
    },
  },

  data() {
    return {
      form_inputs: {
        name: "",
        doc_num: "",
        doc_type: "dni",
        phone: "",
        email: "",
      },
      profile_processing: false,
      profile_error: "",
      password_error: "",
      password_processing: false,
      confirmingPassword: false,
      processing: false,
      form: {
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    ...mapState({
      current_user: (state) => state.currentUser,
      doc_types: (state) =>
        state.constants.doc_types.map((item) => {
          const new_item = {
            text: item.label,
            value: item.name,
          };
          return new_item;
        }),
    }),
  },
  methods: {
    updateProfile() {
      console.log("updateProfile");
      this.profile_processing = true;
      this.profile_error = "";
      return updateProfile(this.form_inputs)
        .then((res) => {
          this.$store.dispatch("initUser");

          this.$store.dispatch("notifications/addNotification", {
            title: "Perfil Actualizado",
            body: "",
          });
        })
        .catch((err) => {
          this.profile_error = window.errorsValidate(err);
        })
        .finally(() => {
          this.profile_processing = false;
        });
    },
    updatePassword() {
      console.log("updatePassword");
      this.password_processing = true;
      this.password_error = "";
      return updatePassword(this.form)
        .then((res) => {
          this.$store.dispatch("notifications/addNotification", {
            title: "Contraseña Actualizada",
            body: "",
          });
        })
        .catch((err) => {
          this.password_error = window.errorsValidate(err);
        })
        .finally(() => {
          this.password_processing = false;
        });
    },
    startConfirmingPassword() {
      this.confirmingPassword = true;
      this.form_inputs.name = this.current_user.name;
      this.form_inputs.doc_type = this.current_user.doc_type.name;
      this.form_inputs.doc_num = this.current_user.doc_num;
      this.form_inputs.email = this.current_user.email;
      this.form_inputs.phone = this.current_user.phone;
      this.form.password = "";
      this.form.password_confirmation = "";
      // axios.get(route("password.confirmation")).then((response) => {
      //   if (response.data.confirmed) {
      //     this.$emit("confirmed");
      //   } else {
      //     this.confirmingPassword = true;
      //     setTimeout(() => this.$refs.password.focus(), 250);
      //   }
      // });
    },

    confirmPassword() {
      this.form.processing = true;

      // axios
      //   .post(route("password.confirm"), {
      //     password: this.form.password,
      //   })
      //   .then(() => {
      //     this.form.processing = false;
      //     this.closeModal();
      //     this.$nextTick(() => this.$emit("confirmed"));
      //   })
      //   .catch((error) => {
      //     this.form.processing = false;
      //     this.form.error = error.response.data.errors.password[0];
      //     this.$refs.password.focus();
      //   });
    },

    closeModal() {
      this.confirmingPassword = false;
      this.form.password = "";
      this.form.error = "";
    },
  },
};
</script>
