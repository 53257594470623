<template>
  <transition
    enter-active-class="tw-ease-out tw-duration-150"
    enter-class="tw-opacity-0"
    enter-to-class="tw-opacity-100"
    leave-active-class="tw-ease-in tw-duration-100"
    leave-class="tw-opacity-100"
    leave-to-class="tw-opacity-0"
    mode="out-in"
  >
    <router-view :key="$route.fullPath" />
  </transition>
</template>

<script>
export default {};
</script>

<style>
</style>