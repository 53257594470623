<template>
  <section id="step-botones" class="style-single options" :class="{ 'is-active': isTrue }">
    <div class="inner">
      <header>
        <BackBtn />
        <h5>Botones</h5>
      </header>
      <div class="flex flex-wrap option-grid">
        <BotonInput
          v-for="(style,index) in buttons"
          id="check"
          :key="index"
          :value="style.id"
          :img="style.url_img"
          :name="style.name"
          :data-img="style.url_img"
          :botton_img_shirt="style.url_shirt_img"
          :botton_img_cuff="style.url_cuff_img"
          :botton_img_neck="style.url_neck_img"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BackBtn from "../../../components/BackBtn";
import BotonInput from "../../../components/steps/two/BotonInput";
export default {
  components: {
    BackBtn,
    BotonInput
  },
  data() {
    return {
      isTrue: false
    };
  },
  computed: {
    buttons() {
      return this.$store.getters.buttons;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isTrue = true;
    }, 100);
  }
};
</script>

<style>
</style>