import { getFabrics, getCurrentStyles, saveShirt } from "../api/fabrics";
export const mutations = {
  constants(state, payload) {
    const {
      doc_types,
      // version
      standard_sizes,
      finishes,
      shipping
    } = payload;

    // state.constants.version = version;
    state.constants.doc_types = doc_types;
    state.constants.finishes = finishes;
    state.constants.standard_sizes = standard_sizes;
    state.constants.shipping = shipping;
  },
  resetSize(state, payload) {
    state.size.values.measure_self = {
      neck_input: "",
      arm_length_input: "",
      shoulder_input: "",
      chest_input: "",
      waist_input: "",
      hip_input: "",
      length_input: "",
      bicep_input: "",
      wrist_input: "",
      type_of_fit_input: "CLASSIC"
    };
  },
  setShirtUUID(state, { uuid }) {
    state.uuid_shirt_query = uuid;
  },
  logoutComplete(state) {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    state.authenticated = false;
    state.currentUser = {};
  },
  addSizeToUser(state, payload) {
    state.currentUser.user_details.sizes = [
      ...state.currentUser.user_details.sizes,
      payload
    ];
  },
  setUserFavorites(state, payload) {
    console.log(payload);
    state.currentUser.favorites = [...payload];
  },
  setSizesToUser(state, payload) {
    state.currentUser.user_details.sizes = payload;
  },
  setCheckoutShippingFields(state, payload) {
    console.log("setCheckoutShippingFields", state.currentUser);
    state.checkout.shipping.full_name = state.currentUser.name;
    state.checkout.shipping.phone = state.currentUser.phone;
    state.checkout.shipping.doc_type = state.currentUser.doc_type.name;
    state.checkout.shipping.doc_num = state.currentUser.doc_num;
  },
  loginSuccess(state, payload) {
    state.authenticated = true;
    state.currentUser = Object.assign({}, payload.user, {
      token: payload.access_token,
      user_details: payload.user_details
    });
    localStorage.setItem("user", JSON.stringify(state.currentUser));
    localStorage.setItem("token", payload.access_token);
    window.NUCLIUZ.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${payload.access_token}`;
  },
  authenticateCold(state, payload) {
    state.authenticated = true;
    window.NUCLIUZ.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${window.localStorage.getItem("token")}`;
    state.currentUser = JSON.parse(window.localStorage.getItem("user"));
  },
  // coldRefresh(state, payload) {
  //   return window.NUCLIUZ
  //     .get('/api/auth/user')
  //     .then((res) => {
  //       state.currentUser = Object.assign({}, res.data.user, {
  //         token: res.data.access_token,
  //         user_details: res.data.user_details
  //       });

  //       localStorage.setItem('user', JSON.stringify(state.currentUser));
  //     })
  //     .catch((err) => {
  //       console.log(window.errorsValidate(err));
  //     });
  // },
  current_shirt_b64(state, payload) {
    state.shirt.current_shirt_b64 = payload;
  },
  setAuthenticated(state, payload) {
    state.authenticated = payload;
  },
  second_purchase(state, payload) {
    state.second_purchase = payload;
  },
  validateSize(state, payload) {
    if (payload.name === "tallas") {
      state.validateSize.tallas = payload.value;
    }
    if (payload.name === "date") {
      state.validateSize.date = payload.value;
    }
  },
  MobileNavOpen(state, bool) {
    state.MobileNavOpen = bool;
  },
  purchaseAction(state, bool) {
    state.purchaseAction = bool;
  },
  loadingSave(state, bool) {
    state.loadingSave = bool;
  },
  toBuyLoading(state, bool) {
    state.toBuyLoading = bool;
  },
  toggleModalLoginOpen(state, bool) {
    if (!bool) {
      state.auth.toggleForm = "login";
    }
    state.modalLoginOpen = bool;
  },
  setModalSetDate(state, bool) {
    state.modals.tailor = bool;
  },
  setModalMailShirt(state, bool) {
    state.modals.mail = bool;
  },
  setFabrics(state, payload) {
    state.data.fabrics = payload;
  },
  setCollarStyles(state, payload) {
    state.data.collar_styles = payload;
  },
  setCuffStyles(state, payload) {
    state.data.cuff_styles = payload;
  },
  setPocketStyles(state, payload) {
    state.data.pocket_styles = payload;
  },
  setPlacketStyles(state, payload) {
    state.data.placket_styles = payload;
  },
  setButtons(state, payload) {
    state.data.buttons = payload;
  },
  setThirdStandardSizeToggle(state, bool) {
    state.steps.third.toggleStandard = bool;
  },
  setShirtChange(state, bool) {
    state.shirtChange = bool;
  },
  currentPath(state, payload) {
    state.route.currentPath = payload.path;
  },
  toggleLoading(state, bool) {
    console.log(bool);
    state.loading = bool;
  },
  toggleModalOpen(state, bool) {
    state.modalOpen = bool;
  },
  toggleModalMeasurements(state, bool) {
    state.modals.measurements = bool;
  },
  setTela(state, payload) {
    state.shirtChange = true;
    state.shirt.tela.id = payload.value;
    state.shirt.tela.name = payload.name;
    state.shirt.tela.url = payload.img_url;
  },
  setTelaNoLoad(state, payload) {
    state.shirt.tela.id = payload.id;
    state.shirt.tela.name = payload.name;
    state.shirt.tela.url = payload.url_img_body;
  },
  setCuello(state, payload) {
    state.shirtChange = true;
    state.shirt.cuello.id = payload.value;
    state.shirt.cuello.name = payload.name;
    state.shirt.cuello.item_id = payload.item_id;
    state.shirt.cuello.url = payload.img_url;

    const collars = JSON.parse(localStorage.getItem("all_collar_btns"));
    let varCurrent = "";
    collars.forEach(item => {
      if (
        item.id_collar_style === payload.value &&
        item.id_button === state.shirt.boton.id
      ) {
        varCurrent = item.url_img_body;
      }
    });
    console.log(varCurrent);

    state.shirt.boton.additionals.collar = varCurrent;
  },
  setCuelloNoLoad(state, payload) {
    state.shirt.cuello.id = payload.id;
    state.shirt.cuello.name = payload.name;
    state.shirt.cuello.item_id = payload.item_id;
    state.shirt.cuello.url = payload.url_img_body;

    const collars = JSON.parse(localStorage.getItem("all_collar_btns"));
    let varCurrent = "";
    collars.forEach(item => {
      if (
        item.id_collar_style === payload.id &&
        item.id_button === state.shirt.boton.id
      ) {
        varCurrent = item.url_img_body;
      }
    });
    console.log(varCurrent);

    state.shirt.boton.additionals.collar = varCurrent;
  },
  setManga(state, payload) {
    state.shirtChange = true;
    state.shirt.manga.id = payload.value;
    state.shirt.manga.name = payload.name;
    state.shirt.manga.item_id = payload.item_id;
    state.shirt.manga.url = payload.img_url;

    const cuffs = JSON.parse(localStorage.getItem("all_cuff_btns"));
    let varCurrent = "";
    cuffs.forEach(item => {
      if (
        item.id_cuff_style === payload.value &&
        item.id_button === state.shirt.boton.id
      ) {
        varCurrent = item.url_img_body;
      }
    });
    console.log(varCurrent);

    state.shirt.boton.places.boton_manga = varCurrent;
  },
  setMangaNoLoad(state, payload) {
    state.shirt.manga.id = payload.id;
    state.shirt.manga.name = payload.name;
    state.shirt.manga.item_id = payload.item_id;
    state.shirt.manga.url = payload.url_img_body;

    const cuffs = JSON.parse(localStorage.getItem("all_cuff_btns"));
    let varCurrent = "";
    cuffs.forEach(item => {
      if (
        item.id_cuff_style === payload.id &&
        item.id_button === state.shirt.boton.id
      ) {
        varCurrent = item.url_img_body;
      }
    });
    console.log(varCurrent);

    state.shirt.boton.places.boton_manga = varCurrent;
  },
  setBolsillo(state, payload) {
    state.shirtChange = true;
    state.shirt.bolsillo.id = payload.value;
    state.shirt.bolsillo.name = payload.name;
    state.shirt.bolsillo.item_id = payload.item_id;
    state.shirt.bolsillo.url = payload.img_url;

    const pockets = JSON.parse(localStorage.getItem("all_pocket_btns"));
    let varCurrent = "";
    pockets.forEach(item => {
      if (
        item.id_pocket_style === payload.value &&
        item.id_button === state.shirt.boton.id
      ) {
        varCurrent = item.url_img_body;
      }
    });
    console.log(varCurrent);

    state.shirt.boton.additionals.pocket = varCurrent;
  },
  setBolsilloNoLoad(state, payload) {
    state.shirt.bolsillo.id = payload.id;
    state.shirt.bolsillo.name = payload.name;
    state.shirt.bolsillo.item_id = payload.item_id;
    state.shirt.bolsillo.url = payload.url_img_body;

    const pockets = JSON.parse(localStorage.getItem("all_pocket_btns"));
    let varCurrent = "";
    pockets.forEach(item => {
      if (
        item.id_pocket_style === payload.id &&
        item.id_button === state.shirt.boton.id
      ) {
        varCurrent = item.url_img_body;
      }
    });
    console.log(varCurrent);

    state.shirt.boton.additionals.pocket = varCurrent;
  },
  setPlacket(state, payload) {
    state.shirtChange = true;
    state.shirt.placket.id = payload.value;
    state.shirt.placket.name = payload.name;
    state.shirt.placket.item_id = payload.item_id;
    state.shirt.placket.url = payload.img_url;
  },
  setPlacketNoLoad(state, payload) {
    if (payload) {
      state.shirt.placket.id = payload.id;
      state.shirt.placket.name = payload.name;
      state.shirt.placket.item_id = payload.item_id;
      state.shirt.placket.url = payload.url_img_body;
    }
  },
  setButton(state, payload) {
    state.shirtChange = true;
    state.shirt.boton.id = payload.value;
    state.shirt.boton.name = payload.name;
    state.shirt.boton.places.boton_camisa = payload.botton_img_shirt;
    // state.shirt.boton.places.boton_manga = payload.botton_img_cuff;
    state.shirt.boton.places.boton_cuello = payload.botton_img_neck;
    const cuffs = JSON.parse(localStorage.getItem("all_cuff_btns"));
    let varCuff = "";
    cuffs.forEach(item => {
      if (
        item.id_cuff_style === state.shirt.manga.id &&
        item.id_button === payload.value
      ) {
        varCuff = item.url_img_body;
      }
    });
    console.log(varCuff);

    state.shirt.boton.places.boton_manga = varCuff;

    const collars = JSON.parse(localStorage.getItem("all_collar_btns"));
    let varCollar = "";
    collars.forEach(item => {
      if (
        item.id_collar_style === state.shirt.cuello.id &&
        item.id_button === payload.value
      ) {
        varCollar = item.url_img_body;
      }
    });
    console.log(varCollar);

    state.shirt.boton.additionals.collar = varCollar;

    const pockets = JSON.parse(localStorage.getItem("all_pocket_btns"));
    let varPocket = "";
    pockets.forEach(item => {
      if (
        item.id_pocket_style === state.shirt.bolsillo.id &&
        item.id_button === payload.value
      ) {
        varPocket = item.url_img_body;
      }
    });
    console.log(varPocket);

    state.shirt.boton.additionals.pocket = varPocket;
  },
  setButtonNoLoad(state, payload) {
    state.shirt.boton.id = payload.id;
    state.shirt.boton.name = payload.name;
    state.shirt.boton.places.boton_camisa = payload.url_shirt_img;
    // state.shirt.boton.places.boton_manga = payload.url_cuff_img;
    state.shirt.boton.places.boton_cuello = payload.url_neck_img;
  },
  setMonogram(state, payload) {
    state.shirt.monogram.value = payload.value;
  },
  setMonogramParam(state, payload) {
    if (payload.target === "word") {
      return (state.shirt.monogram.word = payload.value);
    }
    if (payload.target === "style") {
      return (state.shirt.monogram.style = payload.value);
    }
    if (payload.target === "color") {
      return (state.shirt.monogram.color = payload.value);
    }
    if (payload.target === "position") {
      return (state.shirt.monogram.position = payload.value);
    }
  },
  sizeMethod(state, payload) {
    console.log();
    state.size.method = payload.value;
    state.size.nameMethod = payload.name;
  },
  toggles(state, payload) {
    if (payload.target === "mobile") {
      state.toggles.navBarMobile = payload.val;
      return;
    }
  },
  sizeValues(state, payload) {
    if (payload.parent === "measure_self") {
      if (payload.target === "neck_input") {
        return (state.size.values.measure_self.neck_input = payload.value);
      }
      if (payload.target === "arm_length_input") {
        return (state.size.values.measure_self.arm_length_input =
          payload.value);
      }
      if (payload.target === "shoulder_input") {
        return (state.size.values.measure_self.shoulder_input = payload.value);
      }
      if (payload.target === "chest_input") {
        return (state.size.values.measure_self.chest_input = payload.value);
      }
      if (payload.target === "waist_input") {
        return (state.size.values.measure_self.waist_input = payload.value);
      }
      if (payload.target === "hip_input") {
        return (state.size.values.measure_self.hip_input = payload.value);
      }
      if (payload.target === "length_input") {
        return (state.size.values.measure_self.length_input = payload.value);
      }
      if (payload.target === "bicep_input") {
        return (state.size.values.measure_self.bicep_input = payload.value);
      }
      if (payload.target === "wrist_input") {
        return (state.size.values.measure_self.wrist_input = payload.value);
      }
      if (payload.target === "type_of_fit_input") {
        return (state.size.values.measure_self.type_of_fit_input =
          payload.value);
      }
    }

    if (payload.parent === "standard_size") {
      if (payload.target === "standart_type_size_name") {
        return (state.size.values.standard_size.standart_type_size_name =
          payload.value);
      }
      if (payload.target === "standart_type_of_fit_form") {
        return (state.size.values.standard_size.standart_type_of_fit_form =
          payload.value);
      }
    }

    if (payload.parent === "meet_with_sastre") {
      if (payload.target === "address") {
        return (state.size.values.meet_with_sastre.address = payload.value);
      }
      if (payload.target === "district") {
        return (state.size.values.meet_with_sastre.district = payload.value);
      }
      if (payload.target === "date") {
        return (state.size.values.meet_with_sastre.date = payload.value);
      }
      if (payload.target === "hours") {
        return (state.size.values.meet_with_sastre.hours = payload.value);
      }
    }
  }
};
