<template>
  <label key="11" class="w-50 mb2">
    <input v-model="cuello" class type="radio" name="collar" :value="value" layer="pss" />
    <span>
      <img :src="img" />
      <span>{{ name }}</span>

      <i class="info-btn">
        A moderate spread collar built with traditional proportions for versatile,
        classic looks.
      </i>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 1
    },
    name: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    img_shirt: { type: String, default: "" },
    item_id: { type: Number, default: 1 }
  },
  computed: {
    cuello: {
      get: function() {
        return this.$store.getters.cuello;
      },
      set: function(value) {
        const data = {
          value: value,
          name: this.name,
          item_id: this.item_id,
          img_url: this.img_shirt
        };
        this.$store.commit("setCuello", data);
      }
    }
  }
};
</script>

<style>
</style>