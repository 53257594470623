<template>
  <div id="mw_forgot_password_form">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Close"
        :disabled="loading"
        @click.prevent="toggleLoginModal"
      >
        <span aria-hidden="true"></span>
      </button>
      <h4 class="modal-title">NUEVA CONTRASEÑA</h4>
    </div>
    <div class="modal-body">
      <form
        class="form-horizontal form-neu"
        style="margin-top: 0"
        role="form"
        @submit.prevent="reset"
      >
        <div class="form-group">
          <div class="col-xs-12 control-area">
            <label for="email_field" class="sr-only">Email</label>
            <input
              id="mw_email_forgot"
              v-model="email"
              type="email_field"
              class="form-control"
              value
              placeholder="Email"
              autocomplete="username"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12 control-area">
            <label for="mw_password" class="sr-only">Nueva Contraseña</label>
            <input
              id="mw_password"
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Password"
              autocomplete="new-password"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12 control-area">
            <label for="mw_password" class="sr-only">Confirmar Nueva Contraseña</label>
            <input
              id="mw_password"
              v-model="password_confirmation"
              type="password"
              class="form-control"
              placeholder="Confirmar Password"
              autocomplete="current-password"
            />
          </div>
        </div>
        <div class="form-group no-border" style="margin-left:0;">
          <div id="captcha_container_fp" />
        </div>

        <div class="row">
          <div class="col-xs-6 padding-0 padding-right-5">
            <button
              type="button"
              class="btn btn-default-grey btn-width-all mw_close border-solid-black bg-transparent secondary text-black"
              :disabled="loading"
              @click.prevent="toggleLogin"
            >CANCELAR</button>
          </div>
          <div class="col-xs-6 padding-0 padding-left-5 align-center">
            <button
              id="mw_buttonResetPassword"
              type="submit"
              class="btn btn-default btn-width-all border-solid-black display-flex justify-center"
              :disabled="loading"
            >
              <span v-if="!loading">
                <div class="hidden-xxs">RECUPERAR CONTRASEÑA</div>
                <div class="visible-xxs">RECUPERAR</div>
              </span>
              <BtnLoader v-if="loading" />
            </button>
          </div>
        </div>
      </form>
      <input id="inp_sp" type="hidden" name="inp_sp" value="sp_say_not" />
      <div class="indent-30">
        <div class="indent-5" />
        <div class="message-danger margin-top-0-imp display-block">{{ errorMsg }}</div>
        <div class="message-success margin-top-0-imp display-block">{{ successMsg }}</div>
      </div>
      <!-- <div class="align-center pb2">Te enviaremos los pasos para recuperar tu contraseña.</div> -->
    </div>
  </div>
</template>

<script>
import { resetPassword } from "@/api/auth";
import BtnLoader from "@/components/btnLoader";
export default {
  components: {
    BtnLoader
  },
  data() {
    return {
      email: "",
      password: "",
      password_confirmation: "",
      errorMsg: "",
      successMsg: ""
    };
  },
  computed: {
    loading() {
      return this.$store.getters["auth/isAuthLoading"];
    }
  },
  methods: {
    toggleLoginModal() {
      return this.$store.commit("toggleModalLoginOpen", false);
    },
    toggleForgot() {
      return this.$store.commit("auth/toggleForm", "forgot");
    },
    toggleLogin() {
      return this.$store.commit("auth/toggleForm", "login");
    },
    toggleRegister() {
      return this.$store.commit("auth/toggleForm", "register");
    },
    reset() {
      this.errorMsg = "";
      if (this.password !== this.password_confirmation) {
        return (this.errorMsg = "Contraseñas no son identicas");
      }

      const body = {
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        resetToken: this.$store.getters["auth/resetToken"]
      };
      this.$store.commit("auth/setAuthLoading", true);
      return resetPassword(body)
        .then(res => {
          console.log(res);

          this.$store.commit("auth/setPasswordRecoveryToken", { token: "" });
          this.toggleLoginModal();
        })
        .catch(err => {
          console.log(err);
          this.errorMsg = err;
        })
        .finally(() => {
          this.$store.commit("auth/setAuthLoading", false);
        });
    }
  }
};
</script>

<style>
</style>