export const appendUpdate = payload => {
  let body = "";
  if (payload instanceof FormData) {
    payload.append("_method", "PUT");
    body = payload;
  } else {
    body = { ...payload, _method: "PUT" };
  }
  return body;
};
