<template>
  <div class="filter-wrap">
    <aside class="filter">
      <div id="filter-panel" class="filter-panel show">
        <div class="filter-inner">
          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Style
            </h5>
            <label
              class="control checkbox"
              data-cat="style"
              data-filter="casual"
              data-url="casual"
              data-rule="=="
              data-value="yes"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Casual</span>
            </label>
            <label
              class="control checkbox"
              data-cat="style"
              data-filter="business_casual"
              data-url="business-casual"
              data-rule="=="
              data-value="yes"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Business Casual</span>
            </label>
            <label
              class="control checkbox"
              data-cat="style"
              data-filter="business"
              data-url="business"
              data-rule="=="
              data-value="yes"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Business</span>
            </label>
            <label
              class="control checkbox"
              data-cat="style"
              data-filter="formal"
              data-url="formal"
              data-rule="=="
              data-value="yes"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Formal</span>
            </label>
          </div>

          <div class="filter-group color">
            <h5>
              <span class="clear-filter label">Clear</span> Color
            </h5>
            <div class="color-wrap">
              <label
                class="control checkbox"
                data-cat="color"
                data-filter="white"
                data-url="white"
                data-rule="=="
                data-value="1"
                data-toggle="tooltip"
                title
                data-original-title="Whites"
              >
                <input type="checkbox" />
                <span style="background-color:#F4F4F4">
                  <i>White</i>
                </span>
              </label>
              <label
                class="control checkbox"
                data-cat="color"
                data-filter="black"
                data-url="black"
                data-rule="=="
                data-value="1"
                data-toggle="tooltip"
                title
                data-original-title="Blacks/Grays"
              >
                <input type="checkbox" />
                <span
                  style="background:url('https://cdn2.propercloth.com/images/filters/alt/black-gray.svg') center center repeat-y"
                >
                  <i>Black</i>
                </span>
              </label>
              <label
                class="control checkbox"
                data-cat="color"
                data-filter="blue"
                data-url="blue"
                data-rule="=="
                data-value="1"
                data-toggle="tooltip"
                title
                data-original-title="Blues"
              >
                <input type="checkbox" />
                <span
                  style="background:url('https://cdn2.propercloth.com/images/filters/alt/blue.svg') center center repeat-y"
                >
                  <i>Blue</i>
                </span>
              </label>
              <label
                class="control checkbox"
                data-cat="color"
                data-filter="red"
                data-url="red"
                data-rule="=="
                data-value="1"
                data-toggle="tooltip"
                title
                data-original-title="Reds"
              >
                <input type="checkbox" />
                <span
                  style="background:url('https://cdn2.propercloth.com/images/filters/alt/red.svg') center center repeat-y"
                >
                  <i>Red</i>
                </span>
              </label>
              <label
                class="control checkbox"
                data-cat="color"
                data-filter="green"
                data-url="green"
                data-rule="=="
                data-value="1"
                data-toggle="tooltip"
                title
                data-original-title="Greens"
              >
                <input type="checkbox" />
                <span
                  style="background:url('https://cdn2.propercloth.com/images/filters/alt/green.svg') center center repeat-y"
                >
                  <i>Green</i>
                </span>
              </label>
              <label
                class="control checkbox"
                data-cat="color"
                data-filter="yellow"
                data-url="yellow"
                data-rule="=="
                data-value="1"
                data-toggle="tooltip"
                title
                data-original-title="Yellows"
              >
                <input type="checkbox" />
                <span
                  style="background:url('https://cdn2.propercloth.com/images/filters/alt/yellow.svg') center center repeat-y"
                >
                  <i>Yellow</i>
                </span>
              </label>
              <label
                class="control checkbox"
                data-cat="color"
                data-filter="pink"
                data-url="pink"
                data-rule="=="
                data-value="1"
                data-toggle="tooltip"
                title
                data-original-title="Purples/Pinks"
              >
                <input type="checkbox" />
                <span
                  style="background:url('https://cdn2.propercloth.com/images/filters/alt/purple-pink.svg') center center repeat-y"
                >
                  <i>Pink</i>
                </span>
              </label>
              <label
                class="control checkbox"
                data-cat="color"
                data-filter="brown"
                data-url="brown"
                data-rule="=="
                data-value="1"
                data-toggle="tooltip"
                title
                data-original-title="Browns/Oranges"
              >
                <input type="checkbox" />
                <span
                  style="background:url('https://cdn2.propercloth.com/images/filters/alt/brown-orange.svg') center center repeat-y"
                >
                  <i>Brown/Orange</i>
                </span>
              </label>
            </div>
          </div>

          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Pattern
            </h5>
            <label
              class="control checkbox"
              data-cat="pattern"
              data-filter="pattern"
              data-url="solids"
              data-rule="=="
              data-value="2"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Solids</span>
            </label>
            <label
              class="control checkbox"
              data-cat="pattern"
              data-filter="pattern"
              data-url="stripes"
              data-rule="=="
              data-value="3"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Stripes</span>
            </label>
            <label
              class="control checkbox"
              data-cat="pattern"
              data-filter="pattern"
              data-url="check"
              data-rule="=="
              data-value="1"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Check</span>
            </label>
            <label
              class="control checkbox"
              data-cat="pattern"
              data-filter="pattern"
              data-url="gingham"
              data-rule="=="
              data-value="5"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Gingham</span>
            </label>
            <label
              class="control checkbox"
              data-cat="pattern"
              data-filter="pattern"
              data-url="plaid"
              data-rule="=="
              data-value="6"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Plaid</span>
            </label>
            <label
              class="control checkbox"
              data-cat="pattern"
              data-filter="pattern"
              data-url="print"
              data-rule="=="
              data-value="4"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Print</span>
            </label>
          </div>

          <div class="filter-group" data-type="price">
            <h5>
              <span class="clear-filter label">Clear</span> Price
            </h5>
            <label
              class="control checkbox"
              data-cat="sale"
              data-filter="sale"
              data-rule="=="
              data-url="sale"
              data-value="1"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>On Sale</span>
            </label>
            <label
              class="control checkbox"
              data-cat="price"
              data-filter="price"
              data-url="under-100"
              data-rule="<"
              data-value="100"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Under $100.00</span>
            </label>
            <label
              class="control checkbox"
              data-cat="price"
              data-filter="price"
              data-rule=">=<="
              data-url="100-125"
              data-value="100|125"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>$100.00 - $125.00</span>
            </label>
            <label
              class="control checkbox"
              data-cat="price"
              data-filter="price"
              data-rule=">=<="
              data-url="125-150"
              data-value="125|150"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>$125.00 - $150.00</span>
            </label>
            <label
              class="control checkbox"
              data-cat="price"
              data-filter="price"
              data-rule=">"
              data-url="over-150"
              data-value="150"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Over $150.00</span>
            </label>
          </div>

          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Thread Count
            </h5>
            <label
              class="control checkbox"
              data-cat="threadCount"
              data-filter="threadCount"
              data-url="thread-count-below-80"
              data-rule="<"
              data-value="79"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Below 80s</span>
            </label>
            <label
              class="control checkbox"
              data-cat="threadCount"
              data-filter="threadCount"
              data-url="thread-count-80"
              data-rule="=="
              data-value="80"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>80s</span>
            </label>
            <label
              class="control checkbox"
              data-cat="threadCount"
              data-filter="threadCount"
              data-url="thread-count-100"
              data-rule="=="
              data-value="100"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>100s</span>
            </label>
            <label
              class="control checkbox"
              data-cat="threadCount"
              data-filter="threadCount"
              data-url="thread-count-120"
              data-rule="=="
              data-value="120"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>120s</span>
            </label>
            <label
              class="control checkbox"
              data-cat="threadCount"
              data-filter="threadCount"
              data-url="thread-count-140"
              data-rule=">="
              data-value="140"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>140s+</span>
            </label>
          </div>

          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Fabric
              <br />Thickness
            </h5>
            <label
              class="control checkbox"
              data-cat="thickness"
              data-filter="thickness"
              data-url="very-lightweight"
              data-rule="<="
              data-value="0.007"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Very Lightweight</span>
            </label>
            <label
              class="control checkbox"
              data-cat="thickness"
              data-filter="thickness"
              data-url="lightweight"
              data-rule="=="
              data-value="0.008"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Lightweight</span>
            </label>
            <label
              class="control checkbox"
              data-cat="thickness"
              data-filter="thickness"
              data-url="medium-weight"
              data-rule="=="
              data-value="0.009"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Medium Weight</span>
            </label>
            <label
              class="control checkbox"
              data-cat="thickness"
              data-filter="thickness"
              data-url="medium-heavyweight"
              data-rule=">=<="
              data-value="0.010|0.011"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Medium Heavyweight</span>
            </label>
            <label
              class="control checkbox"
              data-cat="thickness"
              data-filter="thickness"
              data-url="heavyweight"
              data-rule=">=<="
              data-value="0.012|0.014"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Heavyweight</span>
            </label>
            <label
              class="control checkbox"
              data-cat="thickness"
              data-filter="thickness"
              data-url="very-heavyweight"
              data-rule=">"
              data-value="0.014"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Very Heavyweight</span>
            </label>
          </div>

          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Special
              <br />Treatment
            </h5>
            <label
              class="control checkbox"
              data-cat="easy_care_only"
              data-filter="easy_care_only"
              data-url="wrinkle-resistant"
              data-rule="=="
              data-value="0"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Wrinkle-Resistant</span>
            </label>
            <label
              class="control checkbox"
              data-cat="easy_care_only"
              data-filter="easy_care_only"
              data-url="non-iron"
              data-rule="=="
              data-value="2"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Non-Iron</span>
            </label>
            <label
              class="control checkbox"
              data-cat="easy_care_only"
              data-filter="easy_care_only"
              data-url="no-treatment"
              data-rule="=="
              data-value="1"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>No Treatment</span>
            </label>
          </div>

          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Country of Origin
            </h5>
            <label
              class="control checkbox"
              data-cat="country_of_origin"
              data-filter="country_of_origin"
              data-url="italy"
              data-rule="=="
              data-value="3"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Italy</span>
            </label>
            <label
              class="control checkbox"
              data-cat="country_of_origin"
              data-filter="country_of_origin"
              data-url="japan"
              data-rule="=="
              data-value="2"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Japan</span>
            </label>
            <label
              class="control checkbox"
              data-cat="country_of_origin"
              data-filter="country_of_origin"
              data-url="portugal"
              data-rule="=="
              data-value="4"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Portugal</span>
            </label>
          </div>

          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Fabric Mill
            </h5>
            <label
              class="control checkbox"
              data-cat="fabric_mill"
              data-filter="fabric_mill"
              data-url="albini"
              data-rule="=="
              data-value="2"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Albini</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_mill"
              data-filter="fabric_mill"
              data-url="canclini"
              data-rule="=="
              data-value="3"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Canclini</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_mill"
              data-filter="fabric_mill"
              data-url="dja"
              data-rule="=="
              data-value="5"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>David &amp; John Anderson</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_mill"
              data-filter="fabric_mill"
              data-url="grandi-and-rubinelli"
              data-rule="=="
              data-value="4"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Grandi &amp; Rubinelli</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_mill"
              data-filter="fabric_mill"
              data-url="thomas-mason"
              data-rule="=="
              data-value="1"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Thomas Mason</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_mill"
              data-filter="fabric_mill"
              data-url="reda"
              data-rule="=="
              data-value="6"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Reda</span>
            </label>
          </div>

          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Fabric Weave
            </h5>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="broadcloth"
              data-rule="=="
              data-value="1"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Broadcloth</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="denim-chambray"
              data-rule="=="
              data-value="12"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Denim/Chambray</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="dobby"
              data-rule="=="
              data-value="8"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Dobby</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="end-on-end"
              data-rule="=="
              data-value="5"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>End-on-end</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="flannel"
              data-rule="=="
              data-value="9"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Flannel</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="oxford-cloth"
              data-rule="=="
              data-value="3"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Oxford Cloth</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="pinpoint"
              data-rule="=="
              data-value="2"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Pinpoint</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="plain-weave"
              data-rule="=="
              data-value="13"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Plain Weave</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="royal-oxford"
              data-rule="=="
              data-value="7"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Royal Oxford</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="seersucker"
              data-rule="=="
              data-value="14"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Seersucker</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="twill"
              data-rule="=="
              data-value="6"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Twill</span>
            </label>
            <label
              class="control checkbox"
              data-cat="fabric_type"
              data-filter="fabric_type"
              data-url="other"
              data-rule="=="
              data-value="4"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Other</span>
            </label>
          </div>

          <div class="filter-group">
            <h5>
              <span class="clear-filter label">Clear</span> Composition
            </h5>
            <label
              class="control checkbox"
              data-cat="composition"
              data-filter="composition"
              data-url="cotton"
              data-rule="=="
              data-value="|1|"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>100% Cotton</span>
            </label>
            <label
              class="control checkbox"
              data-cat="composition"
              data-filter="composition"
              data-url="cotton-linen"
              data-rule="|="
              data-value="|1|2|"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Cotton Linen Blend</span>
            </label>
            <label
              class="control checkbox"
              data-cat="composition"
              data-filter="composition"
              data-url="linen"
              data-rule="=="
              data-value="|2|"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>100% Linen</span>
            </label>
            <label
              class="control checkbox"
              data-cat="composition"
              data-filter="composition"
              data-url="merino-wool"
              data-rule="=="
              data-value="|10|"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>100% Merino Wool</span>
            </label>
            <label
              class="control checkbox"
              data-cat="composition"
              data-filter="composition"
              data-url="other-composition"
              data-rule="|="
              data-value="|100|"
            >
              <input type="checkbox" />
              <span class="control-indicator" />
              <span>Other</span>
            </label>
          </div>
        </div>

        <a href="#" class="btn btn-border filter-close">Apply Filter(s)</a>
      </div>
    </aside>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>